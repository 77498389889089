import React from "react";
import { default as CoreTextField } from "@material-ui/core/TextField";
import { useField } from "formik";
import { defaultTo } from "ramda";

export const TextField: React.FC<{
  name: string;
  label?: string;
  required?: boolean;
  multiline?: boolean;
  rows?: number;
  inputMode?: "none" | "text" | "tel" | "url" | "email" | "numeric" | "decimal" | "search";
}> = ({ name, label, required, multiline, rows, inputMode = "text", ...props }) => {
  const [field, meta] = useField(name);
  const { error } = meta;
  const { value, ...rest } = field;
  return (
    <CoreTextField
      fullWidth
      variant={"outlined"}
      multiline={multiline}
      rows={rows}
      label={label}
      value={value ? value : ""}
      error={!!error}
      helperText={defaultTo("", error)}
      inputProps={{ inputMode: inputMode }}
      {...rest}
      {...props}
    />
  );
};
