import {
  __,
  addIndex,
  applySpec,
  chain,
  difference,
  divide,
  fromPairs,
  identity,
  isNil,
  isEmpty,
  map,
  multiply,
  pipe,
  pluck,
  prop,
  reject,
  sum,
  toPairs,
  type,
} from "ramda";
import * as Option from "fp-ts/lib/Option";
import { v5 as uuidV5 } from "uuid";

export const objectDiff = (newObj, oldObj) => {
  return fromPairs<any>(difference(toPairs(newObj), toPairs(oldObj)));
};

export const flattenObj = (obj) => {
  const go = (obj_) =>
    chain(([k, v]) => {
      if (type(v) === "Object") {
        return map(([k_, v_]) => [`${k}.${k_}`, v_], go(v));
      } else {
        return [[k, v]];
      }
    }, toPairs(obj_));
  return fromPairs(go(obj));
};
const unflatten = (obj) => {
  const result = {};
  for (const objectKey in obj) {
    const keys = objectKey.split(".");
    keys.reduce(function (r, e, j) {
      return r[e] || (r[e] = isNaN(Number(keys[j + 1])) ? (keys.length - 1 === j ? obj[objectKey] : {}) : []);
    }, result);
  }
  return result;
};

export const cleanObject = (obj) => {
  return pipe(flattenObj, reject(isNil), unflatten)(obj);
};

export const cleanEmptyObject = (obj) => {
  return pipe(flattenObj, reject(isNil), reject(isEmpty), unflatten)(obj);
};

// @ts-ignore
export const calculateSubtotalFromValues = (values: any) =>
  pipe(
    prop("items"),
    // @ts-ignore
    pluck("amount"),
    map(toNumber),
    map(multiply(100)),
    sum,
    divide(__, 100)
    // @ts-ignore
  )(values);

export const handleNoDecimal = (val: string) => {
  return val.includes(".") ? val : val + ".00";
};

export const toReactSelectOptions = (options: string[]) =>
  map(
    applySpec({
      value: identity,
      label: identity,
    })
  )(options);

export const toNumber = (val: any) => {
  return Number(val);
};

export const mapIndexed = addIndex(map);

export const stringToDate = (val: string) =>
  pipe(
    Option.fromNullable,
    Option.map((innerVal: string) => {
      return new Date(innerVal);
    }),
    Option.toNullable
  )(val);

export const isStripeReady = (values: any) => {
  return !!prop("stripeCustomer", values) && !isEmpty(prop("stripePaymentMethods", values));
};

export const hashIdempotencyKey = async (content) => {
  return uuidV5(content, uuidV5.DNS);
};
