import { BottomNavigation, BottomNavigationAction } from "@material-ui/core";
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons/faCalendar";
import { faBars } from "@fortawesome/free-solid-svg-icons/faBars";
import { useHistory } from "react-router-dom";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "76px",
  },
}));
export const BottomNav = () => {
  const classes = useStyles();
  const {
    location: { pathname },
    push,
  } = useHistory();
  const [value, setValue] = useState(pathname);
  return (
    <BottomNavigation
      className={classes.root}
      value={value}
      onChange={async (event, newValue) => {
        setValue(newValue);
        await push(newValue);
      }}
      showLabels
    >
      <BottomNavigationAction label="Schedule" icon={<FontAwesomeIcon icon={faCalendar} />} value={"/"} />
      <BottomNavigationAction label="More" icon={<FontAwesomeIcon icon={faBars} />} value={"/more"} />
    </BottomNavigation>
  );
};
