import React from "react";
import { Form, Formik } from "formik";
import { Select } from "./fields/Select";
import { cond, equals } from "ramda";
import { useMutation } from "@apollo/client";
import { Mutation, MutationCreatePaymentArgs } from "../../generated/nest-graphql";
import * as Yup from "yup";
import { CREATE_PAYMENT } from "../../graphql/mutations/createPayment";
import { CashAndCheckFields } from "../Payments/CashAndCheckFields";
import { showSuccessAlert } from "../../actions";
import { useDispatch } from "../../contexts/snackbar-context";
import { CollectCardPaymentFields } from "../Payments/CreditCardPaymentFields";

export type AddPaymentFormValues = {
  paymentMethod: string;
  type: string;
  receivedDate: Date;
  amount: string;
  refNumber?: string;
  memo?: string;
  sendReceipt: boolean;
  payer: string;
  source: string;
  paymentIntentId?: string;
};

const validationSchema = Yup.object().shape({
  payer: Yup.string().required("Required").min(1),
  amount: Yup.string().required("Required").min(1),
});

export const AddPaymentForm: React.FC<{
  amount: string;
  invoiceId: string;
  contactId: string;
  refetch: any;
  payer: string;
  laborCost: string;
  partsCost: string;
  partsTax: string;
  laborTax: string;
  totalTax: string;
  subTotal: string;
  onClose: () => void;
}> = ({
  amount,
  invoiceId,
  contactId,
  refetch,
  onClose,
  laborCost,
  partsCost,
  payer,
  partsTax,
  laborTax,
  totalTax,
  subTotal,
}) => {
  const [createPayment] = useMutation<Mutation, MutationCreatePaymentArgs>(CREATE_PAYMENT);
  const dispatch = useDispatch();
  const initialValues = {
    laborCost,
    partsCost,
    partsTax,
    laborTax,
    totalTax,
    subTotal,
    source: "",
    paymentMethod: "Credit Card Payment",
    type: "Invoice",
    amount,
    receivedDate: new Date(),
    sendReceipt: true,
    payer,
  };

  const onSubmit = async (
    { amount, payer, source, sendReceipt, type, memo, paymentMethod, receivedDate, refNumber, paymentIntentId },
    helpers
  ) => {
    const cleanedAmount = amount.includes(".") ? amount : amount + ".00";
    await createPayment({
      variables: {
        createPaymentInput: {
          paymentIntentId,
          amount: cleanedAmount,
          invoicePrice: cleanedAmount,
          payer: payer || "testPayer",
          paymentMethod,
          receivedDate,
          laborCost,
          partsCost,
          partsTax,
          laborTax,
          totalTax,
          subTotal,
          type,
          status: "succeeded",
          source,
          invoice: invoiceId,
          contact: contactId,
          memo,
          refNumber,
          sendReceipt,
        },
      },
    });
    showSuccessAlert(dispatch, "Success");
    await refetch({
      variables: {
        id: invoiceId,
      },
    });
    onClose();
  };

  return (
    <Formik<AddPaymentFormValues> validationSchema={validationSchema} initialValues={initialValues} onSubmit={onSubmit}>
      {({ values }) => {
        return (
          <Form>
            <div className="grid gap-4">
              <Select name={"paymentMethod"} options={["Cash", "Check", "Credit Card Payment"]} label={"Type"} />
              {cond([
                [equals("Cash"), () => <CashAndCheckFields />],
                [equals("Check"), () => <CashAndCheckFields />],
                [
                  equals("Credit Card Payment"),
                  () => (
                    <CollectCardPaymentFields
                      invoiceId={invoiceId}
                      contactId={contactId}
                      amount={amount}
                      refetch={refetch}
                      onClose={onClose}
                      laborCost={laborCost}
                      partsCost={partsCost}
                      partsTax={partsTax}
                      laborTax={laborTax}
                      totalTax={totalTax}
                      subTotal={subTotal}
                    />
                  ),
                ],
              ])(values.paymentMethod)}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
