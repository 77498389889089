import gql from "graphql-tag";
import { preJobCheckListField } from "./getJob";

export const invoiceDetailsFields = `
id
invoiceNumber
laborCost
partsCost
partsTax
laborTax
totalTax
technician {
    id
    firstName
    lastName
}
market
job {
        id
        status
        jobNumber
        feedback {
            rating
            doneBetter
            additionalComments
        }
        partsOrderingIssuesSurvey {
            partsCorrect
            partsStores
            partsIssues
            additionalDetails 
        }
}
        status
        subTotal
        vehicleInfo {
            frontPadLife
            licensePlate
            make
            model
            odometer
            rearPadLife
            vin
            year
        }
        contact {
            id
            firstName
            lastName
            fullName
            email
            stripeCustomer
            stripePaymentMethods
        }
        amountPaid
        balanceDue
        createdAt
        updatedAt
        taxable
        issuedDate
        dueDate
        ${preJobCheckListField}
        items {
            product
            isInEstimate
            name
            amount
            description
            partNumber
            laborCost
            partsStore {
                id
                name
                vendor
            }
            partsCost
            createdAt
            updatedAt
        }
        serviceLocation
        privateNotes
        customerMessage`;

export const GET_INVOICE = gql`query getInvoice($id: ID!) {
    getInvoice(id: $id) {
        ${invoiceDetailsFields}
    }
}`;
