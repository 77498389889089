// @ts-nocheck
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import { format, getDay, parse, set, startOfWeek } from "date-fns";
import { defaultTo, equals, has, head, path, prop, pluck, uniqBy, reject, anyPass, isEmpty, isNil } from "ramda";
import { default as React, useState } from "react";
import { TechnicianResourceView } from "./TechnicianResourceView";
import { CalendarToolbar } from "./CalendarToolbar";
import { add, sub } from "date-fns/fp";
import { AppointmentSummaryDialog, AppointmentSummaryValues } from "./AppointmentSummaryDialog";
import { useToggle } from "../../hooks/useToggle";
import { useSwipeable } from "react-swipeable";
import { pipe } from "fp-ts/lib/function";

const locales = {
  "en-US": require("date-fns/locale/en-US"),
};
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});
export const AppointmentCalendar: React.FC<{
  events: any[];
  technicianColors: any;
  refetch;
}> = ({ refetch, events, technicianColors }) => {
  const [appointmentSummaryValues, setAppointmentValues] = useState<AppointmentSummaryValues>(null);
  const [summaryDialogIsOpen, , toggleSummaryDialog] = useToggle();
  const minDate = set(new Date(), {
    hours: 6,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [calendarView, setCalendarView] = useState("day");
  const dayDifference = equals(calendarView, "day") ? 1 : 7;
  const swipeHandlers = useSwipeable({
    onSwipedRight: () => {
      const previousDate = sub({ days: dayDifference }, selectedDate);
      setSelectedDate(previousDate);
    },
    onSwipedLeft: () => {
      const nextDate = add({ days: dayDifference }, selectedDate);
      setSelectedDate(nextDate);
    },
  });
  return (
    <div className="w-full" {...swipeHandlers}>
      <Calendar
        localizer={localizer}
        // views={{["day", "agenda", "week", "month"]}}
        // @ts-ignore
        views={{
          day: true,
          week: true,
          agenda: true,
          month: false,
          assign: TechnicianResourceView,
        }}
        events={events}
        view={calendarView}
        onView={setCalendarView}
        date={selectedDate}
        onNavigate={(date) => setSelectedDate(date)}
        onSelectEvent={(event: any) => {
          setAppointmentValues({
            // startDate: event.start,
            // endDate: event.end,
            // id: event.id,
            // allDay: event.allDay,
            // technician: event.technician,
            // subject: event.subject,
            // job: event.job
            title: `#${path(["job", "jobNumber"], event)}: ${path(["appointment", "subject"], event)}`,
            appointmentStartDate: event.start,
            appointmentEndDate: event.end,
            customerName: path(["job", "contact", "fullName"], event),
            job: event.job,
            technician: event.technician,
            jobDescription: path(["job", "description"], event),
            serviceLocation: path(["job", "serviceLocation"], event),
          });
          toggleSummaryDialog();
        }}
        min={minDate}
        onSelectSlot={(slotInfo) => {
          setAppointmentValues({
            startDate: slotInfo.start,
            endDate: slotInfo.end,
          });
          toggleSummaryDialog();
        }}
        onRangeChange={async (range) => {
          const startDate = has("start", range) ? prop("start", range) : head(range);
          const startDateRange = pipe(startOfWeek(startDate), sub({ weeks: 1 }));
          const endDateRange = add({ weeks: 3 }, startDateRange);
          await refetch({
            startRange: startDateRange,
            endRange: endDateRange,
          });
        }}
        defaultView={"day"}
        dayPropGetter={(date) => {
          return {
            className: "bg-black",
          };
        }}
        // @ts-ignore
        messages={{
          // @ts-ignore
          assign: "Assign",
        }}
        slotPropGetter={(date) => {
          return {
            className: "bg-black h-20",
          };
        }}
        eventPropGetter={(event, start, end, isSelected) => {
          // @ts-ignore
          const color = technicianColors[event.technician.id].color;
          return {
            style: {
              backgroundColor: `#${color}`,
            },
          };
        }}
        formats={{
          agendaDateFormat: (date, _, localizer) => {
            // @ts-ignore
            return localizer.format(date, "LLL d yyyy");
          },
          dayHeaderFormat: (date, _, localizer) => {
            // @ts-ignore
            return localizer.format(date, "LLL d yyyy");
          },
          timeGutterFormat: (date, _, localizer) => {
            // @ts-ignore
            return localizer.format(date, "h a");
          },
        }}
        className="bg-black border border-black"
        startAccessor="start"
        endAccessor="end"
        resourceIdAccessor={"resourceId"}
        resourceAccessor={"technicianId"}
        resourceTitleAccessor={"resourceTitle"}
        components={{
          toolbar: CalendarToolbar,
          event: Event,
        }}
      />
      <AppointmentSummaryDialog
        open={summaryDialogIsOpen}
        handleClose={toggleSummaryDialog}
        appointmentSummaryValues={appointmentSummaryValues}
      />
    </div>
  );
};

const Event = (props) => {
  const { event } = props;
  const job = prop("job", event);
  if (job) {
    const partsStores = pipe(
      prop("items", job),
      pluck("partsStore"),
      reject(anyPass([isEmpty, isNil])),
      uniqBy(prop("id")),
      defaultTo([])
    );
    const homePartsStoreId = event?.technician?.homePartsStore?.id;
    const partsStoreText = () => {
      if (partsStores?.length > 1) {
        return ` - Multiple Parts Stores`;
      } else {
        if (partsStores?.length === 1 && homePartsStoreId && !equals(prop("id", partsStores[0]), homePartsStoreId)) {
          return " - Alternate Parts Store";
        } else {
          return "";
        }
      }
    };

    return (
      <div>
        <div>
          #{prop("jobNumber", job)}: {event.title} {event.desc && ":  " + event.desc}
        </div>
        <div>
          <strong>
            {job.status}
            {partsStoreText()}
          </strong>
        </div>
      </div>
    );
  }
  return (
    <div>
      <div>{event.title}</div>
      {event.desc && ":  " + event.desc}
    </div>
  );
};
