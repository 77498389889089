import * as React from "react";
import { contains, equals } from "ramda";
import { TextOnWayButton } from "./TextOnWayButton";
import { StartJobButton } from "./StartJobButton";
import { PreJobCheckListFormValues } from "./PreJobCheckListDialog";

export const JobActionButton: React.FC<{
  jobId: string;
  jobStatus: string;
  message: string;
  phoneNumber: string;
  preJobCheckListInitialValues: PreJobCheckListFormValues;
}> = ({ jobId, jobStatus, message, phoneNumber, preJobCheckListInitialValues }) => {
  if (contains(jobStatus, ["New", "New: Scheduled", "Open"])) {
    return <TextOnWayButton jobId={jobId} message={message} phoneNumber={phoneNumber} />;
  }
  if (equals("Open: En Route", jobStatus)) {
    return <StartJobButton preJobCheckListInitialValues={preJobCheckListInitialValues} jobId={jobId} />;
  }
  return null;
};
