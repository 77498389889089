import React from "react";
import { ListItemText } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import List from "@material-ui/core/List";
import { CalendarViewLayout } from "../components/Appointments/CalendarViewLayout";

const MorePage = () => {
  const { logout } = useAuth0();
  return (
    <CalendarViewLayout>
      <List className="w-full">
        <ListItem
          divider
          button
          onClick={async () =>
            await logout({
              returnTo: window.location.origin,
            })
          }
        >
          <ListItemText primary={"Log Out"} />
        </ListItem>
        <ListItem component={Link} to={"/feedback"} divider button>
          <ListItemText primary={"Submit Feedback"} />
        </ListItem>
        <ListItem component={Link} to={"/preferences"} divider button>
          <ListItemText primary={"Preferences"} />
        </ListItem>
      </List>
    </CalendarViewLayout>
  );
};

export default MorePage;
