import React from "react";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { ErrorMessage, useField } from "formik";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";

const defaultMaskOptions = {
  suffix: "",
  allowDecimal: true,
  prefix: "",
  decimalSymbol: ".",
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 7, // limit length of integer numbers
  includeThousandsSeparator: false,
  allowNegative: true,
  allowLeadingZeroes: false,
  inputMode: "numeric",
};

const MaterialMaskedInput = (props) => {
  const { inputRef, ...rest } = props;
  const currencyMask = createNumberMask(defaultMaskOptions);
  return (
    <MaskedInput
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={currencyMask}
      {...rest}
    />
  );
};

const CurrencyInput: React.FC<{
  name: string;
  label: string;
  required?: boolean;
  readOnly?: boolean;
}> = ({ readOnly = false, name, label, required = false }) => {
  const [field] = useField(name);
  return (
    <div className="mb-3">
      <FormControl fullWidth={true}>
        <InputLabel htmlFor={name} required={required}>
          {label}
        </InputLabel>
        <Input
          {...field}
          fullWidth={true}
          id="formatted-text-mask-input"
          inputComponent={MaterialMaskedInput}
          readOnly={readOnly}
        />
      </FormControl>
      <ErrorMessage name={field.name}>
        {(errorMessage) => <div style={{ color: "red" }}>{errorMessage}</div>}
      </ErrorMessage>
    </div>
  );
};

export default CurrencyInput;
