import * as React from "react";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import { ListItem } from "@material-ui/core";

export const ListItemWithSubList: React.FC<{
  onClick?: () => void;
  button?: boolean;
  divider: boolean;
  title: string;
  secondaries: (string | undefined)[];
  arrow?: boolean;
}> = ({ arrow = true, onClick, button = false, divider = false, title, secondaries }) => {
  return (
    <ListItem divider={divider} button={(button ? button : null) as any} onClick={(onClick ? onClick : null) as any}>
      <ListItemText
        primary={
          <Typography className={"block"} component={"div"}>
            {title}
          </Typography>
        }
        secondary={
          <>
            {(secondaries as string[]).map((secondaryItem, idx) => {
              return (
                <Typography key={idx} className={"block"} component={"span"} display={"block"} variant={"subtitle1"}>
                  {secondaryItem}
                </Typography>
              );
            })}
          </>
        }
      />
    </ListItem>
  );
};
