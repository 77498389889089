import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as Sentry from "@sentry/react";
import { CaptureConsole } from "@sentry/integrations";
import { Integrations } from "@sentry/tracing";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";

if (`${process.env.NODE_ENV}` === "production") {
  Sentry.init({
    dsn: "https://8134e707ccaa4df9b43850301b907e46@o93944.ingest.sentry.io/5226296",
    release: process.env.REACT_APP_SENTRY_RELEASE,
    integrations: [
      new CaptureConsole({
        levels: ["error"],
      }),
      new Integrations.BrowserTracing(),
    ],
    maxBreadcrumbs: 50,
    attachStacktrace: true,
    tracesSampleRate: 0.1,
  });
  LogRocket.init("ijawhc/technician-webapp");
  setupLogRocketReact(LogRocket);
  LogRocket.getSessionURL((sessionURL) => {
    Sentry.configureScope((scope) => {
      scope.setExtra("sessionURL", sessionURL);
    });
  });
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
