import * as React from "react";
import { FullScreenDialog } from "../FullScreenDialog";
import { Paper } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import { AddPaymentForm } from "../Forms/AddPaymentForm";

export const AddPaymentsDialog: React.FC<{
  amount: string;
  invoiceId: string;
  contactId: string;
  laborCost: string;
  partsCost: string;
  partsTax: string;
  laborTax: string;
  payer: string;
  totalTax: string;
  subTotal: string;
  open: boolean;
  onClose: () => void;
  refetch: any;
}> = ({
  laborCost,
  partsCost,
  partsTax,
  laborTax,
  subTotal,
  totalTax,
  amount,
  payer,
  invoiceId,
  contactId,
  open,
  onClose,
  refetch,
}) => {
  return (
    <FullScreenDialog title={"Add Payment"} open={open} onClose={onClose}>
      <Paper>
        <Container className={"py-4"}>
          <AddPaymentForm
            laborCost={laborCost}
            partsCost={partsCost}
            partsTax={partsTax}
            payer={payer}
            laborTax={laborTax}
            subTotal={subTotal}
            totalTax={totalTax}
            amount={amount}
            invoiceId={invoiceId}
            contactId={contactId}
            refetch={refetch}
            onClose={onClose}
          />
        </Container>
      </Paper>
    </FullScreenDialog>
  );
};
