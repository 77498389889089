import React from "react";
import { FullScreenDialog } from "../FullScreenDialog";
import { Paper, Container, Typography, Box, Button } from "@material-ui/core";
import { Mutation, MutationCloseZeroDollarInvoiceArgs } from "../../generated/nest-graphql";
import { CLOSE_ZERO_DOLLAR_INVOICE } from "../../graphql/mutations/closeJobAndInvoice";
import { useMutation } from "@apollo/client";
import * as Sentry from "@sentry/browser";

type CompleteJobDialogProps = {
  amount: string;
  invoiceId: string;
  contactId: string;
  open: boolean;
  onClose: () => void;
  refetch: any;
};

export const CompleteJobDialog = ({ invoiceId, amount, open, onClose }: CompleteJobDialogProps) => {
  const [closeZeroDollarInvoice] = useMutation<Mutation, MutationCloseZeroDollarInvoiceArgs>(CLOSE_ZERO_DOLLAR_INVOICE);
  const closeJobAndInvoice = async () => {
    try {
      await closeZeroDollarInvoice({
        variables: {
          closeZeroDollarInvoiceInput: {
            invoiceId,
          },
        },
      });
    } catch (e) {
      Sentry.captureException(e, {
        extra: {
          invoiceId,
        },
      });
    }
  };
  return (
    <FullScreenDialog title={"Complete Job and Invoice"} open={open} onClose={onClose}>
      <Paper>
        <Container className={"py-4"}>
          <Box style={{ textAlign: "center" }}>
            <Box>
              <Typography variant="h5">Are you sure you want to complete a job with 0$ invoice?</Typography>
            </Box>
            <Box mb={3}>
              <Typography variant="subtitle1">Customer will still recieve a receipt if you do this.</Typography>
            </Box>
            <Box>
              <Button onClick={closeJobAndInvoice} size="large" variant="contained" color="primary">
                Yes
              </Button>
              <Box display="inline" margin={4}></Box>
              <Button onClick={onClose} size="large" variant="contained" color="secondary">
                No
              </Button>
            </Box>
          </Box>
        </Container>
      </Paper>
    </FullScreenDialog>
  );
};
