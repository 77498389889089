import React from "react";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import { JobActionButton } from "./JobActionButton";
import { GoToInvoiceButton } from "./GoToInvoiceButton";
import { UpdateInvoiceDetailsFormValues } from "../Forms/UpdateInvoiceDetailsForm";
import { PreJobCheckListFormValues } from "./PreJobCheckListDialog";

export const JobStatusSection: React.FC<{
  message: string;
  phoneNumber: string;
  status: string;
  jobId: string;
  invoiceId?: string;
  addInvoiceInitialValues: UpdateInvoiceDetailsFormValues;
}> = ({ status, jobId, invoiceId, addInvoiceInitialValues, message, phoneNumber }) => {
  const preJobCheckListInitialValues: PreJobCheckListFormValues = {
    frontPads: addInvoiceInitialValues.frontPads,
    frontRotors: addInvoiceInitialValues.frontRotors,
    rearPads: addInvoiceInitialValues.rearPads,
    rearRotors: addInvoiceInitialValues.rearRotors,
    brakeFluid: addInvoiceInitialValues.brakeFluid,
    brakeShoes: addInvoiceInitialValues.brakeShoes,
    brakeDrums: addInvoiceInitialValues.brakeDrums,
    calipers: addInvoiceInitialValues.calipers,
    shocksStruts: addInvoiceInitialValues.shocksStruts,
    controlArms: addInvoiceInitialValues.controlArms,
    wheelHubs: addInvoiceInitialValues.wheelHubs,
    sparkPlugs: addInvoiceInitialValues.sparkPlugs,
    otherBrakeParts: addInvoiceInitialValues.otherBrakeParts,
    dashboardLights: addInvoiceInitialValues.dashboardLights,
    odometer: addInvoiceInitialValues.odometer,
  };

  return (
    <Paper square={true}>
      <Box display={"flex"}>
        <Box className="py-2 px-2 w-1/2 flex flex-col justify-center">
          <Chip color={"primary"} label={status} />
        </Box>
        <Box className={"flex-1 flex flex-row justify-end"}>
          <GoToInvoiceButton invoiceId={invoiceId} initialValues={addInvoiceInitialValues} jobId={jobId} />
          <JobActionButton
            jobId={jobId}
            jobStatus={status}
            phoneNumber={phoneNumber}
            message={message}
            preJobCheckListInitialValues={preJobCheckListInitialValues}
          />
        </Box>
      </Box>
    </Paper>
  );
};
