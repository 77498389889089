import * as React from "react";
import { Layout } from "../components/Layout";
import { AppBar } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import { useHistory } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { FeedbackForm } from "../components/Forms/FeedbackForm";

export const FeedbackPage: React.FC<{}> = () => {
  return (
    <Layout appBar={<FeedbackAppBar />}>
      <FeedbackForm />
    </Layout>
  );
};

const FeedbackAppBar: React.FC = () => {
  const { goBack } = useHistory();
  return (
    <AppBar position={"sticky"}>
      <Toolbar>
        <IconButton onClick={goBack}>
          <ArrowBackIosIcon />
        </IconButton>
        <Typography variant="h6">Submit Feedback</Typography>
        <Box />
      </Toolbar>
    </AppBar>
  );
};
