export const carDataByYear = {
  "1990": {
    Acura: { Integra: {}, Legend: {} },
    "Alfa Romeo": { Spider: {} },
    "Aston Martin": { Virage: {} },
    Audi: {
      "80": {},
      "90": {},
      "100": {},
      "200": {},
      "100 Quattro": {},
      "200 Quattro": {},
      "80 Quattro": {},
      "90 Quattro": {},
      "Coupe Quattro": {},
      "V8 Quattro": {},
    },
    BMW: {
      "325i": {},
      "325iX": {},
      "325is": {},
      "525i": {},
      "535i": {},
      "735i": {},
      "735iL": {},
      "750iL": {},
      M3: {},
    },
    Bentley: { Continental: {}, Eight: {}, Mulsanne: {}, "Turbo R": {} },
    Buick: {
      Century: {},
      Electra: {},
      LeSabre: {},
      Reatta: {},
      Regal: {},
      Riviera: {},
      Skylark: {},
    },
    Cadillac: {
      Allante: {},
      Brougham: {},
      "Commercial Chassis": {},
      DeVille: {},
      Eldorado: {},
      Fleetwood: {},
      Seville: {},
    },
    Chevrolet: {
      Astro: {},
      Beretta: {},
      Blazer: {},
      C1500: {},
      C2500: {},
      C3500: {},
      Camaro: {},
      Caprice: {},
      Cavalier: {},
      Celebrity: {},
      Corsica: {},
      Corvette: {},
      G10: {},
      G20: {},
      G30: {},
      K1500: {},
      K2500: {},
      K3500: {},
      LLV: {},
      Lumina: {},
      "Lumina APV": {},
      P30: {},
      "R1500 Suburban": {},
      "R2500 Suburban": {},
      R3500: {},
      S10: {},
      "S10 Blazer": {},
      Sprint: {},
      Tracker: {},
      "V1500 Suburban": {},
      "V2500 Suburban": {},
      V3500: {},
    },
    Chrysler: {
      Daytona: {},
      Dynasty: {},
      Imperial: {},
      LeBaron: {},
      "New Yorker": {},
      "TC Maserati": {},
      "Town & Country": {},
    },
    Daihatsu: { Charade: {}, Rocky: {} },
    Dodge: {
      "2000 GTX": {},
      B150: {},
      B250: {},
      B350: {},
      Caravan: {},
      Colt: {},
      D150: {},
      D250: {},
      D350: {},
      Dakota: {},
      Daytona: {},
      Dynasty: {},
      "Grand Caravan": {},
      Monaco: {},
      Omni: {},
      "Ram 50": {},
      Ramcharger: {},
      Shadow: {},
      Spirit: {},
      W150: {},
      W250: {},
      W350: {},
    },
    Eagle: { Premier: {}, Summit: {}, Talon: {}, Vista: {} },
    Ferrari: {
      "348 TB": {},
      "348 TS": {},
      F40: {},
      "Mondial t": {},
      Testarossa: {},
    },
    Ford: {
      Aerostar: {},
      Bronco: {},
      "Bronco II": {},
      "Country Squire": {},
      "E-150 Econoline": {},
      "E-150 Econoline Club Wagon": {},
      "E-250 Econoline": {},
      "E-250 Econoline Club Wagon": {},
      "E-350 Econoline": {},
      "E-350 Econoline Club Wagon": {},
      Escort: {},
      "F-150": {},
      "F-250": {},
      "F-350": {},
      Festiva: {},
      "LTD Crown Victoria": {},
      Mustang: {},
      Probe: {},
      Ranger: {},
      Taurus: {},
      Tempo: {},
      Thunderbird: {},
    },
    GMC: {
      C1500: {},
      C2500: {},
      C3500: {},
      G1500: {},
      G2500: {},
      G3500: {},
      Jimmy: {},
      K1500: {},
      K2500: {},
      K3500: {},
      P3500: {},
      "R1500 Suburban": {},
      "R2500 Suburban": {},
      R3500: {},
      S15: {},
      "S15 Jimmy": {},
      Safari: {},
      Tracker: {},
      "V1500 Suburban": {},
      "V2500 Suburban": {},
      V3500: {},
    },
    Geo: { Metro: {}, Prizm: {}, Storm: {}, Tracker: {} },
    Honda: { Accord: {}, CRX: {}, Civic: {}, Prelude: {} },
    Hyundai: { Excel: {}, Sonata: {} },
    Infiniti: { M30: {}, Q45: {} },
    Isuzu: { Amigo: {}, Impulse: {}, Pickup: {}, Trooper: {} },
    Jaguar: { "Vanden Plas": {}, XJ12: {}, XJ6: {}, XJS: {} },
    Jeep: {
      Cherokee: {},
      Comanche: {},
      "Grand Wagoneer": {},
      Wagoneer: {},
      Wrangler: {},
    },
    Lamborghini: { Diablo: {}, LM002: {} },
    "Land Rover": { "Range Rover": {} },
    Lexus: { ES250: {}, LS400: {} },
    Lincoln: { Continental: {}, "Mark VII": {}, "Town Car": {} },
    Lotus: { Elan: {}, Esprit: {} },
    Maserati: { "228i": {}, "430i": {}, Spyder: {} },
    Mazda: {
      "323": {},
      "626": {},
      "929": {},
      B2200: {},
      B2600: {},
      MPV: {},
      "MX-6": {},
      Miata: {},
      Protege: {},
      "RX-7": {},
    },
    "Mercedes-Benz": {
      "190E": {},
      "300CE": {},
      "300D": {},
      "300E": {},
      "300SE": {},
      "300SEL": {},
      "300SL": {},
      "300TE": {},
      "350SDL": {},
      "420SEL": {},
      "500SL": {},
      "560SEC": {},
      "560SEL": {},
    },
    Mercury: {
      "Colony Park": {},
      Cougar: {},
      "Grand Marquis": {},
      Sable: {},
      Topaz: {},
      Tracer: {},
    },
    Mitsubishi: {
      Eclipse: {},
      Galant: {},
      "Mighty Max": {},
      Mirage: {},
      Montero: {},
      Precis: {},
      Sigma: {},
      Van: {},
    },
    Nissan: {
      "240SX": {},
      "300ZX": {},
      Axxess: {},
      D21: {},
      Maxima: {},
      Micra: {},
      Pathfinder: {},
      "Pulsar NX": {},
      Sentra: {},
      Stanza: {},
    },
    Oldsmobile: {
      "98": {},
      "Custom Cruiser": {},
      "Cutlass Calais": {},
      "Cutlass Ciera": {},
      "Cutlass Cruiser": {},
      "Cutlass Supreme": {},
      "Delta 88": {},
      Silhouette: {},
      Toronado: {},
    },
    Peugeot: { "405": {}, "505": {} },
    Plymouth: {
      Acclaim: {},
      Colt: {},
      "Grand Voyager": {},
      Horizon: {},
      Laser: {},
      Sundance: {},
      Voyager: {},
    },
    Pontiac: {
      "6000": {},
      Bonneville: {},
      Firebird: {},
      Firefly: {},
      "Grand Am": {},
      "Grand Prix": {},
      LeMans: {},
      Optima: {},
      Sunbird: {},
      Tempest: {},
      "Trans Sport": {},
    },
    Porsche: { "911": {}, "928": {}, "944": {} },
    "Rolls-Royce": {
      "Corniche II": {},
      "Silver Spirit": {},
      "Silver Spur": {},
    },
    Saab: { "900": {}, "9000": {} },
    Sterling: { "827": {} },
    Subaru: { Justy: {}, Legacy: {}, Loyale: {}, XT: {} },
    Suzuki: { Samurai: {}, Sidekick: {}, Swift: {} },
    Toyota: {
      "4Runner": {},
      Camry: {},
      Celica: {},
      Corolla: {},
      Cressida: {},
      "Land Cruiser": {},
      Pickup: {},
      Supra: {},
      Tercel: {},
    },
    Utilimaster: { "Step Van": {} },
    Volkswagen: {
      Cabriolet: {},
      Corrado: {},
      Fox: {},
      Golf: {},
      Jetta: {},
      Passat: {},
      Transporter: {},
      Vanagon: {},
    },
    Volvo: { "240": {}, "740": {}, "760": {}, "780": {} },
    Yugo: { Cabrio: {}, GV: {} },
  },
  "1991": {
    Acura: { Integra: {}, Legend: {}, NSX: {} },
    "Alfa Romeo": { "164": {}, Spider: {} },
    "Aston Martin": { Virage: {} },
    Audi: {
      "80": {},
      "90": {},
      "100": {},
      "200": {},
      "100 Quattro": {},
      "200 Quattro": {},
      "80 Quattro": {},
      "90 Quattro": {},
      "Coupe Quattro": {},
      "V8 Quattro": {},
    },
    BMW: {
      "318i": {},
      "318is": {},
      "325i": {},
      "325iX": {},
      "325is": {},
      "525i": {},
      "535i": {},
      "735i": {},
      "735iL": {},
      "750iL": {},
      "850i": {},
      M3: {},
      M5: {},
    },
    Bentley: { Continental: {}, Eight: {}, Mulsanne: {}, "Turbo R": {} },
    Buick: {
      Century: {},
      "Commercial Chassis": {},
      LeSabre: {},
      "Park Avenue": {},
      Reatta: {},
      Regal: {},
      Riviera: {},
      Roadmaster: {},
      Skylark: {},
    },
    Cadillac: {
      Allante: {},
      Brougham: {},
      "Commercial Chassis": {},
      DeVille: {},
      Eldorado: {},
      Fleetwood: {},
      Seville: {},
    },
    Chevrolet: {
      Astro: {},
      Beretta: {},
      Blazer: {},
      C1500: {},
      C2500: {},
      C3500: {},
      Camaro: {},
      Caprice: {},
      Cavalier: {},
      "Commercial Chassis": {},
      Corsica: {},
      Corvette: {},
      G10: {},
      G20: {},
      G30: {},
      K1500: {},
      K2500: {},
      K3500: {},
      LLV: {},
      Lumina: {},
      "Lumina APV": {},
      P30: {},
      "R1500 Suburban": {},
      "R2500 Suburban": {},
      R3500: {},
      S10: {},
      "S10 Blazer": {},
      Sprint: {},
      Tracker: {},
      "V1500 Suburban": {},
      "V2500 Suburban": {},
      V3500: {},
    },
    Chrysler: {
      Daytona: {},
      Dynasty: {},
      Imperial: {},
      LeBaron: {},
      "New Yorker": {},
      "TC Maserati": {},
      "Town & Country": {},
    },
    Daihatsu: { Charade: {}, Rocky: {} },
    Dodge: {
      B150: {},
      B250: {},
      B350: {},
      Caravan: {},
      Colt: {},
      D150: {},
      D250: {},
      D350: {},
      Dakota: {},
      Daytona: {},
      Dynasty: {},
      "Grand Caravan": {},
      Monaco: {},
      "Ram 50": {},
      Ramcharger: {},
      Shadow: {},
      Spirit: {},
      Stealth: {},
      W150: {},
      W250: {},
      W350: {},
    },
    Eagle: { "2000 GTX": {}, Premier: {}, Summit: {}, Talon: {}, Vista: {} },
    Ferrari: {
      "348 TB": {},
      "348 TS": {},
      F40: {},
      "Mondial t": {},
      Testarossa: {},
    },
    Ford: {
      Aerostar: {},
      Bronco: {},
      "Country Squire": {},
      "E-150 Econoline": {},
      "E-150 Econoline Club Wagon": {},
      "E-250 Econoline": {},
      "E-250 Econoline Club Wagon": {},
      "E-350 Econoline": {},
      "E-350 Econoline Club Wagon": {},
      Escort: {},
      Explorer: {},
      "F-150": {},
      "F-250": {},
      "F-350": {},
      Festiva: {},
      "LTD Crown Victoria": {},
      Mustang: {},
      Probe: {},
      Ranger: {},
      Taurus: {},
      Tempo: {},
      Thunderbird: {},
    },
    GMC: {
      C1500: {},
      C2500: {},
      C3500: {},
      G1500: {},
      G2500: {},
      G3500: {},
      Jimmy: {},
      K1500: {},
      K2500: {},
      K3500: {},
      P3500: {},
      "R1500 Suburban": {},
      "R2500 Suburban": {},
      R3500: {},
      "S15 Jimmy": {},
      Safari: {},
      Sonoma: {},
      Syclone: {},
      Tracker: {},
      "V1500 Suburban": {},
      "V2500 Suburban": {},
      V3500: {},
    },
    Geo: { Metro: {}, Prizm: {}, Storm: {}, Tracker: {} },
    Honda: { Accord: {}, CRX: {}, Civic: {}, Prelude: {} },
    Hyundai: { Excel: {}, Scoupe: {}, Sonata: {} },
    Infiniti: { G20: {}, M30: {}, Q45: {} },
    Isuzu: {
      Amigo: {},
      Impulse: {},
      Pickup: {},
      Rodeo: {},
      Stylus: {},
      Trooper: {},
    },
    Jaguar: { "Vanden Plas": {}, XJ12: {}, XJ6: {}, XJS: {} },
    Jeep: { Cherokee: {}, Comanche: {}, "Grand Wagoneer": {}, Wrangler: {} },
    Lamborghini: { Diablo: {}, "LM American": {} },
    "Land Rover": { "Range Rover": {} },
    Lexus: { ES250: {}, LS400: {} },
    Lincoln: { Continental: {}, "Mark VII": {}, "Town Car": {} },
    Lotus: { Elan: {}, Esprit: {} },
    Maserati: { Spyder: {} },
    Mazda: {
      "323": {},
      "626": {},
      "929": {},
      B2200: {},
      B2600: {},
      MPV: {},
      "MX-6": {},
      Miata: {},
      Navajo: {},
      Protege: {},
      "RX-7": {},
    },
    "Mercedes-Benz": {
      "190E": {},
      "300CE": {},
      "300D": {},
      "300E": {},
      "300SE": {},
      "300SEL": {},
      "300SL": {},
      "300TE": {},
      "350SD": {},
      "350SDL": {},
      "420SEL": {},
      "500SL": {},
      "560SEC": {},
      "560SEL": {},
    },
    Mercury: {
      Capri: {},
      "Colony Park": {},
      Cougar: {},
      "Grand Marquis": {},
      Sable: {},
      Topaz: {},
      Tracer: {},
    },
    Mitsubishi: {
      "3000GT": {},
      Eclipse: {},
      Galant: {},
      "Mighty Max": {},
      Mirage: {},
      Montero: {},
      Precis: {},
    },
    Nissan: {
      "240SX": {},
      "300ZX": {},
      Axxess: {},
      D21: {},
      Maxima: {},
      Micra: {},
      NX: {},
      Pathfinder: {},
      Sentra: {},
      Stanza: {},
    },
    Oldsmobile: {
      "98": {},
      Bravada: {},
      "Custom Cruiser": {},
      "Cutlass Calais": {},
      "Cutlass Ciera": {},
      "Cutlass Cruiser": {},
      "Cutlass Supreme": {},
      "Delta 88": {},
      Silhouette: {},
      Toronado: {},
    },
    Peugeot: { "405": {}, "505": {} },
    Plymouth: {
      Acclaim: {},
      Colt: {},
      "Grand Voyager": {},
      Laser: {},
      Sundance: {},
      Voyager: {},
    },
    Pontiac: {
      "6000": {},
      Bonneville: {},
      Firebird: {},
      Firefly: {},
      "Grand Am": {},
      "Grand Prix": {},
      LeMans: {},
      Optima: {},
      Sunbird: {},
      Tempest: {},
      "Trans Sport": {},
    },
    Porsche: { "911": {}, "928": {}, "944": {} },
    "Rolls-Royce": {
      "Corniche II": {},
      "Silver Spirit": {},
      "Silver Spur": {},
    },
    Saab: { "900": {}, "9000": {} },
    Saturn: { SC: {}, SL: {}, SL1: {}, SL2: {} },
    Sterling: { "827": {} },
    Subaru: { Justy: {}, Legacy: {}, Loyale: {}, XT: {} },
    Suzuki: { Samurai: {}, Sidekick: {}, Swift: {} },
    Toyota: {
      "4Runner": {},
      Camry: {},
      Celica: {},
      Corolla: {},
      Cressida: {},
      "Land Cruiser": {},
      MR2: {},
      Pickup: {},
      Previa: {},
      Supra: {},
      Tercel: {},
    },
    Utilimaster: { "Step Van": {} },
    Volkswagen: {
      Cabriolet: {},
      Corrado: {},
      Fox: {},
      Golf: {},
      Jetta: {},
      Passat: {},
      Transporter: {},
      Vanagon: {},
    },
    Volvo: { "240": {}, "740": {}, "780": {}, "940": {} },
    Yugo: { Cabrio: {}, GV: {} },
  },
  "1992": {
    "AM General": { Hummer: {} },
    Acura: { Integra: {}, Legend: {}, NSX: {}, Vigor: {} },
    "Alfa Romeo": { "164": {}, Spider: {} },
    "Aston Martin": { Virage: {} },
    Audi: {
      "80": {},
      "100": {},
      "100 Quattro": {},
      "80 Quattro": {},
      "90 Quattro": {},
      S4: {},
      "V8 Quattro": {},
    },
    BMW: {
      "318i": {},
      "318is": {},
      "320i": {},
      "325i": {},
      "325is": {},
      "525i": {},
      "535i": {},
      "735i": {},
      "735iL": {},
      "750iL": {},
      "850i": {},
      M5: {},
    },
    Bentley: { Continental: {}, Mulsanne: {}, "Turbo R": {} },
    Buick: {
      Century: {},
      "Commercial Chassis": {},
      LeSabre: {},
      "Park Avenue": {},
      Regal: {},
      Riviera: {},
      Roadmaster: {},
      Skylark: {},
    },
    Cadillac: {
      Allante: {},
      Brougham: {},
      "Commercial Chassis": {},
      DeVille: {},
      Eldorado: {},
      Fleetwood: {},
      Seville: {},
    },
    Chevrolet: {
      Astro: {},
      Beretta: {},
      Blazer: {},
      C1500: {},
      "C1500 Suburban": {},
      C2500: {},
      "C2500 Suburban": {},
      C3500: {},
      Camaro: {},
      Caprice: {},
      Cavalier: {},
      "Commercial Chassis": {},
      Corsica: {},
      Corvette: {},
      G10: {},
      G20: {},
      G30: {},
      K1500: {},
      "K1500 Suburban": {},
      K2500: {},
      "K2500 Suburban": {},
      K3500: {},
      LLV: {},
      Lumina: {},
      "Lumina APV": {},
      Metro: {},
      P30: {},
      S10: {},
      "S10 Blazer": {},
      Sprint: {},
    },
    Chrysler: {
      Daytona: {},
      Dynasty: {},
      Imperial: {},
      LeBaron: {},
      "New Yorker": {},
      "Town & Country": {},
    },
    Daihatsu: { Charade: {}, Rocky: {} },
    Dodge: {
      B150: {},
      B250: {},
      B350: {},
      Caravan: {},
      Colt: {},
      D150: {},
      D250: {},
      D350: {},
      Dakota: {},
      Daytona: {},
      Dynasty: {},
      "Grand Caravan": {},
      Monaco: {},
      "Ram 50": {},
      Ramcharger: {},
      Shadow: {},
      Spirit: {},
      Stealth: {},
      Viper: {},
      W150: {},
      W250: {},
      W350: {},
    },
    Eagle: { "2000 GTX": {}, Premier: {}, Summit: {}, Talon: {}, Vista: {} },
    Ferrari: {
      "348 TB": {},
      "348 TS": {},
      "512 TR": {},
      F40: {},
      "Mondial t": {},
    },
    Ford: {
      Aerostar: {},
      Bronco: {},
      "Crown Victoria": {},
      "E-150 Econoline": {},
      "E-150 Econoline Club Wagon": {},
      "E-250 Econoline": {},
      "E-350 Econoline": {},
      "E-350 Econoline Club Wagon": {},
      Escort: {},
      Explorer: {},
      "F-150": {},
      "F-250": {},
      "F-350": {},
      Festiva: {},
      Mustang: {},
      Probe: {},
      Ranger: {},
      Taurus: {},
      Tempo: {},
      Thunderbird: {},
    },
    GMC: {
      C1500: {},
      "C1500 Suburban": {},
      C2500: {},
      "C2500 Suburban": {},
      C3500: {},
      G1500: {},
      G2500: {},
      G3500: {},
      Jimmy: {},
      K1500: {},
      "K1500 Suburban": {},
      K2500: {},
      "K2500 Suburban": {},
      K3500: {},
      P3500: {},
      Safari: {},
      Sonoma: {},
      Typhoon: {},
      Yukon: {},
    },
    Geo: { Metro: {}, Prizm: {}, Storm: {}, Tracker: {} },
    Honda: { Accord: {}, Civic: {}, Prelude: {} },
    Hyundai: { Elantra: {}, Excel: {}, Scoupe: {}, Sonata: {} },
    Infiniti: { G20: {}, M30: {}, Q45: {} },
    Isuzu: {
      Amigo: {},
      Impulse: {},
      Pickup: {},
      Rodeo: {},
      Stylus: {},
      Trooper: {},
    },
    Jaguar: { "Vanden Plas": {}, XJ12: {}, XJ6: {}, XJS: {} },
    Jeep: { Cherokee: {}, Comanche: {}, Wrangler: {} },
    Lamborghini: { Diablo: {}, "LM American": {} },
    "Land Rover": { "Range Rover": {} },
    Lexus: { ES300: {}, LS400: {}, SC300: {}, SC400: {} },
    Lincoln: { Continental: {}, "Mark VII": {}, "Town Car": {} },
    Lotus: { Elan: {}, Esprit: {} },
    Mazda: {
      "323": {},
      "626": {},
      "929": {},
      B2200: {},
      B2600: {},
      MPV: {},
      "MX-3": {},
      "MX-6": {},
      Miata: {},
      Navajo: {},
      Protege: {},
    },
    "Mercedes-Benz": {
      "190E": {},
      "300CE": {},
      "300D": {},
      "300E": {},
      "300SD": {},
      "300SE": {},
      "300SL": {},
      "300TE": {},
      "400E": {},
      "400SE": {},
      "500E": {},
      "500SEL": {},
      "500SL": {},
      "600SEL": {},
    },
    Mercury: {
      Capri: {},
      Cougar: {},
      "Grand Marquis": {},
      Sable: {},
      Topaz: {},
      Tracer: {},
    },
    Mitsubishi: {
      "3000GT": {},
      Diamante: {},
      Eclipse: {},
      Expo: {},
      "Expo LRV": {},
      Galant: {},
      "Mighty Max": {},
      Mirage: {},
      Montero: {},
      Precis: {},
    },
    Nissan: {
      "240SX": {},
      "300ZX": {},
      Axxess: {},
      D21: {},
      Maxima: {},
      NX: {},
      Pathfinder: {},
      Sentra: {},
      Stanza: {},
    },
    Oldsmobile: {
      "88": {},
      "98": {},
      Achieva: {},
      Bravada: {},
      "Custom Cruiser": {},
      "Cutlass Ciera": {},
      "Cutlass Cruiser": {},
      "Cutlass Supreme": {},
      Silhouette: {},
      Toronado: {},
    },
    Plymouth: {
      Acclaim: {},
      Colt: {},
      "Grand Voyager": {},
      Laser: {},
      Sundance: {},
      Voyager: {},
    },
    Pontiac: {
      Bonneville: {},
      Firebird: {},
      "Grand Am": {},
      "Grand Prix": {},
      LeMans: {},
      Sunbird: {},
      "Trans Sport": {},
    },
    Porsche: { "911": {}, "968": {} },
    "Rolls-Royce": {
      "Corniche II": {},
      "Silver Spirit": {},
      "Silver Spur": {},
    },
    Saab: { "900": {}, "9000": {} },
    Saturn: { SC: {}, SL: {}, SL1: {}, SL2: {} },
    Subaru: { Justy: {}, Legacy: {}, Loyale: {}, SVX: {} },
    Suzuki: { Samurai: {}, Sidekick: {}, Swift: {} },
    Toyota: {
      "4Runner": {},
      Camry: {},
      Celica: {},
      Corolla: {},
      Cressida: {},
      "Land Cruiser": {},
      MR2: {},
      Paseo: {},
      Pickup: {},
      Previa: {},
      Supra: {},
      Tercel: {},
    },
    Utilimaster: { "Step Van": {} },
    Volkswagen: {
      Cabriolet: {},
      Corrado: {},
      EuroVan: {},
      Fox: {},
      Golf: {},
      Jetta: {},
      Passat: {},
      Transporter: {},
    },
    Volvo: { "240": {}, "740": {}, "940": {}, "960": {} },
    Yugo: { Cabrio: {}, GV: {} },
  },
  "1993": {
    "AM General": { Hummer: {} },
    Acura: { Integra: {}, Legend: {}, NSX: {}, Vigor: {} },
    "Alfa Romeo": { "164": {}, Spider: {} },
    "Aston Martin": { Virage: {} },
    Audi: {
      "90": {},
      "100": {},
      "100 Quattro": {},
      "90 Quattro": {},
      S4: {},
      "V8 Quattro": {},
    },
    BMW: {
      "318i": {},
      "318is": {},
      "320i": {},
      "325i": {},
      "325is": {},
      "525i": {},
      "525iT": {},
      "535i": {},
      "740i": {},
      "740iL": {},
      "750iL": {},
      "850Ci": {},
      M5: {},
    },
    Bentley: { Brooklands: {}, Continental: {}, "Turbo R": {} },
    Buick: {
      Century: {},
      "Commercial Chassis": {},
      LeSabre: {},
      "Park Avenue": {},
      Regal: {},
      Riviera: {},
      Roadmaster: {},
      Skylark: {},
    },
    Cadillac: {
      "60 Special": {},
      Allante: {},
      "Commercial Chassis": {},
      DeVille: {},
      Eldorado: {},
      Fleetwood: {},
      Seville: {},
    },
    Chevrolet: {
      Astro: {},
      Beretta: {},
      Blazer: {},
      C1500: {},
      "C1500 Suburban": {},
      C2500: {},
      "C2500 Suburban": {},
      C3500: {},
      Camaro: {},
      Caprice: {},
      Cavalier: {},
      "Commercial Chassis": {},
      Corsica: {},
      Corvette: {},
      G10: {},
      G20: {},
      G30: {},
      K1500: {},
      "K1500 Suburban": {},
      K2500: {},
      "K2500 Suburban": {},
      K3500: {},
      LLV: {},
      Lumina: {},
      "Lumina APV": {},
      P30: {},
      S10: {},
      "S10 Blazer": {},
    },
    Chrysler: {
      Concorde: {},
      Daytona: {},
      Dynasty: {},
      Imperial: {},
      Intrepid: {},
      LeBaron: {},
      "New Yorker": {},
      "Town & Country": {},
    },
    Dodge: {
      B150: {},
      B250: {},
      B350: {},
      Caravan: {},
      Colt: {},
      D150: {},
      D250: {},
      D350: {},
      Dakota: {},
      Daytona: {},
      Dynasty: {},
      "Grand Caravan": {},
      Intrepid: {},
      "Ram 50": {},
      Ramcharger: {},
      Shadow: {},
      Spirit: {},
      Stealth: {},
      Viper: {},
      W150: {},
      W250: {},
      W350: {},
    },
    Eagle: { "2000 GTX": {}, Summit: {}, Talon: {}, Vision: {} },
    Ferrari: {
      "348 GTB": {},
      "348 GTS": {},
      "348 Spider": {},
      "512 TR": {},
      "Mondial t": {},
    },
    Ford: {
      Aerostar: {},
      Bronco: {},
      "Crown Victoria": {},
      "E-150 Econoline": {},
      "E-150 Econoline Club Wagon": {},
      "E-250 Econoline": {},
      "E-350 Econoline": {},
      "E-350 Econoline Club Wagon": {},
      Escort: {},
      Explorer: {},
      "F-150": {},
      "F-250": {},
      "F-350": {},
      Festiva: {},
      Mustang: {},
      Probe: {},
      Ranger: {},
      Taurus: {},
      Tempo: {},
      Thunderbird: {},
    },
    GMC: {
      C1500: {},
      "C1500 Suburban": {},
      C2500: {},
      "C2500 Suburban": {},
      C3500: {},
      G1500: {},
      G2500: {},
      G3500: {},
      Jimmy: {},
      K1500: {},
      "K1500 Suburban": {},
      K2500: {},
      "K2500 Suburban": {},
      K3500: {},
      P3500: {},
      Safari: {},
      Sonoma: {},
      Typhoon: {},
      Yukon: {},
    },
    Geo: { Metro: {}, Prizm: {}, Storm: {}, Tracker: {} },
    Honda: { Accord: {}, Civic: {}, "Civic del Sol": {}, Prelude: {} },
    Hyundai: { Elantra: {}, Excel: {}, Scoupe: {}, Sonata: {} },
    Infiniti: { G20: {}, J30: {}, Q45: {} },
    Isuzu: { Amigo: {}, Pickup: {}, Rodeo: {}, Stylus: {}, Trooper: {} },
    Jaguar: { "Vanden Plas": {}, XJ6: {}, XJRS: {}, XJS: {} },
    Jeep: {
      Cherokee: {},
      "Grand Cherokee": {},
      "Grand Wagoneer": {},
      Wrangler: {},
    },
    Lamborghini: { Diablo: {}, "LM American": {} },
    "Land Rover": { "Defender 110": {}, "Range Rover": {} },
    Lexus: { ES300: {}, GS300: {}, LS400: {}, SC300: {}, SC400: {} },
    Lincoln: { Continental: {}, "Mark VIII": {}, "Town Car": {} },
    Lotus: { Esprit: {} },
    Mazda: {
      "323": {},
      "626": {},
      "929": {},
      B2200: {},
      B2600: {},
      MPV: {},
      "MX-3": {},
      "MX-6": {},
      Miata: {},
      Navajo: {},
      Protege: {},
      "RX-7": {},
    },
    "Mercedes-Benz": {
      "190E": {},
      "300CE": {},
      "300D": {},
      "300E": {},
      "300SD": {},
      "300SE": {},
      "300SL": {},
      "300TE": {},
      "400E": {},
      "400SEL": {},
      "500E": {},
      "500SEC": {},
      "500SEL": {},
      "500SL": {},
      "600SEC": {},
      "600SEL": {},
      "600SL": {},
    },
    Mercury: {
      Capri: {},
      Cougar: {},
      "Grand Marquis": {},
      Sable: {},
      Topaz: {},
      Tracer: {},
      Villager: {},
    },
    Mitsubishi: {
      "3000GT": {},
      Diamante: {},
      Eclipse: {},
      Expo: {},
      "Expo LRV": {},
      Galant: {},
      "Mighty Max": {},
      Mirage: {},
      Montero: {},
      Precis: {},
    },
    Nissan: {
      "240SX": {},
      "300ZX": {},
      Altima: {},
      Axxess: {},
      D21: {},
      Maxima: {},
      NX: {},
      Pathfinder: {},
      Quest: {},
      Sentra: {},
    },
    Oldsmobile: {
      "88": {},
      "98": {},
      Achieva: {},
      Bravada: {},
      "Cutlass Ciera": {},
      "Cutlass Cruiser": {},
      "Cutlass Supreme": {},
      Silhouette: {},
    },
    Plymouth: {
      Acclaim: {},
      Colt: {},
      "Grand Voyager": {},
      Laser: {},
      Sundance: {},
      Voyager: {},
    },
    Pontiac: {
      Bonneville: {},
      Firebird: {},
      "Grand Am": {},
      "Grand Prix": {},
      LeMans: {},
      Sunbird: {},
      "Trans Sport": {},
    },
    Porsche: { "911": {}, "928": {}, "968": {} },
    "Rolls-Royce": {
      "Corniche IV": {},
      "Silver Spirit": {},
      "Silver Spur": {},
    },
    Saab: { "900": {}, "9000": {} },
    Saturn: { SC1: {}, SC2: {}, SL: {}, SL1: {}, SL2: {}, SW1: {}, SW2: {} },
    Subaru: { Impreza: {}, Justy: {}, Legacy: {}, Loyale: {}, SVX: {} },
    Suzuki: { Samurai: {}, Sidekick: {}, Swift: {} },
    Toyota: {
      "4Runner": {},
      Camry: {},
      Celica: {},
      Corolla: {},
      "Land Cruiser": {},
      MR2: {},
      Paseo: {},
      Pickup: {},
      Previa: {},
      Supra: {},
      T100: {},
      Tercel: {},
    },
    Utilimaster: { "Step Van": {} },
    Volkswagen: {
      Cabriolet: {},
      Corrado: {},
      EuroVan: {},
      Fox: {},
      Golf: {},
      Jetta: {},
      Passat: {},
      Transporter: {},
    },
    Volvo: { "240": {}, "850": {}, "940": {}, "960": {} },
  },
  "1994": {
    "AM General": { Hummer: {} },
    Acura: { Integra: {}, Legend: {}, NSX: {}, Vigor: {} },
    "Alfa Romeo": { "164": {}, Spider: {} },
    "Aston Martin": { Virage: {} },
    Audi: {
      "90": {},
      "100": {},
      "100 Quattro": {},
      "90 Quattro": {},
      Cabriolet: {},
      S4: {},
      "V8 Quattro": {},
    },
    BMW: {
      "318i": {},
      "318is": {},
      "320i": {},
      "325i": {},
      "325is": {},
      "525i": {},
      "530i": {},
      "540i": {},
      "740i": {},
      "740iL": {},
      "750iL": {},
      "840Ci": {},
      "850CSi": {},
      "850Ci": {},
      M3: {},
    },
    Bentley: { Brooklands: {}, Continental: {}, "Turbo R": {} },
    Buick: {
      Century: {},
      "Commercial Chassis": {},
      LeSabre: {},
      "Park Avenue": {},
      Regal: {},
      Roadmaster: {},
      Skylark: {},
    },
    Cadillac: {
      "Commercial Chassis": {},
      DeVille: {},
      Eldorado: {},
      Fleetwood: {},
      Seville: {},
    },
    Chevrolet: {
      Astro: {},
      Beretta: {},
      Blazer: {},
      C1500: {},
      "C1500 Suburban": {},
      C2500: {},
      "C2500 Suburban": {},
      C3500: {},
      Camaro: {},
      Caprice: {},
      Cavalier: {},
      "Commercial Chassis": {},
      Corsica: {},
      Corvette: {},
      G10: {},
      G20: {},
      G30: {},
      Impala: {},
      K1500: {},
      "K1500 Suburban": {},
      K2500: {},
      "K2500 Suburban": {},
      K3500: {},
      LLV: {},
      Lumina: {},
      "Lumina APV": {},
      P30: {},
      S10: {},
      "S10 Blazer": {},
    },
    Chrysler: {
      Concorde: {},
      Intrepid: {},
      LHS: {},
      LeBaron: {},
      "New Yorker": {},
      "Town & Country": {},
    },
    Dodge: {
      B150: {},
      B250: {},
      B350: {},
      Caravan: {},
      Colt: {},
      Dakota: {},
      "Grand Caravan": {},
      Intrepid: {},
      "Ram 1500": {},
      "Ram 2500": {},
      "Ram 3500": {},
      Shadow: {},
      Spirit: {},
      Stealth: {},
      Viper: {},
    },
    Eagle: { Summit: {}, Talon: {}, Vision: {} },
    Ferrari: {
      "348 GTB": {},
      "348 GTS": {},
      "348 Spider": {},
      "512 TR": {},
      "F355 Berlinetta": {},
      "F355 GTS": {},
    },
    Ford: {
      Aerostar: {},
      Aspire: {},
      Bronco: {},
      "Crown Victoria": {},
      "E-150 Econoline": {},
      "E-150 Econoline Club Wagon": {},
      "E-250 Econoline": {},
      "E-350 Econoline": {},
      "E-350 Econoline Club Wagon": {},
      Escort: {},
      Explorer: {},
      "F-150": {},
      "F-250": {},
      "F-350": {},
      Mustang: {},
      Probe: {},
      Ranger: {},
      Taurus: {},
      Tempo: {},
      Thunderbird: {},
    },
    GMC: {
      C1500: {},
      "C1500 Suburban": {},
      C2500: {},
      "C2500 Suburban": {},
      C3500: {},
      G1500: {},
      G2500: {},
      G3500: {},
      Jimmy: {},
      K1500: {},
      "K1500 Suburban": {},
      K2500: {},
      "K2500 Suburban": {},
      K3500: {},
      P3500: {},
      Safari: {},
      Sonoma: {},
      Yukon: {},
    },
    Geo: { Metro: {}, Prizm: {}, Tracker: {} },
    Honda: {
      Accord: {},
      Civic: {},
      "Civic del Sol": {},
      Passport: {},
      Prelude: {},
    },
    Hyundai: { Elantra: {}, Excel: {}, Scoupe: {}, Sonata: {} },
    Infiniti: { G20: {}, J30: {}, Q45: {} },
    Isuzu: { Amigo: {}, Pickup: {}, Rodeo: {}, Trooper: {} },
    Jaguar: { "Vanden Plas": {}, XJ12: {}, XJ6: {}, XJS: {} },
    Jeep: { Cherokee: {}, "Grand Cherokee": {}, Wrangler: {} },
    Kia: { Sephia: {} },
    Lamborghini: { Diablo: {} },
    "Land Rover": { "Defender 90": {}, Discovery: {}, "Range Rover": {} },
    Lexus: { ES300: {}, GS300: {}, LS400: {}, SC300: {}, SC400: {} },
    Lincoln: { Continental: {}, "Mark VIII": {}, "Town Car": {} },
    Lotus: { Esprit: {} },
    Mazda: {
      "323": {},
      "626": {},
      "929": {},
      B2300: {},
      B3000: {},
      B4000: {},
      MPV: {},
      "MX-3": {},
      "MX-6": {},
      Miata: {},
      Navajo: {},
      Protege: {},
      "RX-7": {},
    },
    "Mercedes-Benz": {
      C220: {},
      C280: {},
      E320: {},
      E420: {},
      E500: {},
      S320: {},
      S350: {},
      S420: {},
      S500: {},
      S600: {},
      SL320: {},
      SL500: {},
      SL600: {},
    },
    Mercury: {
      Capri: {},
      Cougar: {},
      "Grand Marquis": {},
      Sable: {},
      Topaz: {},
      Tracer: {},
      Villager: {},
    },
    Mitsubishi: {
      "3000GT": {},
      Diamante: {},
      Eclipse: {},
      Expo: {},
      "Expo LRV": {},
      Galant: {},
      "Mighty Max": {},
      Mirage: {},
      Montero: {},
      Precis: {},
    },
    Nissan: {
      "240SX": {},
      "300ZX": {},
      Altima: {},
      Axxess: {},
      D21: {},
      Maxima: {},
      Pathfinder: {},
      Quest: {},
      Sentra: {},
    },
    Oldsmobile: {
      "88": {},
      "98": {},
      Achieva: {},
      Bravada: {},
      "Cutlass Ciera": {},
      "Cutlass Cruiser": {},
      "Cutlass Supreme": {},
      Silhouette: {},
    },
    Plymouth: {
      Acclaim: {},
      Colt: {},
      "Grand Voyager": {},
      Laser: {},
      Sundance: {},
      Voyager: {},
    },
    Pontiac: {
      Bonneville: {},
      Firebird: {},
      Firefly: {},
      "Grand Am": {},
      "Grand Prix": {},
      Sunbird: {},
      Sunrunner: {},
      "Trans Sport": {},
    },
    Porsche: { "911": {}, "928": {}, "968": {} },
    "Rolls-Royce": {
      "Corniche IV": {},
      "Silver Spirit": {},
      "Silver Spur": {},
      "Touring Limousine": {},
    },
    Saab: { "900": {}, "9000": {} },
    Saturn: { SC1: {}, SC2: {}, SL: {}, SL1: {}, SL2: {}, SW1: {}, SW2: {} },
    Subaru: { Impreza: {}, Justy: {}, Legacy: {}, Loyale: {}, SVX: {} },
    Suzuki: { Samurai: {}, Sidekick: {}, Swift: {} },
    Toyota: {
      "4Runner": {},
      Camry: {},
      Celica: {},
      Corolla: {},
      "Land Cruiser": {},
      MR2: {},
      Paseo: {},
      Pickup: {},
      Previa: {},
      Supra: {},
      T100: {},
      Tercel: {},
    },
    Utilimaster: { "Step Van": {} },
    Volkswagen: {
      Corrado: {},
      EuroVan: {},
      Golf: {},
      Jetta: {},
      Passat: {},
      Transporter: {},
    },
    Volvo: { "850": {}, "940": {}, "960": {} },
  },
  "1995": {
    "AM General": { Hummer: {} },
    Acura: { Integra: {}, Legend: {}, NSX: {}, TL: {} },
    "Alfa Romeo": { "164": {} },
    Audi: {
      "90": {},
      "90 Quattro": {},
      A6: {},
      "A6 Quattro": {},
      Cabriolet: {},
      S6: {},
    },
    BMW: {
      "318i": {},
      "318is": {},
      "318ti": {},
      "320i": {},
      "325i": {},
      "325is": {},
      "525i": {},
      "530i": {},
      "540i": {},
      "740i": {},
      "740iL": {},
      "750iL": {},
      "840Ci": {},
      "850CSi": {},
      "850Ci": {},
      M3: {},
    },
    Bentley: { Brooklands: {}, Continental: {}, "Turbo R": {} },
    Buick: {
      Century: {},
      "Commercial Chassis": {},
      LeSabre: {},
      "Park Avenue": {},
      Regal: {},
      Riviera: {},
      Roadmaster: {},
      Skylark: {},
    },
    Cadillac: {
      "Commercial Chassis": {},
      DeVille: {},
      Eldorado: {},
      Fleetwood: {},
      Seville: {},
    },
    Chevrolet: {
      Astro: {},
      Beretta: {},
      Blazer: {},
      C1500: {},
      "C1500 Suburban": {},
      C2500: {},
      "C2500 Suburban": {},
      C3500: {},
      Camaro: {},
      Caprice: {},
      Cavalier: {},
      Corsica: {},
      Corvette: {},
      G10: {},
      G20: {},
      G30: {},
      Impala: {},
      K1500: {},
      "K1500 Suburban": {},
      K2500: {},
      "K2500 Suburban": {},
      K3500: {},
      LLV: {},
      Lumina: {},
      "Lumina APV": {},
      "Monte Carlo": {},
      P30: {},
      S10: {},
      Tahoe: {},
    },
    Chrysler: {
      Cirrus: {},
      Concorde: {},
      Intrepid: {},
      LHS: {},
      LeBaron: {},
      "New Yorker": {},
      Sebring: {},
      "Town & Country": {},
    },
    Dodge: {
      Avenger: {},
      B1500: {},
      B2500: {},
      B3500: {},
      Caravan: {},
      Colt: {},
      Dakota: {},
      "Grand Caravan": {},
      Intrepid: {},
      Neon: {},
      "Ram 1500": {},
      "Ram 2500": {},
      "Ram 3500": {},
      Spirit: {},
      Stealth: {},
      Stratus: {},
      Viper: {},
    },
    Eagle: { Summit: {}, Talon: {}, Vision: {} },
    Ferrari: {
      "348 Spider": {},
      "456 GT": {},
      "512 M": {},
      "F355 Berlinetta": {},
      "F355 GTS": {},
      F50: {},
    },
    Ford: {
      Aerostar: {},
      Aspire: {},
      Bronco: {},
      Contour: {},
      "Crown Victoria": {},
      "E-150 Econoline": {},
      "E-150 Econoline Club Wagon": {},
      "E-250 Econoline": {},
      "E-350 Econoline": {},
      "E-350 Econoline Club Wagon": {},
      Escort: {},
      Explorer: {},
      "F-150": {},
      "F-250": {},
      "F-350": {},
      Mustang: {},
      Probe: {},
      Ranger: {},
      Taurus: {},
      Thunderbird: {},
      Windstar: {},
    },
    GMC: {
      C1500: {},
      "C1500 Suburban": {},
      C2500: {},
      "C2500 Suburban": {},
      C3500: {},
      G1500: {},
      G2500: {},
      G3500: {},
      Jimmy: {},
      K1500: {},
      "K1500 Suburban": {},
      K2500: {},
      "K2500 Suburban": {},
      K3500: {},
      P3500: {},
      Safari: {},
      Sonoma: {},
      Yukon: {},
    },
    Geo: { Metro: {}, Prizm: {}, Tracker: {} },
    Honda: {
      Accord: {},
      Civic: {},
      "Civic del Sol": {},
      Odyssey: {},
      Passport: {},
      Prelude: {},
    },
    Hyundai: { Accent: {}, Elantra: {}, Scoupe: {}, Sonata: {} },
    Infiniti: { G20: {}, J30: {}, Q45: {} },
    Isuzu: { Pickup: {}, Rodeo: {}, Trooper: {} },
    Jaguar: { "Vanden Plas": {}, XJ12: {}, XJ6: {}, XJR: {}, XJS: {} },
    Jeep: { Cherokee: {}, "Grand Cherokee": {}, Wrangler: {} },
    Kia: { Sephia: {}, Sportage: {} },
    Lamborghini: { Diablo: {} },
    "Land Rover": { "Defender 90": {}, Discovery: {}, "Range Rover": {} },
    Lexus: { ES300: {}, GS300: {}, LS400: {}, SC300: {}, SC400: {} },
    Lincoln: { Continental: {}, "Mark VIII": {}, "Town Car": {} },
    Lotus: { Esprit: {} },
    Mazda: {
      "323": {},
      "626": {},
      "929": {},
      B2300: {},
      B3000: {},
      B4000: {},
      MPV: {},
      "MX-3": {},
      "MX-6": {},
      Miata: {},
      Millenia: {},
      Protege: {},
      "RX-7": {},
    },
    "Mercedes-Benz": {
      C220: {},
      C280: {},
      "C36 AMG": {},
      E300: {},
      E320: {},
      E420: {},
      S320: {},
      S350: {},
      S420: {},
      S500: {},
      S600: {},
      SL320: {},
      SL500: {},
      SL600: {},
    },
    Mercury: {
      Cougar: {},
      "Grand Marquis": {},
      Mystique: {},
      Sable: {},
      Tracer: {},
      Villager: {},
    },
    Mitsubishi: {
      "3000GT": {},
      Diamante: {},
      Eclipse: {},
      Expo: {},
      Galant: {},
      "Mighty Max": {},
      Mirage: {},
      Montero: {},
    },
    Nissan: {
      "200SX": {},
      "240SX": {},
      "300ZX": {},
      Altima: {},
      Axxess: {},
      Maxima: {},
      Pathfinder: {},
      Pickup: {},
      Quest: {},
      Sentra: {},
    },
    Oldsmobile: {
      "88": {},
      "98": {},
      Achieva: {},
      Aurora: {},
      "Cutlass Ciera": {},
      "Cutlass Supreme": {},
      Silhouette: {},
    },
    Plymouth: {
      Acclaim: {},
      Colt: {},
      "Grand Voyager": {},
      Neon: {},
      Voyager: {},
    },
    Pontiac: {
      Bonneville: {},
      Firebird: {},
      Firefly: {},
      "Grand Am": {},
      "Grand Prix": {},
      Sunfire: {},
      Sunrunner: {},
      "Trans Sport": {},
    },
    Porsche: { "911": {}, "928": {}, "968": {} },
    "Rolls-Royce": {
      "Corniche IV": {},
      "Corniche S": {},
      "Flying Spur": {},
      "Silver Dawn": {},
      "Silver Spirit": {},
      "Silver Spur": {},
    },
    Saab: { "900": {}, "9000": {} },
    Saturn: { SC1: {}, SC2: {}, SL: {}, SL1: {}, SL2: {}, SW1: {}, SW2: {} },
    Subaru: { Impreza: {}, Justy: {}, Legacy: {}, SVX: {} },
    Suzuki: { Esteem: {}, Samurai: {}, Sidekick: {}, Swift: {} },
    Toyota: {
      "4Runner": {},
      Avalon: {},
      Camry: {},
      Celica: {},
      Corolla: {},
      "Land Cruiser": {},
      MR2: {},
      Paseo: {},
      Pickup: {},
      Previa: {},
      Supra: {},
      T100: {},
      Tacoma: {},
      Tercel: {},
    },
    Volkswagen: {
      Cabrio: {},
      Corrado: {},
      EuroVan: {},
      Golf: {},
      Jetta: {},
      Passat: {},
    },
    Volvo: { "850": {}, "940": {}, "960": {} },
  },
  "1996": {
    "AM General": { Hummer: {} },
    Acura: { Integra: {}, NSX: {}, RL: {}, SLX: {}, TL: {} },
    Audi: {
      A4: {},
      "A4 Quattro": {},
      A6: {},
      "A6 Quattro": {},
      Cabriolet: {},
      S6: {},
    },
    BMW: {
      "318i": {},
      "318is": {},
      "318ti": {},
      "328i": {},
      "328is": {},
      "740iL": {},
      "750iL": {},
      "840Ci": {},
      "850Ci": {},
      M3: {},
      Z3: {},
    },
    Bentley: { Azure: {}, Brooklands: {}, Continental: {}, "Turbo R": {} },
    Buick: {
      Century: {},
      "Commercial Chassis": {},
      LeSabre: {},
      "Park Avenue": {},
      Regal: {},
      Riviera: {},
      Roadmaster: {},
      Skylark: {},
    },
    Cadillac: {
      "Commercial Chassis": {},
      DeVille: {},
      Eldorado: {},
      Fleetwood: {},
      Seville: {},
    },
    Chevrolet: {
      Astro: {},
      Beretta: {},
      Blazer: {},
      C1500: {},
      "C1500 Suburban": {},
      C2500: {},
      "C2500 Suburban": {},
      C3500: {},
      Camaro: {},
      Caprice: {},
      Cavalier: {},
      Corsica: {},
      Corvette: {},
      "Express 1500": {},
      "Express 2500": {},
      "Express 3500": {},
      G30: {},
      Impala: {},
      K1500: {},
      "K1500 Suburban": {},
      K2500: {},
      "K2500 Suburban": {},
      K3500: {},
      Lumina: {},
      "Lumina APV": {},
      "Monte Carlo": {},
      P30: {},
      S10: {},
      Tahoe: {},
    },
    Chrysler: {
      Cirrus: {},
      Concorde: {},
      Intrepid: {},
      LHS: {},
      "New Yorker": {},
      Sebring: {},
      "Town & Country": {},
    },
    Dodge: {
      Avenger: {},
      B1500: {},
      B2500: {},
      B3500: {},
      Caravan: {},
      Dakota: {},
      "Grand Caravan": {},
      Intrepid: {},
      Neon: {},
      "Ram 1500": {},
      "Ram 2500": {},
      "Ram 3500": {},
      Stealth: {},
      Stratus: {},
      Viper: {},
    },
    Eagle: { Summit: {}, Talon: {}, Vision: {} },
    Ferrari: {
      "456 GT": {},
      "F355 Berlinetta": {},
      "F355 GTS": {},
      "F355 Spider": {},
      F50: {},
    },
    Ford: {
      Aerostar: {},
      Aspire: {},
      Bronco: {},
      Contour: {},
      "Crown Victoria": {},
      "E-150 Econoline": {},
      "E-150 Econoline Club Wagon": {},
      "E-250 Econoline": {},
      "E-350 Econoline": {},
      "E-350 Econoline Club Wagon": {},
      Escort: {},
      Explorer: {},
      "F-150": {},
      "F-250": {},
      "F-350": {},
      Mustang: {},
      Probe: {},
      Ranger: {},
      Taurus: {},
      Thunderbird: {},
      Windstar: {},
    },
    GMC: {
      C1500: {},
      "C1500 Suburban": {},
      C2500: {},
      "C2500 Suburban": {},
      C3500: {},
      G3500: {},
      Jimmy: {},
      K1500: {},
      "K1500 Suburban": {},
      K2500: {},
      "K2500 Suburban": {},
      K3500: {},
      P3500: {},
      Safari: {},
      "Savana 1500": {},
      "Savana 2500": {},
      "Savana 3500": {},
      Sonoma: {},
      Yukon: {},
    },
    Geo: { Metro: {}, Prizm: {}, Tracker: {} },
    Honda: {
      Accord: {},
      Civic: {},
      "Civic del Sol": {},
      Odyssey: {},
      Passport: {},
      Prelude: {},
    },
    Hyundai: { Accent: {}, Elantra: {}, Sonata: {} },
    Infiniti: { G20: {}, I30: {}, J30: {}, Q45: {} },
    Isuzu: { Hombre: {}, Oasis: {}, Rodeo: {}, Trooper: {} },
    Jaguar: { "Vanden Plas": {}, XJ12: {}, XJ6: {}, XJR: {}, XJS: {} },
    Jeep: { Cherokee: {}, "Grand Cherokee": {} },
    Kia: { Sephia: {}, Sportage: {} },
    Lamborghini: { Diablo: {} },
    "Land Rover": { Discovery: {}, "Range Rover": {} },
    Lexus: { ES300: {}, GS300: {}, LS400: {}, LX450: {}, SC300: {}, SC400: {} },
    Lincoln: { Continental: {}, "Mark VIII": {}, "Town Car": {} },
    Mazda: {
      "626": {},
      B2300: {},
      B3000: {},
      B4000: {},
      MPV: {},
      "MX-3": {},
      "MX-6": {},
      Miata: {},
      Millenia: {},
      Protege: {},
    },
    "Mercedes-Benz": {
      C220: {},
      C280: {},
      "C36 AMG": {},
      E300: {},
      E320: {},
      S320: {},
      S420: {},
      S500: {},
      S600: {},
      SL320: {},
      SL500: {},
      SL600: {},
    },
    Mercury: {
      Cougar: {},
      "Grand Marquis": {},
      Mystique: {},
      Sable: {},
      Tracer: {},
      Villager: {},
    },
    Mitsubishi: {
      "3000GT": {},
      Diamante: {},
      Eclipse: {},
      Galant: {},
      "Mighty Max": {},
      Mirage: {},
      Montero: {},
    },
    Nissan: {
      "200SX": {},
      "240SX": {},
      "300ZX": {},
      Altima: {},
      Maxima: {},
      Pathfinder: {},
      Pickup: {},
      Quest: {},
      Sentra: {},
    },
    Oldsmobile: {
      "88": {},
      "98": {},
      Achieva: {},
      Aurora: {},
      Bravada: {},
      "Cutlass Ciera": {},
      "Cutlass Supreme": {},
      LSS: {},
      Silhouette: {},
    },
    Plymouth: { Breeze: {}, "Grand Voyager": {}, Neon: {}, Voyager: {} },
    Pontiac: {
      Bonneville: {},
      Firebird: {},
      Firefly: {},
      "Grand Am": {},
      "Grand Prix": {},
      Sunfire: {},
      Sunrunner: {},
      "Trans Sport": {},
    },
    Porsche: { "911": {} },
    "Rolls-Royce": { "Silver Dawn": {}, "Silver Spur": {} },
    Saab: { "900": {}, "9000": {} },
    Saturn: { SC1: {}, SC2: {}, SL: {}, SL1: {}, SL2: {}, SW1: {}, SW2: {} },
    Subaru: { Impreza: {}, Legacy: {}, SVX: {} },
    Suzuki: { Esteem: {}, Sidekick: {}, Swift: {}, "X-90": {} },
    Toyota: {
      "4Runner": {},
      Avalon: {},
      Camry: {},
      Celica: {},
      Corolla: {},
      "Land Cruiser": {},
      Paseo: {},
      Previa: {},
      RAV4: {},
      Supra: {},
      T100: {},
      Tacoma: {},
      Tercel: {},
    },
    Volkswagen: { Cabrio: {}, EuroVan: {}, Golf: {}, Jetta: {}, Passat: {} },
    Volvo: { "850": {}, "960": {} },
  },
  "1997": {
    "AM General": { Hummer: {} },
    Acura: { CL: {}, EL: {}, Integra: {}, NSX: {}, RL: {}, SLX: {}, TL: {} },
    "Aston Martin": { DB7: {} },
    Audi: {
      A4: {},
      "A4 Quattro": {},
      A6: {},
      "A6 Quattro": {},
      A8: {},
      "A8 Quattro": {},
      Cabriolet: {},
      S6: {},
    },
    BMW: {
      "318i": {},
      "318is": {},
      "318ti": {},
      "328i": {},
      "328is": {},
      "528i": {},
      "540i": {},
      "740i": {},
      "740iL": {},
      "750iL": {},
      "840Ci": {},
      "850Ci": {},
      M3: {},
      Z3: {},
    },
    Bentley: { Azure: {}, Brooklands: {}, Continental: {}, "Turbo R": {} },
    Buick: {
      Century: {},
      LeSabre: {},
      "Park Avenue": {},
      Regal: {},
      Riviera: {},
      Skylark: {},
    },
    Cadillac: { Catera: {}, DeVille: {}, Eldorado: {}, Seville: {} },
    Chevrolet: {
      Astro: {},
      Blazer: {},
      C1500: {},
      "C1500 Suburban": {},
      C2500: {},
      "C2500 Suburban": {},
      C3500: {},
      Camaro: {},
      Cavalier: {},
      Corvette: {},
      "Express 1500": {},
      "Express 2500": {},
      "Express 3500": {},
      K1500: {},
      "K1500 Suburban": {},
      K2500: {},
      "K2500 Suburban": {},
      K3500: {},
      Lumina: {},
      Malibu: {},
      "Monte Carlo": {},
      P30: {},
      S10: {},
      Tahoe: {},
      Venture: {},
    },
    Chrysler: {
      Cirrus: {},
      Concorde: {},
      Intrepid: {},
      LHS: {},
      Sebring: {},
      "Town & Country": {},
    },
    Dodge: {
      Avenger: {},
      B1500: {},
      B2500: {},
      B3500: {},
      Caravan: {},
      Dakota: {},
      "Grand Caravan": {},
      Intrepid: {},
      Neon: {},
      "Ram 1500": {},
      "Ram 2500": {},
      "Ram 3500": {},
      Stratus: {},
      Viper: {},
    },
    Eagle: { Talon: {}, Vision: {} },
    Ferrari: {
      "456 GT": {},
      "456 GTA": {},
      "550 Maranello": {},
      "F355 GTS": {},
      "F355 Spider": {},
      F50: {},
    },
    Ford: {
      Aerostar: {},
      Aspire: {},
      Contour: {},
      "Crown Victoria": {},
      "E-150 Econoline": {},
      "E-150 Econoline Club Wagon": {},
      "E-250 Econoline": {},
      "E-350 Econoline": {},
      "E-350 Econoline Club Wagon": {},
      Escort: {},
      Expedition: {},
      Explorer: {},
      "F-150": {},
      "F-250": {},
      "F-250 HD": {},
      "F-350": {},
      Mustang: {},
      Probe: {},
      Ranger: {},
      Taurus: {},
      Thunderbird: {},
      Windstar: {},
    },
    GMC: {
      C1500: {},
      "C1500 Suburban": {},
      C2500: {},
      "C2500 Suburban": {},
      C3500: {},
      Jimmy: {},
      K1500: {},
      "K1500 Suburban": {},
      K2500: {},
      "K2500 Suburban": {},
      K3500: {},
      P3500: {},
      Safari: {},
      "Savana 1500": {},
      "Savana 2500": {},
      "Savana 3500": {},
      Sonoma: {},
      Yukon: {},
    },
    Geo: { Metro: {}, Prizm: {}, Tracker: {} },
    Honda: {
      Accord: {},
      "CR-V": {},
      Civic: {},
      "Civic del Sol": {},
      Odyssey: {},
      Passport: {},
      Prelude: {},
    },
    Hyundai: { Accent: {}, Elantra: {}, Sonata: {}, Tiburon: {} },
    Infiniti: { I30: {}, J30: {}, Q45: {}, QX4: {} },
    Isuzu: { Hombre: {}, Oasis: {}, Rodeo: {}, Trooper: {} },
    Jaguar: { "Vanden Plas": {}, XJ6: {}, XJR: {}, XK8: {} },
    Jeep: { Cherokee: {}, "Grand Cherokee": {}, TJ: {}, Wrangler: {} },
    Kia: { Sephia: {}, Sportage: {} },
    Lamborghini: { Diablo: {} },
    "Land Rover": { "Defender 90": {}, Discovery: {}, "Range Rover": {} },
    Lexus: { ES300: {}, GS300: {}, LS400: {}, LX450: {}, SC300: {}, SC400: {} },
    Lincoln: { Continental: {}, "Mark VIII": {}, "Town Car": {} },
    Lotus: { Esprit: {} },
    Mazda: {
      "626": {},
      B2300: {},
      B3000: {},
      B4000: {},
      MPV: {},
      "MX-6": {},
      Miata: {},
      Millenia: {},
      Protege: {},
    },
    "Mercedes-Benz": {
      C230: {},
      C280: {},
      "C36 AMG": {},
      E300: {},
      E320: {},
      E420: {},
      S320: {},
      S420: {},
      S500: {},
      S600: {},
      SL320: {},
      SL500: {},
      SL600: {},
    },
    Mercury: {
      Cougar: {},
      "Grand Marquis": {},
      Mountaineer: {},
      Mystique: {},
      Sable: {},
      Tracer: {},
      Villager: {},
    },
    Mitsubishi: {
      "3000GT": {},
      Diamante: {},
      Eclipse: {},
      Galant: {},
      Mirage: {},
      Montero: {},
      "Montero Sport": {},
    },
    Nissan: {
      "200SX": {},
      "240SX": {},
      Altima: {},
      Maxima: {},
      Pathfinder: {},
      Pickup: {},
      Quest: {},
      Sentra: {},
    },
    Oldsmobile: {
      "88": {},
      Achieva: {},
      Aurora: {},
      Bravada: {},
      Cutlass: {},
      "Cutlass Supreme": {},
      LSS: {},
      Regency: {},
      Silhouette: {},
    },
    Plymouth: {
      Breeze: {},
      "Grand Voyager": {},
      Neon: {},
      Prowler: {},
      Voyager: {},
    },
    Pontiac: {
      Bonneville: {},
      Firebird: {},
      Firefly: {},
      "Grand Am": {},
      "Grand Prix": {},
      Sunfire: {},
      Sunrunner: {},
      "Trans Sport": {},
    },
    Porsche: { "911": {}, Boxster: {} },
    "Rolls-Royce": { "Silver Dawn": {}, "Silver Spur": {} },
    Saab: { "900": {}, "9000": {} },
    Saturn: { SC1: {}, SC2: {}, SL: {}, SL1: {}, SL2: {}, SW1: {}, SW2: {} },
    Subaru: { Impreza: {}, Legacy: {}, SVX: {} },
    Suzuki: { Esteem: {}, Sidekick: {}, Swift: {}, "X-90": {} },
    Toyota: {
      "4Runner": {},
      Avalon: {},
      Camry: {},
      Celica: {},
      Corolla: {},
      "Land Cruiser": {},
      Paseo: {},
      Previa: {},
      RAV4: {},
      Supra: {},
      T100: {},
      Tacoma: {},
      Tercel: {},
    },
    Volkswagen: { Cabrio: {}, EuroVan: {}, Golf: {}, Jetta: {}, Passat: {} },
    Volvo: { "850": {}, "960": {}, S90: {}, V90: {} },
  },
  "1998": {
    "AM General": { Hummer: {} },
    Acura: { CL: {}, EL: {}, Integra: {}, NSX: {}, RL: {}, SLX: {}, TL: {} },
    "Aston Martin": { DB7: {} },
    Audi: {
      A4: {},
      "A4 Quattro": {},
      A6: {},
      "A6 Quattro": {},
      A8: {},
      "A8 Quattro": {},
      Cabriolet: {},
    },
    BMW: {
      "318i": {},
      "318is": {},
      "318ti": {},
      "323i": {},
      "323is": {},
      "328i": {},
      "328is": {},
      "528i": {},
      "540i": {},
      "740i": {},
      "740iL": {},
      "750iL": {},
      M3: {},
      Z3: {},
    },
    Bentley: { Azure: {}, Brooklands: {}, Continental: {}, "Turbo R": {} },
    Buick: {
      Century: {},
      LeSabre: {},
      "Park Avenue": {},
      Regal: {},
      Riviera: {},
      Skylark: {},
    },
    Cadillac: { Catera: {}, DeVille: {}, Eldorado: {}, Seville: {} },
    Chevrolet: {
      Astro: {},
      Blazer: {},
      C1500: {},
      "C1500 Suburban": {},
      C2500: {},
      "C2500 Suburban": {},
      C3500: {},
      Camaro: {},
      Cavalier: {},
      Corvette: {},
      "Express 1500": {},
      "Express 2500": {},
      "Express 3500": {},
      K1500: {},
      "K1500 Suburban": {},
      K2500: {},
      "K2500 Suburban": {},
      K3500: {},
      Lumina: {},
      Malibu: {},
      Metro: {},
      "Monte Carlo": {},
      P30: {},
      Prizm: {},
      S10: {},
      Tahoe: {},
      Tracker: {},
      Venture: {},
    },
    Chrysler: {
      Cirrus: {},
      Concorde: {},
      Intrepid: {},
      Sebring: {},
      "Town & Country": {},
    },
    Dodge: {
      Avenger: {},
      B1500: {},
      B2500: {},
      B3500: {},
      Caravan: {},
      Dakota: {},
      Durango: {},
      "Grand Caravan": {},
      Intrepid: {},
      Neon: {},
      "Ram 1500": {},
      "Ram 2500": {},
      "Ram 3500": {},
      Stratus: {},
      Viper: {},
    },
    Eagle: { Talon: {} },
    Ferrari: {
      "456 GT": {},
      "456 GTA": {},
      "550 Maranello": {},
      "F355 F1": {},
      "F355 GTS": {},
      "F355 Spider": {},
    },
    Ford: {
      Contour: {},
      "Crown Victoria": {},
      "E-150 Econoline": {},
      "E-150 Econoline Club Wagon": {},
      "E-250 Econoline": {},
      "E-350 Econoline": {},
      "E-350 Econoline Club Wagon": {},
      Escort: {},
      Expedition: {},
      Explorer: {},
      "F-150": {},
      "F-250": {},
      Mustang: {},
      Ranger: {},
      Taurus: {},
      Windstar: {},
    },
    GMC: {
      C1500: {},
      "C1500 Suburban": {},
      C2500: {},
      "C2500 Suburban": {},
      C3500: {},
      Jimmy: {},
      K1500: {},
      "K1500 Suburban": {},
      K2500: {},
      "K2500 Suburban": {},
      K3500: {},
      P3500: {},
      Safari: {},
      "Savana 1500": {},
      "Savana 2500": {},
      "Savana 3500": {},
      Sonoma: {},
      Yukon: {},
    },
    Honda: {
      Accord: {},
      "CR-V": {},
      Civic: {},
      "EV Plus": {},
      Odyssey: {},
      Passport: {},
      Prelude: {},
    },
    Hyundai: { Accent: {}, Elantra: {}, Sonata: {}, Tiburon: {} },
    Infiniti: { I30: {}, Q45: {}, QX4: {} },
    Isuzu: { Amigo: {}, Hombre: {}, Oasis: {}, Rodeo: {}, Trooper: {} },
    Jaguar: { "Vanden Plas": {}, XJ8: {}, XJR: {}, XK8: {} },
    Jeep: { Cherokee: {}, "Grand Cherokee": {}, TJ: {}, Wrangler: {} },
    Kia: { Sephia: {}, Sportage: {} },
    Lamborghini: { Diablo: {} },
    "Land Rover": { Discovery: {}, "Range Rover": {} },
    Lexus: {
      ES300: {},
      GS300: {},
      GS400: {},
      LS400: {},
      LX470: {},
      SC300: {},
      SC400: {},
    },
    Lincoln: {
      Continental: {},
      "Mark VIII": {},
      Navigator: {},
      "Town Car": {},
    },
    Lotus: { Esprit: {} },
    Mazda: {
      "626": {},
      B2500: {},
      B3000: {},
      B4000: {},
      MPV: {},
      Millenia: {},
      Protege: {},
    },
    "Mercedes-Benz": {
      C230: {},
      C280: {},
      "C43 AMG": {},
      CL500: {},
      CL600: {},
      CLK320: {},
      E300: {},
      E320: {},
      E430: {},
      ML320: {},
      S320: {},
      S420: {},
      S500: {},
      S600: {},
      SL500: {},
      SL600: {},
      SLK230: {},
    },
    Mercury: {
      "Grand Marquis": {},
      Mountaineer: {},
      Mystique: {},
      Sable: {},
      Tracer: {},
      Villager: {},
    },
    Mitsubishi: {
      "3000GT": {},
      Diamante: {},
      Eclipse: {},
      Galant: {},
      Mirage: {},
      Montero: {},
      "Montero Sport": {},
    },
    Nissan: {
      "200SX": {},
      "240SX": {},
      Altima: {},
      Frontier: {},
      Maxima: {},
      Pathfinder: {},
      Quest: {},
      Sentra: {},
    },
    Oldsmobile: {
      "88": {},
      Achieva: {},
      Aurora: {},
      Bravada: {},
      Cutlass: {},
      Intrigue: {},
      LSS: {},
      Regency: {},
      Silhouette: {},
    },
    Plymouth: { Breeze: {}, "Grand Voyager": {}, Neon: {}, Voyager: {} },
    Pontiac: {
      Bonneville: {},
      Firebird: {},
      Firefly: {},
      "Grand Am": {},
      "Grand Prix": {},
      Sunfire: {},
      "Trans Sport": {},
    },
    Porsche: { "911": {}, Boxster: {} },
    "Rolls-Royce": { "Silver Spur": {} },
    Saab: { "900": {}, "9000": {} },
    Saturn: { SC1: {}, SC2: {}, SL: {}, SL1: {}, SL2: {}, SW1: {}, SW2: {} },
    Subaru: { Forester: {}, Impreza: {}, Legacy: {} },
    Suzuki: { Esteem: {}, Sidekick: {}, Swift: {}, "X-90": {} },
    Toyota: {
      "4Runner": {},
      Avalon: {},
      Camry: {},
      Celica: {},
      Corolla: {},
      "Land Cruiser": {},
      Paseo: {},
      RAV4: {},
      Sienna: {},
      Supra: {},
      T100: {},
      Tacoma: {},
      Tercel: {},
    },
    Volkswagen: { Beetle: {}, Cabrio: {}, Golf: {}, Jetta: {}, Passat: {} },
    Volvo: { C70: {}, S70: {}, S90: {}, V70: {}, V90: {} },
  },
  "1999": {
    "AM General": { Hummer: {} },
    Acura: { CL: {}, EL: {}, Integra: {}, NSX: {}, RL: {}, SLX: {}, TL: {} },
    Audi: {
      A4: {},
      "A4 Quattro": {},
      A6: {},
      "A6 Quattro": {},
      A8: {},
      "A8 Quattro": {},
    },
    BMW: {
      "318i": {},
      "318is": {},
      "318ti": {},
      "323i": {},
      "323is": {},
      "328i": {},
      "328is": {},
      "528i": {},
      "540i": {},
      "740i": {},
      "740iL": {},
      "750iL": {},
      M3: {},
      Z3: {},
    },
    Bentley: { Arnage: {}, Azure: {}, Continental: {} },
    Buick: {
      Century: {},
      LeSabre: {},
      "Park Avenue": {},
      Regal: {},
      Riviera: {},
    },
    Cadillac: {
      Catera: {},
      DeVille: {},
      Eldorado: {},
      Escalade: {},
      Seville: {},
    },
    Chevrolet: {
      Astro: {},
      Blazer: {},
      C1500: {},
      "C1500 Suburban": {},
      C2500: {},
      "C2500 Suburban": {},
      C3500: {},
      Camaro: {},
      Cavalier: {},
      Corvette: {},
      "Express 1500": {},
      "Express 2500": {},
      "Express 3500": {},
      K1500: {},
      "K1500 Suburban": {},
      K2500: {},
      "K2500 Suburban": {},
      K3500: {},
      Lumina: {},
      Malibu: {},
      Metro: {},
      "Monte Carlo": {},
      P30: {},
      Prizm: {},
      S10: {},
      "Silverado 1500": {},
      "Silverado 2500": {},
      Tahoe: {},
      Tracker: {},
      Venture: {},
    },
    Chrysler: {
      "300M": {},
      Cirrus: {},
      Concorde: {},
      Intrepid: {},
      LHS: {},
      Sebring: {},
      "Town & Country": {},
    },
    Daewoo: { Lanos: {}, Leganza: {}, Nubira: {} },
    Dodge: {
      Avenger: {},
      Caravan: {},
      Dakota: {},
      Durango: {},
      "Grand Caravan": {},
      Intrepid: {},
      Neon: {},
      "Ram 1500": {},
      "Ram 1500 Van": {},
      "Ram 2500": {},
      "Ram 2500 Van": {},
      "Ram 3500": {},
      "Ram 3500 Van": {},
      Stratus: {},
      Viper: {},
    },
    Ferrari: { "456 M": {}, "550 Maranello": {}, F355: {}, "F355 Spider": {} },
    Ford: {
      Contour: {},
      "Crown Victoria": {},
      "E-150 Econoline": {},
      "E-150 Econoline Club Wagon": {},
      "E-250 Econoline": {},
      "E-350 Econoline Club Wagon": {},
      "E-350 Super Duty": {},
      Escort: {},
      Expedition: {},
      Explorer: {},
      "F-150": {},
      "F-250": {},
      "F-250 Super Duty": {},
      "F-350 Super Duty": {},
      Mustang: {},
      Ranger: {},
      Taurus: {},
      Windstar: {},
    },
    GMC: {
      C1500: {},
      "C1500 Suburban": {},
      C2500: {},
      "C2500 Suburban": {},
      C3500: {},
      Jimmy: {},
      K1500: {},
      "K1500 Suburban": {},
      K2500: {},
      "K2500 Suburban": {},
      K3500: {},
      P3500: {},
      Safari: {},
      "Savana 1500": {},
      "Savana 2500": {},
      "Savana 3500": {},
      "Sierra 1500": {},
      "Sierra 2500": {},
      Sonoma: {},
      Yukon: {},
    },
    Honda: {
      Accord: {},
      "CR-V": {},
      Civic: {},
      "EV Plus": {},
      Odyssey: {},
      Passport: {},
      Prelude: {},
    },
    Hyundai: { Accent: {}, Elantra: {}, Sonata: {}, Tiburon: {} },
    Infiniti: { G20: {}, I30: {}, Q45: {}, QX4: {} },
    Isuzu: {
      Amigo: {},
      Hombre: {},
      Oasis: {},
      Rodeo: {},
      Trooper: {},
      VehiCROSS: {},
    },
    Jaguar: { "Vanden Plas": {}, XJ8: {}, XJR: {}, XK8: {} },
    Jeep: { Cherokee: {}, "Grand Cherokee": {}, TJ: {}, Wrangler: {} },
    Kia: { Sephia: {}, Sportage: {} },
    Lamborghini: { Diablo: {} },
    "Land Rover": { Discovery: {}, "Range Rover": {} },
    Lexus: {
      ES300: {},
      GS300: {},
      GS400: {},
      LS400: {},
      LX470: {},
      RX300: {},
      SC300: {},
      SC400: {},
    },
    Lincoln: { Continental: {}, Navigator: {}, "Town Car": {} },
    Lotus: { Esprit: {} },
    Mazda: {
      "626": {},
      B2500: {},
      B3000: {},
      B4000: {},
      Miata: {},
      Millenia: {},
      Protege: {},
    },
    "Mercedes-Benz": {
      C230: {},
      C280: {},
      "C43 AMG": {},
      CL500: {},
      CL600: {},
      CLK320: {},
      CLK430: {},
      E300: {},
      E320: {},
      E430: {},
      "E55 AMG": {},
      ML320: {},
      ML430: {},
      S320: {},
      S420: {},
      S500: {},
      S600: {},
      SL500: {},
      SL600: {},
      SLK230: {},
    },
    Mercury: {
      Cougar: {},
      "Grand Marquis": {},
      Mountaineer: {},
      Mystique: {},
      Sable: {},
      Tracer: {},
      Villager: {},
    },
    Mitsubishi: {
      "3000GT": {},
      Diamante: {},
      Eclipse: {},
      Galant: {},
      Mirage: {},
      Montero: {},
      "Montero Sport": {},
    },
    Nissan: {
      Altima: {},
      "Altra EV": {},
      Frontier: {},
      Maxima: {},
      Pathfinder: {},
      Quest: {},
      Sentra: {},
    },
    Oldsmobile: {
      "88": {},
      Alero: {},
      Aurora: {},
      Bravada: {},
      Cutlass: {},
      Intrigue: {},
      LSS: {},
      Silhouette: {},
    },
    Plymouth: {
      Breeze: {},
      "Grand Voyager": {},
      Neon: {},
      Prowler: {},
      Voyager: {},
    },
    Pontiac: {
      Bonneville: {},
      Firebird: {},
      Firefly: {},
      "Grand Am": {},
      "Grand Prix": {},
      Montana: {},
      Sunfire: {},
      "Trans Sport": {},
    },
    Porsche: { "911": {}, Boxster: {} },
    "Rolls-Royce": { "Silver Seraph": {} },
    Saab: { "9-3": {}, "9-5": {} },
    Saturn: { SC1: {}, SC2: {}, SL: {}, SL1: {}, SL2: {}, SW1: {}, SW2: {} },
    Subaru: { Forester: {}, Impreza: {}, Legacy: {} },
    Suzuki: { Esteem: {}, "Grand Vitara": {}, Swift: {}, Vitara: {} },
    Toyota: {
      "4Runner": {},
      Avalon: {},
      Camry: {},
      Celica: {},
      Corolla: {},
      "Land Cruiser": {},
      Paseo: {},
      RAV4: {},
      Sienna: {},
      Solara: {},
      Tacoma: {},
      Tercel: {},
    },
    Volkswagen: {
      Beetle: {},
      Cabrio: {},
      EuroVan: {},
      Golf: {},
      Jetta: {},
      Passat: {},
    },
    Volvo: { C70: {}, S70: {}, S80: {}, V70: {} },
  },
  "2000": {
    "AM General": { Hummer: {} },
    Acura: { EL: {}, Integra: {}, NSX: {}, RL: {}, TL: {} },
    "Aston Martin": { DB7: {} },
    Audi: {
      A4: {},
      "A4 Quattro": {},
      A6: {},
      "A6 Quattro": {},
      "A8 Quattro": {},
      S4: {},
      TT: {},
      "TT Quattro": {},
    },
    BMW: {
      "323Ci": {},
      "323i": {},
      "328Ci": {},
      "328i": {},
      "528i": {},
      "540i": {},
      "740i": {},
      "740iL": {},
      "750iL": {},
      M5: {},
      X5: {},
      Z3: {},
      Z8: {},
    },
    Bentley: { Arnage: {}, Azure: {}, Continental: {} },
    Buick: { Century: {}, LeSabre: {}, "Park Avenue": {}, Regal: {} },
    Cadillac: {
      Catera: {},
      DeVille: {},
      Eldorado: {},
      Escalade: {},
      Seville: {},
    },
    Chevrolet: {
      Astro: {},
      Blazer: {},
      C2500: {},
      C3500: {},
      Camaro: {},
      Cavalier: {},
      Corvette: {},
      "Express 1500": {},
      "Express 2500": {},
      "Express 3500": {},
      Impala: {},
      K2500: {},
      K3500: {},
      Lumina: {},
      Malibu: {},
      Metro: {},
      "Monte Carlo": {},
      Prizm: {},
      S10: {},
      "Silverado 1500": {},
      "Silverado 2500": {},
      "Suburban 1500": {},
      "Suburban 2500": {},
      Tahoe: {},
      Tracker: {},
      Venture: {},
    },
    Chrysler: {
      "300M": {},
      Cirrus: {},
      Concorde: {},
      "Grand Voyager": {},
      Intrepid: {},
      LHS: {},
      Neon: {},
      Sebring: {},
      "Town & Country": {},
      Voyager: {},
    },
    Daewoo: { Lanos: {}, Leganza: {}, Nubira: {} },
    Dodge: {
      Avenger: {},
      Caravan: {},
      Dakota: {},
      Durango: {},
      "Grand Caravan": {},
      Intrepid: {},
      Neon: {},
      "Ram 1500": {},
      "Ram 1500 Van": {},
      "Ram 2500": {},
      "Ram 2500 Van": {},
      "Ram 3500": {},
      "Ram 3500 Van": {},
      Stratus: {},
      Viper: {},
    },
    Ferrari: { "360": {}, "456 M": {}, "550 Maranello": {} },
    Ford: {
      Contour: {},
      "Crown Victoria": {},
      "E-150 Econoline": {},
      "E-150 Econoline Club Wagon": {},
      "E-250 Econoline": {},
      "E-350 Econoline Club Wagon": {},
      "E-350 Super Duty": {},
      Escort: {},
      Excursion: {},
      Expedition: {},
      Explorer: {},
      "F-150": {},
      "F-250 Super Duty": {},
      "F-350 Super Duty": {},
      Focus: {},
      Mustang: {},
      Ranger: {},
      Taurus: {},
      Windstar: {},
    },
    GMC: {
      C2500: {},
      C3500: {},
      Jimmy: {},
      K2500: {},
      K3500: {},
      Safari: {},
      "Savana 1500": {},
      "Savana 2500": {},
      "Savana 3500": {},
      "Sierra 1500": {},
      "Sierra 2500": {},
      Sonoma: {},
      Yukon: {},
      "Yukon XL 1500": {},
      "Yukon XL 2500": {},
    },
    Honda: {
      Accord: {},
      "CR-V": {},
      Civic: {},
      Insight: {},
      Odyssey: {},
      Passport: {},
      Prelude: {},
      S2000: {},
    },
    Hyundai: { Accent: {}, Elantra: {}, Sonata: {}, Tiburon: {} },
    Infiniti: { G20: {}, I30: {}, Q45: {}, QX4: {} },
    Isuzu: { Amigo: {}, Hombre: {}, Rodeo: {}, Trooper: {}, VehiCROSS: {} },
    Jaguar: {
      "S-Type": {},
      "Vanden Plas": {},
      XJ8: {},
      XJR: {},
      XK8: {},
      XKR: {},
    },
    Jeep: { Cherokee: {}, "Grand Cherokee": {}, TJ: {}, Wrangler: {} },
    Kia: { Sephia: {}, Spectra: {}, Sportage: {} },
    Lamborghini: { Diablo: {} },
    "Land Rover": { Discovery: {}, "Range Rover": {} },
    Lexus: {
      ES300: {},
      GS300: {},
      GS400: {},
      LS400: {},
      LX470: {},
      RX300: {},
      SC300: {},
      SC400: {},
    },
    Lincoln: { Continental: {}, LS: {}, Navigator: {}, "Town Car": {} },
    Lotus: { Esprit: {} },
    Mazda: {
      "626": {},
      B2500: {},
      B3000: {},
      B4000: {},
      MPV: {},
      Miata: {},
      Millenia: {},
      Protege: {},
    },
    "Mercedes-Benz": {
      C230: {},
      C280: {},
      "C43 AMG": {},
      CL500: {},
      CLK320: {},
      CLK430: {},
      E320: {},
      E430: {},
      "E55 AMG": {},
      ML320: {},
      ML430: {},
      "ML55 AMG": {},
      S430: {},
      S500: {},
      SL500: {},
      SL600: {},
      SLK230: {},
    },
    Mercury: {
      Cougar: {},
      "Grand Marquis": {},
      Mountaineer: {},
      Mystique: {},
      Sable: {},
      Villager: {},
    },
    Mitsubishi: {
      Diamante: {},
      Eclipse: {},
      Galant: {},
      Mirage: {},
      Montero: {},
      "Montero Sport": {},
    },
    Nissan: {
      Altima: {},
      "Altra EV": {},
      Frontier: {},
      Maxima: {},
      Pathfinder: {},
      Quest: {},
      Sentra: {},
      Xterra: {},
    },
    Oldsmobile: { Alero: {}, Bravada: {}, Intrigue: {}, Silhouette: {} },
    Plymouth: {
      Breeze: {},
      "Grand Voyager": {},
      Neon: {},
      Prowler: {},
      Voyager: {},
    },
    Pontiac: {
      Bonneville: {},
      Firebird: {},
      Firefly: {},
      "Grand Am": {},
      "Grand Prix": {},
      Montana: {},
      Sunfire: {},
    },
    Porsche: { "911": {}, Boxster: {} },
    "Rolls-Royce": { Corniche: {}, "Silver Seraph": {} },
    Saab: { "9-3": {}, "9-5": {} },
    Saturn: {
      LS: {},
      LS1: {},
      LS2: {},
      LW1: {},
      LW2: {},
      SC1: {},
      SC2: {},
      SL: {},
      SL1: {},
      SL2: {},
      SW2: {},
    },
    Subaru: { Forester: {}, Impreza: {}, Legacy: {}, Outback: {} },
    Suzuki: { Esteem: {}, "Grand Vitara": {}, Swift: {}, Vitara: {} },
    Toyota: {
      "4Runner": {},
      Avalon: {},
      Camry: {},
      Celica: {},
      Corolla: {},
      Echo: {},
      "Land Cruiser": {},
      "MR2 Spyder": {},
      RAV4: {},
      Sienna: {},
      Solara: {},
      Tacoma: {},
      Tundra: {},
    },
    Volkswagen: {
      Beetle: {},
      Cabrio: {},
      EuroVan: {},
      Golf: {},
      Jetta: {},
      Passat: {},
    },
    Volvo: { C70: {}, S40: {}, S70: {}, S80: {}, V40: {}, V70: {} },
    Workhorse: {
      "FasTrack FT1260": {},
      "FasTrack FT1460": {},
      "FasTrack FT1600": {},
      "FasTrack FT1800": {},
      P30: {},
    },
  },
  "2001": {
    "AM General": { Hummer: {} },
    Acura: { CL: {}, EL: {}, Integra: {}, MDX: {}, NSX: {}, RL: {}, TL: {} },
    "Aston Martin": { DB7: {}, Vanquish: {} },
    Audi: {
      A4: {},
      "A4 Quattro": {},
      A6: {},
      "A6 Quattro": {},
      "A8 Quattro": {},
      "Allroad Quattro": {},
      S4: {},
      S8: {},
      TT: {},
      "TT Quattro": {},
    },
    BMW: {
      "320i": {},
      "325Ci": {},
      "325i": {},
      "325xi": {},
      "330Ci": {},
      "330i": {},
      "330xi": {},
      "525i": {},
      "530i": {},
      "540i": {},
      "740i": {},
      "740iL": {},
      "750iL": {},
      M3: {},
      M5: {},
      X5: {},
      Z3: {},
      Z8: {},
    },
    Bentley: { Arnage: {}, Azure: {}, Continental: {} },
    Buick: { Century: {}, LeSabre: {}, "Park Avenue": {}, Regal: {} },
    Cadillac: { Catera: {}, DeVille: {}, Eldorado: {}, Seville: {} },
    Chevrolet: {
      Astro: {},
      Blazer: {},
      Camaro: {},
      Cavalier: {},
      Corvette: {},
      "Express 1500": {},
      "Express 2500": {},
      "Express 3500": {},
      Impala: {},
      Lumina: {},
      Malibu: {},
      Metro: {},
      "Monte Carlo": {},
      Prizm: {},
      S10: {},
      "Silverado 1500": {},
      "Silverado 1500 HD": {},
      "Silverado 2500": {},
      "Silverado 2500 HD": {},
      "Silverado 3500": {},
      "Suburban 1500": {},
      "Suburban 2500": {},
      Tahoe: {},
      Tracker: {},
      Venture: {},
    },
    Chrysler: {
      "300M": {},
      Concorde: {},
      Intrepid: {},
      LHS: {},
      Neon: {},
      "PT Cruiser": {},
      Prowler: {},
      Sebring: {},
      "Town & Country": {},
      Voyager: {},
    },
    Daewoo: { Lanos: {}, Leganza: {}, Nubira: {} },
    Dodge: {
      Caravan: {},
      Dakota: {},
      Durango: {},
      "Grand Caravan": {},
      Intrepid: {},
      Neon: {},
      "Ram 1500": {},
      "Ram 1500 Van": {},
      "Ram 2500": {},
      "Ram 2500 Van": {},
      "Ram 3500": {},
      "Ram 3500 Van": {},
      Stratus: {},
      Viper: {},
    },
    Ferrari: { "360": {}, "456 M": {}, "550 Maranello": {} },
    Ford: {
      "Crown Victoria": {},
      "E-150 Econoline": {},
      "E-150 Econoline Club Wagon": {},
      "E-250 Econoline": {},
      "E-350 Econoline Club Wagon": {},
      "E-350 Super Duty": {},
      Escape: {},
      Escort: {},
      Excursion: {},
      Expedition: {},
      Explorer: {},
      "Explorer Sport": {},
      "Explorer Sport Trac": {},
      "F-150": {},
      "F-250 Super Duty": {},
      "F-350 Super Duty": {},
      Focus: {},
      Mustang: {},
      Ranger: {},
      Taurus: {},
      Windstar: {},
    },
    GMC: {
      Jimmy: {},
      Safari: {},
      "Savana 1500": {},
      "Savana 2500": {},
      "Savana 3500": {},
      "Sierra 1500": {},
      "Sierra 1500 HD": {},
      "Sierra 2500": {},
      "Sierra 2500 HD": {},
      "Sierra 3500": {},
      Sonoma: {},
      Yukon: {},
      "Yukon XL 1500": {},
      "Yukon XL 2500": {},
    },
    Honda: {
      Accord: {},
      "CR-V": {},
      Civic: {},
      Insight: {},
      Odyssey: {},
      Passport: {},
      Prelude: {},
      S2000: {},
    },
    Hyundai: {
      Accent: {},
      Elantra: {},
      "Santa Fe": {},
      Sonata: {},
      Tiburon: {},
      XG300: {},
    },
    Infiniti: { G20: {}, I30: {}, Q45: {}, QX4: {} },
    Isuzu: { Rodeo: {}, "Rodeo Sport": {}, Trooper: {}, VehiCROSS: {} },
    Jaguar: {
      "S-Type": {},
      "Vanden Plas": {},
      XJ8: {},
      XJR: {},
      XK8: {},
      XKR: {},
    },
    Jeep: { Cherokee: {}, "Grand Cherokee": {}, TJ: {}, Wrangler: {} },
    Kia: {
      Magentis: {},
      Optima: {},
      Rio: {},
      Sephia: {},
      Spectra: {},
      Sportage: {},
    },
    Lamborghini: { Diablo: {}, Murcielago: {} },
    "Land Rover": { Discovery: {}, "Range Rover": {} },
    Lexus: {
      ES300: {},
      GS300: {},
      GS430: {},
      IS300: {},
      LS430: {},
      LX470: {},
      RX300: {},
    },
    Lincoln: { Continental: {}, LS: {}, Navigator: {}, "Town Car": {} },
    Lotus: { Esprit: {} },
    Mazda: {
      "626": {},
      B2300: {},
      B2500: {},
      B3000: {},
      B4000: {},
      MPV: {},
      Miata: {},
      Millenia: {},
      Protege: {},
      Tribute: {},
    },
    "Mercedes-Benz": {
      C240: {},
      C320: {},
      CL500: {},
      "CL55 AMG": {},
      CL600: {},
      CLK320: {},
      CLK430: {},
      "CLK55 AMG": {},
      E320: {},
      E430: {},
      "E55 AMG": {},
      ML320: {},
      ML430: {},
      "ML55 AMG": {},
      S430: {},
      S500: {},
      "S55 AMG": {},
      S600: {},
      SL500: {},
      SL600: {},
      SLK230: {},
      SLK320: {},
    },
    Mercury: {
      Cougar: {},
      "Grand Marquis": {},
      Mountaineer: {},
      Sable: {},
      Villager: {},
    },
    Mitsubishi: {
      Diamante: {},
      Eclipse: {},
      Galant: {},
      Mirage: {},
      Montero: {},
      "Montero Sport": {},
    },
    Nissan: {
      Altima: {},
      "Altra EV": {},
      Frontier: {},
      Maxima: {},
      Pathfinder: {},
      Quest: {},
      Sentra: {},
      Xterra: {},
    },
    Oldsmobile: {
      Alero: {},
      Aurora: {},
      Bravada: {},
      Intrigue: {},
      Silhouette: {},
    },
    Plymouth: { Neon: {}, Prowler: {} },
    Pontiac: {
      Aztek: {},
      Bonneville: {},
      Firebird: {},
      "Grand Am": {},
      "Grand Prix": {},
      Montana: {},
      Sunfire: {},
    },
    Porsche: { "911": {}, Boxster: {} },
    "Rolls-Royce": { Corniche: {}, "Silver Seraph": {} },
    Saab: { "9-3": {}, "9-5": {} },
    Saturn: {
      L100: {},
      L200: {},
      L300: {},
      LW200: {},
      LW300: {},
      SC1: {},
      SC2: {},
      SL: {},
      SL1: {},
      SL2: {},
      SW2: {},
    },
    Subaru: { Forester: {}, Impreza: {}, Legacy: {}, Outback: {} },
    Suzuki: { Esteem: {}, "Grand Vitara": {}, Swift: {}, Vitara: {} },
    Toyota: {
      "4Runner": {},
      Avalon: {},
      Camry: {},
      Celica: {},
      Corolla: {},
      Echo: {},
      Highlander: {},
      "Land Cruiser": {},
      "MR2 Spyder": {},
      Prius: {},
      RAV4: {},
      Sequoia: {},
      Sienna: {},
      Solara: {},
      Tacoma: {},
      Tundra: {},
    },
    Volkswagen: {
      Beetle: {},
      Cabrio: {},
      EuroVan: {},
      Golf: {},
      Jetta: {},
      Passat: {},
    },
    Volvo: { C70: {}, S40: {}, S60: {}, S80: {}, V40: {}, V70: {} },
    Workhorse: {
      "FasTrack FT1260": {},
      "FasTrack FT1460": {},
      "FasTrack FT1600": {},
      "FasTrack FT1800": {},
      P30: {},
    },
  },
  "2002": {
    Acura: { CL: {}, EL: {}, MDX: {}, NSX: {}, RL: {}, RSX: {}, TL: {} },
    "Aston Martin": { DB7: {}, Vanquish: {} },
    Audi: {
      A4: {},
      "A4 Quattro": {},
      A6: {},
      "A6 Quattro": {},
      "A8 Quattro": {},
      "Allroad Quattro": {},
      S4: {},
      S6: {},
      S8: {},
      TT: {},
      "TT Quattro": {},
    },
    BMW: {
      "320i": {},
      "325Ci": {},
      "325i": {},
      "325xi": {},
      "330Ci": {},
      "330i": {},
      "330xi": {},
      "525i": {},
      "530i": {},
      "540i": {},
      "745Li": {},
      "745i": {},
      M3: {},
      M5: {},
      X5: {},
      Z3: {},
      Z8: {},
    },
    Bentley: { Arnage: {}, Azure: {}, Continental: {} },
    Buick: {
      Century: {},
      LeSabre: {},
      "Park Avenue": {},
      Regal: {},
      Rendezvous: {},
    },
    Cadillac: {
      DeVille: {},
      Eldorado: {},
      Escalade: {},
      "Escalade EXT": {},
      Seville: {},
    },
    Chevrolet: {
      Astro: {},
      "Avalanche 1500": {},
      "Avalanche 2500": {},
      Blazer: {},
      Camaro: {},
      Cavalier: {},
      Corvette: {},
      "Express 1500": {},
      "Express 2500": {},
      "Express 3500": {},
      Impala: {},
      Malibu: {},
      "Monte Carlo": {},
      Prizm: {},
      S10: {},
      "Silverado 1500": {},
      "Silverado 1500 HD": {},
      "Silverado 2500": {},
      "Silverado 2500 HD": {},
      "Silverado 3500": {},
      "Suburban 1500": {},
      "Suburban 2500": {},
      Tahoe: {},
      Tracker: {},
      Trailblazer: {},
      "Trailblazer EXT": {},
      Venture: {},
    },
    Chrysler: {
      "300M": {},
      Concorde: {},
      Intrepid: {},
      Neon: {},
      "PT Cruiser": {},
      Prowler: {},
      Sebring: {},
      "Town & Country": {},
      Voyager: {},
    },
    Daewoo: { Lanos: {}, Leganza: {}, Nubira: {} },
    Dodge: {
      Caravan: {},
      Dakota: {},
      Durango: {},
      "Grand Caravan": {},
      Intrepid: {},
      Neon: {},
      "Ram 1500": {},
      "Ram 1500 Van": {},
      "Ram 2500": {},
      "Ram 2500 Van": {},
      "Ram 3500": {},
      "Ram 3500 Van": {},
      Stratus: {},
      Viper: {},
    },
    Ferrari: {
      "360": {},
      "456 M GT": {},
      "456 M GTA": {},
      "550 Maranello": {},
      "575 M Maranello": {},
    },
    Ford: {
      "Crown Victoria": {},
      "E-150 Econoline": {},
      "E-150 Econoline Club Wagon": {},
      "E-250 Econoline": {},
      "E-350 Econoline Club Wagon": {},
      "E-350 Super Duty": {},
      Escape: {},
      Escort: {},
      Excursion: {},
      Expedition: {},
      Explorer: {},
      "Explorer Sport": {},
      "Explorer Sport Trac": {},
      "F-150": {},
      "F-250 Super Duty": {},
      "F-350 Super Duty": {},
      Focus: {},
      Mustang: {},
      Ranger: {},
      Taurus: {},
      Thunderbird: {},
      Windstar: {},
    },
    Freightliner: { "Sprinter 2500": {}, "Sprinter 3500": {} },
    GMC: {
      Envoy: {},
      "Envoy XL": {},
      Jimmy: {},
      Safari: {},
      "Savana 1500": {},
      "Savana 2500": {},
      "Savana 3500": {},
      "Sierra 1500": {},
      "Sierra 1500 HD": {},
      "Sierra 2500": {},
      "Sierra 2500 HD": {},
      "Sierra 3500": {},
      Sonoma: {},
      Yukon: {},
      "Yukon XL 1500": {},
      "Yukon XL 2500": {},
    },
    Honda: {
      Accord: {},
      "CR-V": {},
      Civic: {},
      Insight: {},
      Odyssey: {},
      Passport: {},
      S2000: {},
    },
    Hummer: { H1: {} },
    Hyundai: { Accent: {}, Elantra: {}, "Santa Fe": {}, Sonata: {}, XG350: {} },
    Infiniti: { G20: {}, I35: {}, Q45: {}, QX4: {} },
    Isuzu: { Axiom: {}, Rodeo: {}, "Rodeo Sport": {}, Trooper: {} },
    Jaguar: {
      "S-Type": {},
      "Vanden Plas": {},
      "X-Type": {},
      XJ8: {},
      XJR: {},
      XK8: {},
      XKR: {},
    },
    Jeep: { "Grand Cherokee": {}, Liberty: {}, TJ: {}, Wrangler: {} },
    Kia: {
      Magentis: {},
      Optima: {},
      Rio: {},
      Sedona: {},
      Spectra: {},
      Sportage: {},
    },
    Lamborghini: { Murcielago: {} },
    "Land Rover": { Discovery: {}, Freelander: {}, "Range Rover": {} },
    Lexus: {
      ES300: {},
      GS300: {},
      GS430: {},
      IS300: {},
      LS430: {},
      LX470: {},
      RX300: {},
      SC430: {},
    },
    Lincoln: {
      Blackwood: {},
      Continental: {},
      LS: {},
      Navigator: {},
      "Town Car": {},
    },
    Lotus: { Esprit: {} },
    Maserati: { Spyder: {} },
    Mazda: {
      "626": {},
      B2300: {},
      B3000: {},
      B4000: {},
      MPV: {},
      Miata: {},
      Millenia: {},
      Protege: {},
      Protege5: {},
      Tribute: {},
    },
    "Mercedes-Benz": {
      C230: {},
      C240: {},
      "C32 AMG": {},
      C320: {},
      CL500: {},
      "CL55 AMG": {},
      CL600: {},
      CLK320: {},
      CLK430: {},
      "CLK55 AMG": {},
      E320: {},
      E430: {},
      "E55 AMG": {},
      G500: {},
      ML320: {},
      ML500: {},
      "ML55 AMG": {},
      S430: {},
      S500: {},
      "S55 AMG": {},
      S600: {},
      SL500: {},
      SL600: {},
      SLK230: {},
      "SLK32 AMG": {},
      SLK320: {},
    },
    Mercury: {
      Cougar: {},
      "Grand Marquis": {},
      Mountaineer: {},
      Sable: {},
      Villager: {},
    },
    Mini: { Cooper: {} },
    Mitsubishi: {
      Diamante: {},
      Eclipse: {},
      Galant: {},
      Lancer: {},
      Mirage: {},
      Montero: {},
      "Montero Sport": {},
    },
    Nissan: {
      Altima: {},
      Frontier: {},
      Maxima: {},
      Pathfinder: {},
      Quest: {},
      Sentra: {},
      Xterra: {},
    },
    Oldsmobile: {
      Alero: {},
      Aurora: {},
      Bravada: {},
      Intrigue: {},
      Silhouette: {},
    },
    Pontiac: {
      Aztek: {},
      Bonneville: {},
      Firebird: {},
      "Grand Am": {},
      "Grand Prix": {},
      Montana: {},
      Sunfire: {},
    },
    Porsche: { "911": {}, Boxster: {} },
    "Rolls-Royce": { Corniche: {}, "Park Ward": {}, "Silver Seraph": {} },
    Saab: { "9-3": {}, "9-5": {} },
    Saturn: {
      L100: {},
      L200: {},
      L300: {},
      LW200: {},
      LW300: {},
      SC1: {},
      SC2: {},
      SL: {},
      SL1: {},
      SL2: {},
      Vue: {},
    },
    Subaru: { Forester: {}, Impreza: {}, Legacy: {}, Outback: {} },
    Suzuki: {
      Aerio: {},
      Esteem: {},
      "Grand Vitara": {},
      Vitara: {},
      "XL-7": {},
    },
    Toyota: {
      "4Runner": {},
      Avalon: {},
      Camry: {},
      Celica: {},
      Corolla: {},
      Echo: {},
      Highlander: {},
      "Land Cruiser": {},
      "MR2 Spyder": {},
      Prius: {},
      RAV4: {},
      Sequoia: {},
      Sienna: {},
      Solara: {},
      Tacoma: {},
      Tundra: {},
    },
    Volkswagen: {
      Beetle: {},
      Cabrio: {},
      EuroVan: {},
      Golf: {},
      Jetta: {},
      Passat: {},
    },
    Volvo: { C70: {}, S40: {}, S60: {}, S80: {}, V40: {}, V70: {} },
    Workhorse: {
      "FasTrack FT1061": {},
      "FasTrack FT1261": {},
      "FasTrack FT1460": {},
      "FasTrack FT1461": {},
      "FasTrack FT1601": {},
      "FasTrack FT1801": {},
      "FasTrack FT1802": {},
      "FasTrack FT931": {},
      P30: {},
    },
  },
  "2003": {
    Acura: { CL: {}, EL: {}, MDX: {}, NSX: {}, RL: {}, RSX: {}, TL: {} },
    "Aston Martin": { DB7: {}, Vanquish: {} },
    Audi: {
      A4: {},
      "A4 Quattro": {},
      A6: {},
      "A6 Quattro": {},
      "A8 Quattro": {},
      "Allroad Quattro": {},
      RS6: {},
      S6: {},
      S8: {},
      TT: {},
      "TT Quattro": {},
    },
    BMW: {
      "320i": {},
      "325Ci": {},
      "325i": {},
      "325xi": {},
      "330Ci": {},
      "330i": {},
      "330xi": {},
      "525i": {},
      "530i": {},
      "540i": {},
      "745Li": {},
      "745i": {},
      "760Li": {},
      M3: {},
      M5: {},
      X5: {},
      Z4: {},
      Z8: {},
    },
    Bentley: { Arnage: {}, Azure: {}, Continental: {} },
    Buick: {
      Century: {},
      LeSabre: {},
      "Park Avenue": {},
      Regal: {},
      Rendezvous: {},
    },
    Cadillac: {
      CTS: {},
      DeVille: {},
      Escalade: {},
      "Escalade ESV": {},
      "Escalade EXT": {},
      Seville: {},
    },
    Chevrolet: {
      Astro: {},
      "Avalanche 1500": {},
      "Avalanche 2500": {},
      Blazer: {},
      Cavalier: {},
      Corvette: {},
      "Express 1500": {},
      "Express 2500": {},
      "Express 3500": {},
      Impala: {},
      Malibu: {},
      "Monte Carlo": {},
      S10: {},
      SSR: {},
      "Silverado 1500": {},
      "Silverado 1500 HD": {},
      "Silverado 2500": {},
      "Silverado 2500 HD": {},
      "Silverado 3500": {},
      "Suburban 1500": {},
      "Suburban 2500": {},
      Tahoe: {},
      Tracker: {},
      Trailblazer: {},
      "Trailblazer EXT": {},
      Venture: {},
    },
    Chrysler: {
      "300M": {},
      Concorde: {},
      Intrepid: {},
      "PT Cruiser": {},
      Sebring: {},
      "Town & Country": {},
      Voyager: {},
    },
    Dodge: {
      Caravan: {},
      Dakota: {},
      Durango: {},
      "Grand Caravan": {},
      Intrepid: {},
      Neon: {},
      "Ram 1500": {},
      "Ram 1500 Van": {},
      "Ram 2500": {},
      "Ram 2500 Van": {},
      "Ram 3500": {},
      "Ram 3500 Van": {},
      "SX 2.0": {},
      "Sprinter 2500": {},
      "Sprinter 3500": {},
      Stratus: {},
      Viper: {},
    },
    Ferrari: {
      "360": {},
      "456 M GT": {},
      "456 M GTA": {},
      "575 M Maranello": {},
      Enzo: {},
    },
    Ford: {
      "Crown Victoria": {},
      "E-150": {},
      "E-150 Club Wagon": {},
      "E-250": {},
      "E-350 Club Wagon": {},
      "E-350 Super Duty": {},
      Escape: {},
      Escort: {},
      Excursion: {},
      Expedition: {},
      Explorer: {},
      "Explorer Sport": {},
      "Explorer Sport Trac": {},
      "F-150": {},
      "F-250 Super Duty": {},
      "F-350 Super Duty": {},
      Focus: {},
      Mustang: {},
      Ranger: {},
      Taurus: {},
      Thunderbird: {},
      Windstar: {},
    },
    Freightliner: { "Sprinter 2500": {}, "Sprinter 3500": {} },
    GMC: {
      Envoy: {},
      "Envoy XL": {},
      Jimmy: {},
      Safari: {},
      "Savana 1500": {},
      "Savana 2500": {},
      "Savana 3500": {},
      "Sierra 1500": {},
      "Sierra 1500 HD": {},
      "Sierra 2500": {},
      "Sierra 2500 HD": {},
      "Sierra 3500": {},
      Sonoma: {},
      Yukon: {},
      "Yukon XL 1500": {},
      "Yukon XL 2500": {},
    },
    Honda: {
      Accord: {},
      "CR-V": {},
      Civic: {},
      Element: {},
      Insight: {},
      Odyssey: {},
      Pilot: {},
      S2000: {},
    },
    Hummer: { H1: {}, H2: {} },
    Hyundai: {
      Accent: {},
      Elantra: {},
      "Santa Fe": {},
      Sonata: {},
      Tiburon: {},
      XG350: {},
    },
    Infiniti: {
      FX35: {},
      FX45: {},
      G35: {},
      I35: {},
      M45: {},
      Q45: {},
      QX4: {},
    },
    Isuzu: { Ascender: {}, Axiom: {}, Rodeo: {}, "Rodeo Sport": {} },
    Jaguar: {
      "S-Type": {},
      "Vanden Plas": {},
      "X-Type": {},
      XJ8: {},
      XJR: {},
      XK8: {},
      XKR: {},
    },
    Jeep: { "Grand Cherokee": {}, Liberty: {}, TJ: {}, Wrangler: {} },
    Kia: {
      Magentis: {},
      Optima: {},
      Rio: {},
      Sedona: {},
      Sorento: {},
      Spectra: {},
    },
    Lamborghini: { Murcielago: {} },
    "Land Rover": { Discovery: {}, Freelander: {}, "Range Rover": {} },
    Lexus: {
      ES300: {},
      GS300: {},
      GS430: {},
      GX470: {},
      IS300: {},
      LS430: {},
      LX470: {},
      RX300: {},
      SC430: {},
    },
    Lincoln: { Aviator: {}, LS: {}, Navigator: {}, "Town Car": {} },
    Lotus: { Esprit: {} },
    Maserati: { Coupe: {}, Spyder: {} },
    Mazda: {
      "6": {},
      B2300: {},
      B3000: {},
      B4000: {},
      MPV: {},
      Miata: {},
      Protege: {},
      Protege5: {},
      Tribute: {},
    },
    "Mercedes-Benz": {
      C230: {},
      C240: {},
      "C32 AMG": {},
      C320: {},
      CL500: {},
      "CL55 AMG": {},
      CL600: {},
      CLK320: {},
      CLK430: {},
      CLK500: {},
      "CLK55 AMG": {},
      E320: {},
      E500: {},
      "E55 AMG": {},
      G500: {},
      "G55 AMG": {},
      ML320: {},
      ML350: {},
      ML500: {},
      "ML55 AMG": {},
      S430: {},
      S500: {},
      "S55 AMG": {},
      S600: {},
      SL500: {},
      "SL55 AMG": {},
      SLK230: {},
      "SLK32 AMG": {},
      SLK320: {},
    },
    Mercury: { "Grand Marquis": {}, Marauder: {}, Mountaineer: {}, Sable: {} },
    Mini: { Cooper: {} },
    Mitsubishi: {
      Diamante: {},
      Eclipse: {},
      Galant: {},
      Lancer: {},
      Montero: {},
      "Montero Sport": {},
      Outlander: {},
    },
    Nissan: {
      "350Z": {},
      Altima: {},
      Frontier: {},
      Maxima: {},
      Murano: {},
      Pathfinder: {},
      Sentra: {},
      Xterra: {},
    },
    Oldsmobile: { Alero: {}, Aurora: {}, Bravada: {}, Silhouette: {} },
    Pontiac: {
      Aztek: {},
      Bonneville: {},
      "Grand Am": {},
      "Grand Prix": {},
      Montana: {},
      Sunfire: {},
      Vibe: {},
    },
    Porsche: { "911": {}, Boxster: {}, Cayenne: {} },
    Saab: { "9-3": {}, "9-5": {} },
    Saturn: { Ion: {}, L200: {}, L300: {}, LW200: {}, LW300: {}, Vue: {} },
    Subaru: { Baja: {}, Forester: {}, Impreza: {}, Legacy: {}, Outback: {} },
    Suzuki: { Aerio: {}, "Grand Vitara": {}, Vitara: {}, "XL-7": {} },
    Toyota: {
      "4Runner": {},
      Avalon: {},
      Camry: {},
      Celica: {},
      Corolla: {},
      Echo: {},
      Highlander: {},
      "Land Cruiser": {},
      "MR2 Spyder": {},
      Matrix: {},
      Prius: {},
      RAV4: {},
      Sequoia: {},
      Sienna: {},
      Solara: {},
      Tacoma: {},
      Tundra: {},
    },
    Volkswagen: { Beetle: {}, EuroVan: {}, Golf: {}, Jetta: {}, Passat: {} },
    Volvo: {
      C70: {},
      S40: {},
      S60: {},
      S80: {},
      V40: {},
      V70: {},
      XC70: {},
      XC90: {},
    },
    Workhorse: {
      "FasTrack FT1061": {},
      "FasTrack FT1261": {},
      "FasTrack FT1461": {},
      "FasTrack FT1601": {},
      "FasTrack FT1801": {},
      "FasTrack FT1802": {},
      "FasTrack FT931": {},
      P30: {},
    },
  },
  "2004": {
    Acura: { EL: {}, MDX: {}, NSX: {}, RL: {}, RSX: {}, TL: {}, TSX: {} },
    "Aston Martin": { DB7: {}, Vanquish: {} },
    Audi: {
      A4: {},
      "A4 Quattro": {},
      A6: {},
      "A6 Quattro": {},
      "A8 Quattro": {},
      "Allroad Quattro": {},
      RS6: {},
      S4: {},
      TT: {},
      "TT Quattro": {},
    },
    BMW: {
      "320i": {},
      "325Ci": {},
      "325i": {},
      "325xi": {},
      "330Ci": {},
      "330i": {},
      "330xi": {},
      "525i": {},
      "530i": {},
      "545i": {},
      "645Ci": {},
      "745Li": {},
      "745i": {},
      "760Li": {},
      "760i": {},
      M3: {},
      X3: {},
      X5: {},
      Z4: {},
    },
    Bentley: { Arnage: {}, Continental: {} },
    Buick: {
      Century: {},
      LeSabre: {},
      "Park Avenue": {},
      Rainier: {},
      Regal: {},
      Rendezvous: {},
    },
    Cadillac: {
      CTS: {},
      DeVille: {},
      Escalade: {},
      "Escalade ESV": {},
      "Escalade EXT": {},
      SRX: {},
      Seville: {},
      XLR: {},
    },
    Chevrolet: {
      Astro: {},
      "Avalanche 1500": {},
      "Avalanche 2500": {},
      Aveo: {},
      Blazer: {},
      Cavalier: {},
      Classic: {},
      Colorado: {},
      Corvette: {},
      Epica: {},
      "Express 1500": {},
      "Express 2500": {},
      "Express 3500": {},
      Impala: {},
      Malibu: {},
      "Monte Carlo": {},
      Optra: {},
      S10: {},
      SSR: {},
      "Silverado 1500": {},
      "Silverado 2500": {},
      "Silverado 2500 HD": {},
      "Silverado 3500": {},
      "Suburban 1500": {},
      "Suburban 2500": {},
      Tahoe: {},
      Tracker: {},
      Trailblazer: {},
      "Trailblazer EXT": {},
      Venture: {},
    },
    Chrysler: {
      "300M": {},
      Concorde: {},
      Crossfire: {},
      Intrepid: {},
      "PT Cruiser": {},
      Pacifica: {},
      Sebring: {},
      "Town & Country": {},
    },
    Dodge: {
      Caravan: {},
      Dakota: {},
      Durango: {},
      "Grand Caravan": {},
      Intrepid: {},
      Neon: {},
      "Ram 1500": {},
      "Ram 2500": {},
      "Ram 3500": {},
      "SX 2.0": {},
      "Sprinter 2500": {},
      "Sprinter 3500": {},
      Stratus: {},
      Viper: {},
    },
    Ferrari: {
      "360": {},
      "456 M GT": {},
      "456 M GTA": {},
      "575 M Maranello": {},
      Enzo: {},
    },
    Ford: {
      "Crown Victoria": {},
      "E-150": {},
      "E-150 Club Wagon": {},
      "E-250": {},
      "E-350 Club Wagon": {},
      "E-350 Super Duty": {},
      Escape: {},
      Excursion: {},
      Expedition: {},
      Explorer: {},
      "Explorer Sport Trac": {},
      "F-150": {},
      "F-150 Heritage": {},
      "F-250 Super Duty": {},
      "F-350 Super Duty": {},
      Focus: {},
      Freestar: {},
      Mustang: {},
      Ranger: {},
      Taurus: {},
      Thunderbird: {},
    },
    Freightliner: { "Sprinter 2500": {}, "Sprinter 3500": {} },
    GMC: {
      Canyon: {},
      Envoy: {},
      "Envoy XL": {},
      "Envoy XUV": {},
      Jimmy: {},
      Safari: {},
      "Savana 1500": {},
      "Savana 2500": {},
      "Savana 3500": {},
      "Sierra 1500": {},
      "Sierra 2500": {},
      "Sierra 2500 HD": {},
      "Sierra 3500": {},
      Sonoma: {},
      Yukon: {},
      "Yukon XL 1500": {},
      "Yukon XL 2500": {},
    },
    Honda: {
      Accord: {},
      "CR-V": {},
      Civic: {},
      Element: {},
      Insight: {},
      Odyssey: {},
      Pilot: {},
      S2000: {},
    },
    Hummer: { H1: {}, H2: {} },
    Hyundai: {
      Accent: {},
      Elantra: {},
      "Santa Fe": {},
      Sonata: {},
      Tiburon: {},
      XG350: {},
    },
    Infiniti: {
      FX35: {},
      FX45: {},
      G35: {},
      I35: {},
      M45: {},
      Q45: {},
      QX56: {},
    },
    Isuzu: { Ascender: {}, Axiom: {}, Rodeo: {} },
    Jaguar: {
      "S-Type": {},
      "Vanden Plas": {},
      "X-Type": {},
      XJ8: {},
      XJR: {},
      XK8: {},
      XKR: {},
    },
    Jeep: { "Grand Cherokee": {}, Liberty: {}, TJ: {}, Wrangler: {} },
    Kia: {
      Amanti: {},
      Magentis: {},
      Optima: {},
      Rio: {},
      Sedona: {},
      Sorento: {},
      Spectra: {},
    },
    Lamborghini: { Gallardo: {}, Murcielago: {} },
    "Land Rover": { Discovery: {}, Freelander: {}, "Range Rover": {} },
    Lexus: {
      ES330: {},
      GS300: {},
      GS430: {},
      GX470: {},
      IS300: {},
      LS430: {},
      LX470: {},
      RX330: {},
      SC430: {},
    },
    Lincoln: { Aviator: {}, LS: {}, Navigator: {}, "Town Car": {} },
    Lotus: { Esprit: {} },
    Maserati: { Coupe: {}, Quattroporte: {}, Spyder: {} },
    Mazda: {
      "3": {},
      "6": {},
      B2300: {},
      B3000: {},
      B4000: {},
      MPV: {},
      Miata: {},
      "RX-8": {},
      Tribute: {},
    },
    "Mercedes-Benz": {
      C230: {},
      C240: {},
      "C32 AMG": {},
      C320: {},
      CL500: {},
      "CL55 AMG": {},
      CL600: {},
      CLK320: {},
      CLK500: {},
      "CLK55 AMG": {},
      E320: {},
      E500: {},
      "E55 AMG": {},
      G500: {},
      "G55 AMG": {},
      ML350: {},
      ML500: {},
      S430: {},
      S500: {},
      "S55 AMG": {},
      S600: {},
      SL500: {},
      "SL55 AMG": {},
      SL600: {},
      SLK230: {},
      "SLK32 AMG": {},
      SLK320: {},
    },
    Mercury: {
      "Grand Marquis": {},
      Marauder: {},
      Monterey: {},
      Mountaineer: {},
      Sable: {},
    },
    Mini: { Cooper: {} },
    Mitsubishi: {
      Diamante: {},
      Eclipse: {},
      Endeavor: {},
      Galant: {},
      Lancer: {},
      Montero: {},
      "Montero Sport": {},
      Outlander: {},
    },
    Nissan: {
      "350Z": {},
      Altima: {},
      Frontier: {},
      Maxima: {},
      Murano: {},
      Pathfinder: {},
      "Pathfinder Armada": {},
      Quest: {},
      Sentra: {},
      Titan: {},
      Xterra: {},
    },
    Oldsmobile: { Alero: {}, Bravada: {}, Silhouette: {} },
    Pontiac: {
      Aztek: {},
      Bonneville: {},
      GTO: {},
      "Grand Am": {},
      "Grand Prix": {},
      Montana: {},
      Sunfire: {},
      Vibe: {},
    },
    Porsche: { "911": {}, Boxster: {}, "Carrera GT": {}, Cayenne: {} },
    "Rolls-Royce": { Phantom: {} },
    Saab: { "9-3": {}, "9-5": {} },
    Saturn: { Ion: {}, L300: {}, Vue: {} },
    Scion: { xA: {}, xB: {} },
    Subaru: { Baja: {}, Forester: {}, Impreza: {}, Legacy: {}, Outback: {} },
    Suzuki: {
      Aerio: {},
      Forenza: {},
      "Grand Vitara": {},
      "Swift+": {},
      Verona: {},
      Vitara: {},
      "XL-7": {},
    },
    Toyota: {
      "4Runner": {},
      Avalon: {},
      Camry: {},
      Celica: {},
      Corolla: {},
      Echo: {},
      Highlander: {},
      "Land Cruiser": {},
      "MR2 Spyder": {},
      Matrix: {},
      Prius: {},
      RAV4: {},
      Sequoia: {},
      Sienna: {},
      Solara: {},
      Tacoma: {},
      Tundra: {},
    },
    Volkswagen: {
      Beetle: {},
      Golf: {},
      Jetta: {},
      Passat: {},
      Phaeton: {},
      R32: {},
      Touareg: {},
    },
    Volvo: {
      C70: {},
      S40: {},
      S60: {},
      S80: {},
      V40: {},
      V70: {},
      XC70: {},
      XC90: {},
    },
    Workhorse: {
      "FasTrack FT1061": {},
      "FasTrack FT1261": {},
      "FasTrack FT1461": {},
      "FasTrack FT1601": {},
      "FasTrack FT1801": {},
      P30: {},
    },
  },
  "2005": {
    Acura: { EL: {}, MDX: {}, NSX: {}, RL: {}, RSX: {}, TL: {}, TSX: {} },
    "Aston Martin": { DB9: {}, Vanquish: {} },
    Audi: {
      A4: {},
      "A4 Quattro": {},
      "A6 Quattro": {},
      "A8 Quattro": {},
      "Allroad Quattro": {},
      S4: {},
      TT: {},
      "TT Quattro": {},
    },
    BMW: {
      "320i": {},
      "325Ci": {},
      "325i": {},
      "325xi": {},
      "330Ci": {},
      "330i": {},
      "330xi": {},
      "525i": {},
      "530i": {},
      "545i": {},
      "645Ci": {},
      "745Li": {},
      "745i": {},
      "760Li": {},
      "760i": {},
      M3: {},
      X3: {},
      X5: {},
      Z4: {},
    },
    Bentley: { Arnage: {}, Continental: {} },
    Buick: {
      Allure: {},
      Century: {},
      LaCrosse: {},
      LeSabre: {},
      "Park Avenue": {},
      Rainier: {},
      Rendezvous: {},
      Terraza: {},
    },
    Cadillac: {
      CTS: {},
      DeVille: {},
      Escalade: {},
      "Escalade ESV": {},
      "Escalade EXT": {},
      SRX: {},
      STS: {},
      XLR: {},
    },
    Chevrolet: {
      Astro: {},
      "Avalanche 1500": {},
      "Avalanche 2500": {},
      Aveo: {},
      Blazer: {},
      Cavalier: {},
      Classic: {},
      Cobalt: {},
      Colorado: {},
      Corvette: {},
      Epica: {},
      Equinox: {},
      "Express 1500": {},
      "Express 2500": {},
      "Express 3500": {},
      Impala: {},
      Malibu: {},
      "Monte Carlo": {},
      Optra: {},
      SSR: {},
      "Silverado 1500": {},
      "Silverado 1500 HD": {},
      "Silverado 2500 HD": {},
      "Silverado 3500": {},
      "Suburban 1500": {},
      "Suburban 2500": {},
      Tahoe: {},
      Trailblazer: {},
      "Trailblazer EXT": {},
      Uplander: {},
      Venture: {},
    },
    Chrysler: {
      "300": {},
      Crossfire: {},
      "PT Cruiser": {},
      Pacifica: {},
      Sebring: {},
      "Town & Country": {},
    },
    Dodge: {
      Caravan: {},
      Dakota: {},
      Durango: {},
      "Grand Caravan": {},
      Magnum: {},
      Neon: {},
      "Ram 1500": {},
      "Ram 2500": {},
      "Ram 3500": {},
      "SX 2.0": {},
      "Sprinter 2500": {},
      "Sprinter 3500": {},
      Stratus: {},
      Viper: {},
    },
    Ferrari: {
      "360": {},
      "575 M Maranello": {},
      "612 Scaglietti": {},
      F430: {},
      Superamerica: {},
    },
    Ford: {
      "Crown Victoria": {},
      "E-150": {},
      "E-150 Club Wagon": {},
      "E-250": {},
      "E-350 Club Wagon": {},
      "E-350 Super Duty": {},
      Escape: {},
      Excursion: {},
      Expedition: {},
      Explorer: {},
      "Explorer Sport Trac": {},
      "F-150": {},
      "F-250 Super Duty": {},
      "F-350 Super Duty": {},
      "Five Hundred": {},
      Focus: {},
      Freestar: {},
      Freestyle: {},
      GT: {},
      Mustang: {},
      Ranger: {},
      Taurus: {},
      Thunderbird: {},
    },
    Freightliner: { "Sprinter 2500": {}, "Sprinter 3500": {} },
    GMC: {
      Canyon: {},
      Envoy: {},
      "Envoy XL": {},
      "Envoy XUV": {},
      Jimmy: {},
      Safari: {},
      "Savana 1500": {},
      "Savana 2500": {},
      "Savana 3500": {},
      "Sierra 1500": {},
      "Sierra 1500 HD": {},
      "Sierra 2500 HD": {},
      "Sierra 3500": {},
      Yukon: {},
      "Yukon XL 1500": {},
      "Yukon XL 2500": {},
    },
    Honda: {
      Accord: {},
      "CR-V": {},
      Civic: {},
      Element: {},
      Insight: {},
      Odyssey: {},
      Pilot: {},
      S2000: {},
    },
    Hummer: { H2: {} },
    Hyundai: {
      Accent: {},
      Elantra: {},
      "Santa Fe": {},
      Sonata: {},
      Tiburon: {},
      Tucson: {},
      XG350: {},
    },
    Infiniti: { FX35: {}, FX45: {}, G35: {}, Q45: {}, QX56: {} },
    Isuzu: { Ascender: {} },
    Jaguar: {
      "S-Type": {},
      "Super V8": {},
      "Vanden Plas": {},
      "X-Type": {},
      XJ8: {},
      XJR: {},
      XK8: {},
      XKR: {},
    },
    Jeep: { "Grand Cherokee": {}, Liberty: {}, TJ: {}, Wrangler: {} },
    Kia: {
      Amanti: {},
      Magentis: {},
      Optima: {},
      Rio: {},
      Sedona: {},
      Sorento: {},
      Spectra: {},
      Spectra5: {},
      Sportage: {},
    },
    Lamborghini: { Gallardo: {}, Murcielago: {} },
    "Land Rover": { Freelander: {}, LR3: {}, "Range Rover": {} },
    Lexus: {
      ES330: {},
      GS300: {},
      GS430: {},
      GX470: {},
      IS300: {},
      LS430: {},
      LX470: {},
      RX330: {},
      SC430: {},
    },
    Lincoln: { Aviator: {}, LS: {}, Navigator: {}, "Town Car": {} },
    Lotus: { Elise: {} },
    Maserati: { Coupe: {}, GranSport: {}, Quattroporte: {}, Spyder: {} },
    Mazda: {
      "3": {},
      "6": {},
      B2300: {},
      B3000: {},
      B4000: {},
      MPV: {},
      Miata: {},
      "RX-8": {},
      Tribute: {},
    },
    "Mercedes-Benz": {
      C230: {},
      C240: {},
      C320: {},
      "C55 AMG": {},
      CL500: {},
      "CL55 AMG": {},
      CL600: {},
      "CL65 AMG": {},
      CLK320: {},
      CLK500: {},
      "CLK55 AMG": {},
      E320: {},
      E500: {},
      "E55 AMG": {},
      G500: {},
      "G55 AMG": {},
      ML350: {},
      ML500: {},
      S430: {},
      S500: {},
      "S55 AMG": {},
      S600: {},
      SL500: {},
      "SL55 AMG": {},
      SL600: {},
      "SL65 AMG": {},
      SLK350: {},
      "SLK55 AMG": {},
      "SLR McLaren": {},
    },
    Mercury: {
      "Grand Marquis": {},
      Mariner: {},
      Montego: {},
      Monterey: {},
      Mountaineer: {},
      Sable: {},
    },
    Mini: { Cooper: {} },
    Mitsubishi: {
      Eclipse: {},
      Endeavor: {},
      Galant: {},
      Lancer: {},
      Montero: {},
      Outlander: {},
    },
    Nissan: {
      "350Z": {},
      Altima: {},
      Armada: {},
      Frontier: {},
      Maxima: {},
      Murano: {},
      Pathfinder: {},
      Quest: {},
      Sentra: {},
      Titan: {},
      "X-Trail": {},
      Xterra: {},
    },
    Pontiac: {
      Aztek: {},
      Bonneville: {},
      G6: {},
      GTO: {},
      "Grand Am": {},
      "Grand Prix": {},
      Montana: {},
      Pursuit: {},
      Sunfire: {},
      Vibe: {},
      Wave: {},
      Wave5: {},
    },
    Porsche: { "911": {}, Boxster: {}, "Carrera GT": {}, Cayenne: {} },
    "Rolls-Royce": { Phantom: {} },
    Saab: { "9-2X": {}, "9-3": {}, "9-5": {}, "9-7x": {} },
    Saturn: { Ion: {}, L300: {}, Relay: {}, Vue: {} },
    Scion: { tC: {}, xA: {}, xB: {} },
    Smart: { Fortwo: {} },
    Spyker: { C8: {} },
    Subaru: { Baja: {}, Forester: {}, Impreza: {}, Legacy: {}, Outback: {} },
    Suzuki: {
      Aerio: {},
      Forenza: {},
      "Grand Vitara": {},
      Reno: {},
      "Swift+": {},
      Verona: {},
      "XL-7": {},
    },
    Toyota: {
      "4Runner": {},
      Avalon: {},
      Camry: {},
      Celica: {},
      Corolla: {},
      Echo: {},
      Highlander: {},
      "Land Cruiser": {},
      "MR2 Spyder": {},
      Matrix: {},
      Prius: {},
      RAV4: {},
      Sequoia: {},
      Sienna: {},
      Solara: {},
      Tacoma: {},
      Tundra: {},
    },
    Volkswagen: {
      Beetle: {},
      Golf: {},
      Jetta: {},
      Passat: {},
      Phaeton: {},
      Touareg: {},
    },
    Volvo: { S40: {}, S60: {}, S80: {}, V50: {}, V70: {}, XC70: {}, XC90: {} },
    Workhorse: {
      "FasTrack FT1061": {},
      "FasTrack FT1261": {},
      "FasTrack FT1461": {},
      "FasTrack FT1601": {},
      "FasTrack FT1801": {},
      P30: {},
    },
  },
  "2006": {
    Acura: { CSX: {}, MDX: {}, RL: {}, RSX: {}, TL: {}, TSX: {} },
    "Aston Martin": { DB9: {}, "V8 Vantage": {}, Vanquish: {} },
    Audi: {
      A3: {},
      "A3 Quattro": {},
      A4: {},
      "A4 Quattro": {},
      A6: {},
      "A6 Quattro": {},
      "A8 Quattro": {},
      S4: {},
      TT: {},
      "TT Quattro": {},
    },
    BMW: {
      "323i": {},
      "325Ci": {},
      "325i": {},
      "325xi": {},
      "330Ci": {},
      "330i": {},
      "330xi": {},
      "525i": {},
      "525xi": {},
      "530i": {},
      "530xi": {},
      "550i": {},
      "650i": {},
      "750Li": {},
      "750i": {},
      "760Li": {},
      "760i": {},
      M3: {},
      M5: {},
      M6: {},
      X3: {},
      X5: {},
      Z4: {},
    },
    Bentley: { Arnage: {}, Azure: {}, Continental: {} },
    Buick: {
      Allure: {},
      LaCrosse: {},
      Lucerne: {},
      Rainier: {},
      Rendezvous: {},
      Terraza: {},
    },
    Cadillac: {
      CTS: {},
      DTS: {},
      Escalade: {},
      "Escalade ESV": {},
      "Escalade EXT": {},
      SRX: {},
      STS: {},
      XLR: {},
    },
    Chevrolet: {
      "Avalanche 1500": {},
      "Avalanche 2500": {},
      Aveo: {},
      Aveo5: {},
      Cobalt: {},
      Colorado: {},
      Corvette: {},
      Epica: {},
      Equinox: {},
      "Express 1500": {},
      "Express 2500": {},
      "Express 3500": {},
      HHR: {},
      Impala: {},
      Malibu: {},
      "Monte Carlo": {},
      Optra: {},
      SSR: {},
      "Silverado 1500": {},
      "Silverado 1500 HD": {},
      "Silverado 2500 HD": {},
      "Silverado 3500": {},
      "Suburban 1500": {},
      "Suburban 2500": {},
      Tahoe: {},
      Trailblazer: {},
      "Trailblazer EXT": {},
      Uplander: {},
    },
    Chrysler: {
      "300": {},
      Crossfire: {},
      "PT Cruiser": {},
      Pacifica: {},
      Sebring: {},
      "Town & Country": {},
    },
    Dodge: {
      Caravan: {},
      Charger: {},
      Dakota: {},
      Durango: {},
      "Grand Caravan": {},
      Magnum: {},
      "Ram 1500": {},
      "Ram 2500": {},
      "Ram 3500": {},
      "Sprinter 2500": {},
      "Sprinter 3500": {},
      Stratus: {},
      Viper: {},
    },
    Ferrari: { "612 Scaglietti": {}, F430: {} },
    Ford: {
      "Crown Victoria": {},
      "E-150": {},
      "E-250": {},
      "E-350 Super Duty": {},
      Escape: {},
      Expedition: {},
      Explorer: {},
      "F-150": {},
      "F-250 Super Duty": {},
      "F-350 Super Duty": {},
      "Five Hundred": {},
      Focus: {},
      Freestar: {},
      Freestyle: {},
      Fusion: {},
      GT: {},
      Mustang: {},
      Ranger: {},
      Taurus: {},
    },
    Freightliner: { "Sprinter 2500": {}, "Sprinter 3500": {} },
    GMC: {
      Canyon: {},
      Envoy: {},
      "Envoy XL": {},
      "Savana 1500": {},
      "Savana 2500": {},
      "Savana 3500": {},
      "Sierra 1500": {},
      "Sierra 1500 HD": {},
      "Sierra 2500 HD": {},
      "Sierra 3500": {},
      Yukon: {},
      "Yukon XL 1500": {},
      "Yukon XL 2500": {},
    },
    Honda: {
      Accord: {},
      "CR-V": {},
      Civic: {},
      Element: {},
      Insight: {},
      Odyssey: {},
      Pilot: {},
      Ridgeline: {},
      S2000: {},
    },
    Hummer: { H1: {}, H2: {}, H3: {} },
    Hyundai: {
      Accent: {},
      Azera: {},
      Elantra: {},
      "Santa Fe": {},
      Sonata: {},
      Tiburon: {},
      Tucson: {},
    },
    Infiniti: {
      FX35: {},
      FX45: {},
      G35: {},
      M35: {},
      M45: {},
      Q45: {},
      QX56: {},
    },
    Isuzu: { Ascender: {}, "i-280": {}, "i-350": {} },
    Jaguar: {
      "S-Type": {},
      "Super V8": {},
      "Vanden Plas": {},
      "X-Type": {},
      XJ8: {},
      XJR: {},
      XK8: {},
      XKR: {},
    },
    Jeep: {
      Commander: {},
      "Grand Cherokee": {},
      Liberty: {},
      TJ: {},
      Wrangler: {},
    },
    Kia: {
      Amanti: {},
      Magentis: {},
      Optima: {},
      Rio: {},
      Rio5: {},
      Sedona: {},
      Sorento: {},
      Spectra: {},
      Spectra5: {},
      Sportage: {},
    },
    Lamborghini: { Gallardo: {}, Murcielago: {} },
    "Land Rover": { LR3: {}, "Range Rover": {}, "Range Rover Sport": {} },
    Lexus: {
      ES330: {},
      GS300: {},
      GS430: {},
      GX470: {},
      IS250: {},
      IS350: {},
      LS430: {},
      LX470: {},
      RX330: {},
      RX400h: {},
      SC430: {},
    },
    Lincoln: {
      LS: {},
      "Mark LT": {},
      Navigator: {},
      "Town Car": {},
      Zephyr: {},
    },
    Lotus: { Elise: {}, Exige: {} },
    Maserati: { Coupe: {}, GranSport: {}, Quattroporte: {}, Spyder: {} },
    Mazda: {
      "3": {},
      "5": {},
      "6": {},
      B2300: {},
      B3000: {},
      B4000: {},
      MPV: {},
      "MX-5 Miata": {},
      "RX-8": {},
      Tribute: {},
    },
    "Mercedes-Benz": {
      B200: {},
      C230: {},
      C280: {},
      C350: {},
      "C55 AMG": {},
      CL500: {},
      "CL55 AMG": {},
      CL600: {},
      "CL65 AMG": {},
      CLK350: {},
      CLK500: {},
      "CLK55 AMG": {},
      CLS500: {},
      "CLS55 AMG": {},
      E320: {},
      E350: {},
      E500: {},
      "E55 AMG": {},
      G500: {},
      "G55 AMG": {},
      ML350: {},
      ML500: {},
      R350: {},
      R500: {},
      S350: {},
      S430: {},
      S500: {},
      "S55 AMG": {},
      S600: {},
      "S65 AMG": {},
      SL500: {},
      "SL55 AMG": {},
      SL600: {},
      "SL65 AMG": {},
      SLK280: {},
      SLK350: {},
      "SLK55 AMG": {},
      "SLR McLaren": {},
    },
    Mercury: {
      "Grand Marquis": {},
      Mariner: {},
      Milan: {},
      Montego: {},
      Monterey: {},
      Mountaineer: {},
    },
    Mini: { Cooper: {} },
    Mitsubishi: {
      Eclipse: {},
      Endeavor: {},
      Galant: {},
      Lancer: {},
      Montero: {},
      Outlander: {},
      Raider: {},
    },
    Nissan: {
      "350Z": {},
      Altima: {},
      Armada: {},
      Frontier: {},
      Maxima: {},
      Murano: {},
      Pathfinder: {},
      Quest: {},
      Sentra: {},
      Titan: {},
      "X-Trail": {},
      Xterra: {},
    },
    Pontiac: {
      G6: {},
      GTO: {},
      "Grand Prix": {},
      Montana: {},
      Pursuit: {},
      Solstice: {},
      Torrent: {},
      Vibe: {},
      Wave: {},
      Wave5: {},
    },
    Porsche: { "911": {}, Boxster: {}, Cayenne: {}, Cayman: {} },
    "Rolls-Royce": { Phantom: {} },
    Saab: { "9-2X": {}, "9-3": {}, "9-5": {}, "9-7x": {} },
    Saturn: { Ion: {}, Relay: {}, Vue: {} },
    Scion: { tC: {}, xA: {}, xB: {} },
    Smart: { Fortwo: {} },
    Spyker: { C8: {} },
    Subaru: {
      "B9 Tribeca": {},
      Baja: {},
      Forester: {},
      Impreza: {},
      Legacy: {},
      Outback: {},
    },
    Suzuki: {
      Aerio: {},
      Forenza: {},
      "Grand Vitara": {},
      Reno: {},
      "Swift+": {},
      Verona: {},
      "XL-7": {},
    },
    Toyota: {
      "4Runner": {},
      Avalon: {},
      Camry: {},
      Corolla: {},
      Highlander: {},
      "Land Cruiser": {},
      Matrix: {},
      Prius: {},
      RAV4: {},
      Sequoia: {},
      Sienna: {},
      Solara: {},
      Tacoma: {},
      Tundra: {},
      Yaris: {},
    },
    Volkswagen: {
      Beetle: {},
      GTI: {},
      Golf: {},
      Jetta: {},
      Passat: {},
      Phaeton: {},
      Rabbit: {},
      Touareg: {},
    },
    Volvo: {
      C70: {},
      S40: {},
      S60: {},
      S80: {},
      V50: {},
      V70: {},
      XC70: {},
      XC90: {},
    },
  },
  "2007": {
    Acura: { CSX: {}, MDX: {}, RDX: {}, RL: {}, TL: {}, TSX: {} },
    "Aston Martin": { DB9: {}, "V8 Vantage": {} },
    Audi: {
      A3: {},
      "A3 Quattro": {},
      A4: {},
      "A4 Quattro": {},
      A6: {},
      "A6 Quattro": {},
      "A8 Quattro": {},
      Q7: {},
      RS4: {},
      S4: {},
      S6: {},
      S8: {},
    },
    BMW: {
      "323i": {},
      "328i": {},
      "328xi": {},
      "335i": {},
      "335xi": {},
      "525i": {},
      "525xi": {},
      "530i": {},
      "530xi": {},
      "550i": {},
      "650i": {},
      "750Li": {},
      "750i": {},
      "760Li": {},
      "Alpina B7": {},
      M5: {},
      M6: {},
      X3: {},
      X5: {},
      Z4: {},
    },
    Bentley: { Arnage: {}, Azure: {}, Continental: {} },
    Buick: {
      Allure: {},
      LaCrosse: {},
      Lucerne: {},
      Rainier: {},
      Rendezvous: {},
      Terraza: {},
    },
    Cadillac: {
      CTS: {},
      DTS: {},
      Escalade: {},
      "Escalade ESV": {},
      "Escalade EXT": {},
      SRX: {},
      STS: {},
      XLR: {},
    },
    Chevrolet: {
      Avalanche: {},
      Aveo: {},
      Aveo5: {},
      Cobalt: {},
      Colorado: {},
      Corvette: {},
      Equinox: {},
      "Express 1500": {},
      "Express 2500": {},
      "Express 3500": {},
      HHR: {},
      Impala: {},
      Malibu: {},
      "Monte Carlo": {},
      Optra: {},
      "Silverado 1500": {},
      "Silverado 1500 Classic": {},
      "Silverado 1500 HD Classic": {},
      "Silverado 2500 HD": {},
      "Silverado 2500 HD Classic": {},
      "Silverado 3500 Classic": {},
      "Silverado 3500 HD": {},
      "Suburban 1500": {},
      "Suburban 2500": {},
      Tahoe: {},
      Trailblazer: {},
      Uplander: {},
    },
    Chrysler: {
      "300": {},
      Aspen: {},
      Crossfire: {},
      "PT Cruiser": {},
      Pacifica: {},
      Sebring: {},
      "Town & Country": {},
    },
    Dodge: {
      Caliber: {},
      Caravan: {},
      Charger: {},
      Dakota: {},
      Durango: {},
      "Grand Caravan": {},
      Magnum: {},
      Nitro: {},
      "Ram 1500": {},
      "Ram 2500": {},
      "Ram 3500": {},
      "Sprinter 2500": {},
      "Sprinter 3500": {},
    },
    Ferrari: { "599 GTB": {}, "612 Scaglietti": {}, F430: {} },
    Ford: {
      "Crown Victoria": {},
      "E-150": {},
      "E-250": {},
      "E-350 Super Duty": {},
      Edge: {},
      Escape: {},
      Expedition: {},
      Explorer: {},
      "Explorer Sport Trac": {},
      "F-150": {},
      "F-250 Super Duty": {},
      "F-350 Super Duty": {},
      "Five Hundred": {},
      Focus: {},
      Freestar: {},
      Freestyle: {},
      Fusion: {},
      Mustang: {},
      Ranger: {},
      Taurus: {},
    },
    Freightliner: { "Sprinter 2500": {}, "Sprinter 3500": {} },
    GMC: {
      Acadia: {},
      Canyon: {},
      Envoy: {},
      "Savana 1500": {},
      "Savana 2500": {},
      "Savana 3500": {},
      "Sierra 1500": {},
      "Sierra 1500 Classic": {},
      "Sierra 1500 HD Classic": {},
      "Sierra 2500 HD": {},
      "Sierra 2500 HD Classic": {},
      "Sierra 3500 Classic": {},
      "Sierra 3500 HD": {},
      Yukon: {},
      "Yukon XL 1500": {},
      "Yukon XL 2500": {},
    },
    Honda: {
      Accord: {},
      "CR-V": {},
      Civic: {},
      Element: {},
      Fit: {},
      Odyssey: {},
      Pilot: {},
      Ridgeline: {},
      S2000: {},
    },
    Hummer: { H2: {}, H3: {} },
    Hyundai: {
      Accent: {},
      Azera: {},
      Elantra: {},
      Entourage: {},
      "Santa Fe": {},
      Sonata: {},
      Tiburon: {},
      Tucson: {},
      Veracruz: {},
    },
    Infiniti: { FX35: {}, FX45: {}, G35: {}, M35: {}, M45: {}, QX56: {} },
    Isuzu: { Ascender: {}, "i-290": {}, "i-370": {} },
    Jaguar: {
      "S-Type": {},
      "Super V8": {},
      "Vanden Plas": {},
      "X-Type": {},
      XJ8: {},
      XJR: {},
      XK: {},
      XKR: {},
    },
    Jeep: {
      Commander: {},
      Compass: {},
      "Grand Cherokee": {},
      Liberty: {},
      Patriot: {},
      Wrangler: {},
    },
    Kia: {
      Amanti: {},
      Magentis: {},
      Optima: {},
      Rio: {},
      Rio5: {},
      Rondo: {},
      Sedona: {},
      Sorento: {},
      Spectra: {},
      Spectra5: {},
      Sportage: {},
    },
    Lamborghini: { Gallardo: {}, Murcielago: {} },
    "Land Rover": { LR3: {}, "Range Rover": {}, "Range Rover Sport": {} },
    Lexus: {
      ES350: {},
      GS350: {},
      GS430: {},
      GS450h: {},
      GX470: {},
      IS250: {},
      IS350: {},
      LS460: {},
      LX470: {},
      RX350: {},
      RX400h: {},
      SC430: {},
    },
    Lincoln: { MKX: {}, MKZ: {}, "Mark LT": {}, Navigator: {}, "Town Car": {} },
    Lotus: { Elise: {}, Exige: {} },
    Maserati: { Coupe: {}, GranSport: {}, Quattroporte: {} },
    Mazda: {
      "3": {},
      "5": {},
      "6": {},
      B2300: {},
      B3000: {},
      B4000: {},
      "CX-7": {},
      "CX-9": {},
      "MX-5 Miata": {},
      "RX-8": {},
    },
    "Mercedes-Benz": {
      B200: {},
      C230: {},
      C280: {},
      C350: {},
      CL550: {},
      CL600: {},
      CLK350: {},
      CLK550: {},
      "CLK63 AMG": {},
      CLS550: {},
      "CLS63 AMG": {},
      E280: {},
      E320: {},
      E350: {},
      E550: {},
      "E63 AMG": {},
      G500: {},
      "G55 AMG": {},
      GL320: {},
      GL450: {},
      ML320: {},
      ML350: {},
      ML500: {},
      "ML63 AMG": {},
      R320: {},
      R350: {},
      R500: {},
      "R63 AMG": {},
      S550: {},
      S600: {},
      "S65 AMG": {},
      "SL55 AMG": {},
      SL550: {},
      SL600: {},
      "SL65 AMG": {},
      SLK280: {},
      SLK350: {},
      "SLK55 AMG": {},
      "SLR McLaren": {},
    },
    Mercury: {
      "Grand Marquis": {},
      Mariner: {},
      Milan: {},
      Montego: {},
      Monterey: {},
      Mountaineer: {},
    },
    Mini: { Cooper: {} },
    Mitsubishi: {
      Eclipse: {},
      Endeavor: {},
      Galant: {},
      Lancer: {},
      Outlander: {},
      Raider: {},
    },
    Nissan: {
      "350Z": {},
      Altima: {},
      Armada: {},
      Frontier: {},
      Maxima: {},
      Murano: {},
      Pathfinder: {},
      Quest: {},
      Sentra: {},
      Titan: {},
      Versa: {},
      Xterra: {},
    },
    Pontiac: {
      G5: {},
      G6: {},
      "Grand Prix": {},
      Montana: {},
      Solstice: {},
      Torrent: {},
      Vibe: {},
      Wave: {},
      Wave5: {},
    },
    Porsche: { "911": {}, Boxster: {}, Cayman: {} },
    "Rolls-Royce": { Phantom: {} },
    Saab: { "9-3": {}, "9-5": {}, "9-7x": {} },
    Saturn: { Aura: {}, Ion: {}, Outlook: {}, Relay: {}, Sky: {}, Vue: {} },
    Scion: { tC: {} },
    Smart: { Fortwo: {} },
    Spyker: { C8: {} },
    Subaru: {
      "B9 Tribeca": {},
      Forester: {},
      Impreza: {},
      Legacy: {},
      Outback: {},
    },
    Suzuki: {
      Aerio: {},
      Forenza: {},
      "Grand Vitara": {},
      Reno: {},
      SX4: {},
      "Swift+": {},
      "XL-7": {},
    },
    Toyota: {
      "4Runner": {},
      Avalon: {},
      Camry: {},
      Corolla: {},
      "FJ Cruiser": {},
      Highlander: {},
      "Land Cruiser": {},
      Matrix: {},
      Prius: {},
      RAV4: {},
      Sequoia: {},
      Sienna: {},
      Solara: {},
      Tacoma: {},
      Tundra: {},
      Yaris: {},
    },
    Volkswagen: {
      Beetle: {},
      Eos: {},
      GTI: {},
      "Golf City": {},
      Jetta: {},
      "Jetta City": {},
      Passat: {},
      Rabbit: {},
      Touareg: {},
    },
    Volvo: {
      C30: {},
      C70: {},
      S40: {},
      S60: {},
      S80: {},
      V50: {},
      V70: {},
      XC70: {},
      XC90: {},
    },
  },
  "2008": {
    Acura: { CSX: {}, MDX: {}, RDX: {}, RL: {}, TL: {}, TSX: {} },
    "Alfa Romeo": { "8C": {} },
    "Aston Martin": { DB9: {}, DBS: {}, "V8 Vantage": {} },
    Audi: {
      A3: {},
      "A3 Quattro": {},
      A4: {},
      "A4 Quattro": {},
      "A5 Quattro": {},
      A6: {},
      "A6 Quattro": {},
      "A8 Quattro": {},
      Q7: {},
      R8: {},
      RS4: {},
      S4: {},
      S5: {},
      S6: {},
      S8: {},
      TT: {},
      "TT Quattro": {},
    },
    BMW: {
      "128i": {},
      "135i": {},
      "323i": {},
      "328i": {},
      "328xi": {},
      "335i": {},
      "335xi": {},
      "528i": {},
      "528xi": {},
      "535i": {},
      "535xi": {},
      "550i": {},
      "650i": {},
      "750Li": {},
      "750i": {},
      "760Li": {},
      "Alpina B7": {},
      M3: {},
      M5: {},
      M6: {},
      X3: {},
      X5: {},
      X6: {},
      Z4: {},
    },
    Bentley: { Arnage: {}, Azure: {}, Brooklands: {}, Continental: {} },
    Buick: { Allure: {}, Enclave: {}, LaCrosse: {}, Lucerne: {} },
    Cadillac: {
      CTS: {},
      DTS: {},
      Escalade: {},
      "Escalade ESV": {},
      "Escalade EXT": {},
      SRX: {},
      STS: {},
      XLR: {},
    },
    Chevrolet: {
      Avalanche: {},
      Aveo: {},
      Aveo5: {},
      Cobalt: {},
      Colorado: {},
      Corvette: {},
      Equinox: {},
      "Express 1500": {},
      "Express 2500": {},
      "Express 3500": {},
      HHR: {},
      Impala: {},
      Malibu: {},
      "Silverado 1500": {},
      "Silverado 2500 HD": {},
      "Silverado 3500 HD": {},
      "Suburban 1500": {},
      "Suburban 2500": {},
      Tahoe: {},
      Trailblazer: {},
      Uplander: {},
    },
    Chrysler: {
      "300": {},
      Aspen: {},
      Crossfire: {},
      "PT Cruiser": {},
      Pacifica: {},
      Sebring: {},
      "Town & Country": {},
    },
    Dodge: {
      Avenger: {},
      Caliber: {},
      Challenger: {},
      Charger: {},
      Dakota: {},
      Durango: {},
      "Grand Caravan": {},
      Magnum: {},
      Nitro: {},
      "Ram 1500": {},
      "Ram 2500": {},
      "Ram 3500": {},
      "Sprinter 2500": {},
      "Sprinter 3500": {},
      Viper: {},
    },
    Ferrari: { "599 GTB": {}, "612 Scaglietti": {}, F430: {} },
    Ford: {
      "Crown Victoria": {},
      "E-150": {},
      "E-250": {},
      "E-350 Super Duty": {},
      Edge: {},
      Escape: {},
      Expedition: {},
      Explorer: {},
      "Explorer Sport Trac": {},
      "F-150": {},
      "F-250 Super Duty": {},
      "F-350 Super Duty": {},
      Focus: {},
      Fusion: {},
      Mustang: {},
      Ranger: {},
      Taurus: {},
      "Taurus X": {},
    },
    Freightliner: { "Sprinter 2500": {}, "Sprinter 3500": {} },
    GMC: {
      Acadia: {},
      Canyon: {},
      Envoy: {},
      "Savana 1500": {},
      "Savana 2500": {},
      "Savana 3500": {},
      "Sierra 1500": {},
      "Sierra 2500 HD": {},
      "Sierra 3500 HD": {},
      Yukon: {},
      "Yukon XL 1500": {},
      "Yukon XL 2500": {},
    },
    Honda: {
      Accord: {},
      "CR-V": {},
      Civic: {},
      Element: {},
      Fit: {},
      Odyssey: {},
      Pilot: {},
      Ridgeline: {},
      S2000: {},
    },
    Hummer: { H2: {}, H3: {} },
    Hyundai: {
      Accent: {},
      Azera: {},
      Elantra: {},
      Entourage: {},
      "Santa Fe": {},
      Sonata: {},
      Tiburon: {},
      Tucson: {},
      Veracruz: {},
    },
    Infiniti: {
      EX35: {},
      FX35: {},
      FX45: {},
      G35: {},
      G37: {},
      M35: {},
      M45: {},
      QX56: {},
    },
    Isuzu: { Ascender: {}, "i-290": {}, "i-370": {} },
    Jaguar: {
      "S-Type": {},
      "Super V8": {},
      "Vanden Plas": {},
      "X-Type": {},
      XJ8: {},
      XJR: {},
      XK: {},
      XKR: {},
    },
    Jeep: {
      Commander: {},
      Compass: {},
      "Grand Cherokee": {},
      Liberty: {},
      Patriot: {},
      Wrangler: {},
    },
    Kia: {
      Amanti: {},
      Magentis: {},
      Optima: {},
      Rio: {},
      Rio5: {},
      Rondo: {},
      Sedona: {},
      Sorento: {},
      Spectra: {},
      Spectra5: {},
      Sportage: {},
    },
    Lamborghini: { Gallardo: {}, Murcielago: {} },
    "Land Rover": {
      LR2: {},
      LR3: {},
      "Range Rover": {},
      "Range Rover Sport": {},
    },
    Lexus: {
      ES350: {},
      GS350: {},
      GS450h: {},
      GS460: {},
      GX470: {},
      "IS F": {},
      IS250: {},
      IS350: {},
      LS460: {},
      LS600h: {},
      LX570: {},
      RX350: {},
      RX400h: {},
      SC430: {},
    },
    Lincoln: { MKX: {}, MKZ: {}, "Mark LT": {}, Navigator: {}, "Town Car": {} },
    Lotus: { Elise: {}, Exige: {} },
    Maserati: { GranTurismo: {}, Quattroporte: {} },
    Mazda: {
      "3": {},
      "5": {},
      "6": {},
      B2300: {},
      B3000: {},
      B4000: {},
      "CX-7": {},
      "CX-9": {},
      "MX-5 Miata": {},
      "RX-8": {},
      Tribute: {},
    },
    "Mercedes-Benz": {
      B200: {},
      C230: {},
      C300: {},
      C350: {},
      "C63 AMG": {},
      CL550: {},
      CL600: {},
      "CL63 AMG": {},
      "CL65 AMG": {},
      CLK350: {},
      CLK550: {},
      "CLK63 AMG": {},
      CLS550: {},
      "CLS63 AMG": {},
      E300: {},
      E320: {},
      E350: {},
      E550: {},
      "E63 AMG": {},
      G500: {},
      "G55 AMG": {},
      GL320: {},
      GL450: {},
      GL550: {},
      ML320: {},
      ML350: {},
      ML550: {},
      "ML63 AMG": {},
      R320: {},
      R350: {},
      R550: {},
      S450: {},
      S550: {},
      S600: {},
      "S63 AMG": {},
      "S65 AMG": {},
      "SL55 AMG": {},
      SL550: {},
      SL600: {},
      "SL65 AMG": {},
      SLK280: {},
      SLK350: {},
      "SLK55 AMG": {},
      "SLR McLaren": {},
    },
    Mercury: {
      "Grand Marquis": {},
      Mariner: {},
      Milan: {},
      Mountaineer: {},
      Sable: {},
    },
    Mini: { Cooper: {} },
    Mitsubishi: {
      Eclipse: {},
      Endeavor: {},
      Galant: {},
      Lancer: {},
      Outlander: {},
      Raider: {},
    },
    Nissan: {
      "350Z": {},
      Altima: {},
      Armada: {},
      Frontier: {},
      Maxima: {},
      Pathfinder: {},
      Quest: {},
      Rogue: {},
      Sentra: {},
      Titan: {},
      Versa: {},
      Xterra: {},
    },
    Pontiac: {
      G5: {},
      G6: {},
      G8: {},
      "Grand Prix": {},
      Montana: {},
      Solstice: {},
      Torrent: {},
      Vibe: {},
      Wave: {},
    },
    Porsche: { "911": {}, Boxster: {}, Cayenne: {}, Cayman: {} },
    "Rolls-Royce": { Phantom: {} },
    Saab: { "9-3": {}, "9-5": {}, "9-7x": {} },
    Saturn: { Astra: {}, Aura: {}, Outlook: {}, Sky: {}, Vue: {} },
    Scion: { tC: {}, xB: {}, xD: {} },
    Smart: { Fortwo: {} },
    Spyker: { C12: {}, C8: {} },
    Subaru: { Forester: {}, Impreza: {}, Legacy: {}, Outback: {}, Tribeca: {} },
    Suzuki: {
      Forenza: {},
      "Grand Vitara": {},
      Reno: {},
      SX4: {},
      "Swift+": {},
      "XL-7": {},
    },
    Tesla: { Roadster: {} },
    Toyota: {
      "4Runner": {},
      Avalon: {},
      Camry: {},
      Corolla: {},
      "FJ Cruiser": {},
      Highlander: {},
      "Land Cruiser": {},
      Matrix: {},
      Prius: {},
      RAV4: {},
      Sequoia: {},
      Sienna: {},
      Solara: {},
      Tacoma: {},
      Tundra: {},
      Yaris: {},
    },
    Volkswagen: {
      Beetle: {},
      Eos: {},
      GTI: {},
      "Golf City": {},
      Jetta: {},
      "Jetta City": {},
      Passat: {},
      R32: {},
      Rabbit: {},
      Touareg: {},
    },
    Volvo: {
      C30: {},
      C70: {},
      S40: {},
      S60: {},
      S80: {},
      V50: {},
      V70: {},
      XC70: {},
      XC90: {},
    },
  },
  "2009": {
    Acura: { CSX: {}, MDX: {}, RDX: {}, RL: {}, TL: {}, TSX: {} },
    "Aston Martin": { DB9: {}, DBS: {}, "V8 Vantage": {} },
    Audi: {
      A3: {},
      "A3 Quattro": {},
      A4: {},
      "A4 Quattro": {},
      "A5 Quattro": {},
      A6: {},
      "A6 Quattro": {},
      "A8 Quattro": {},
      Q5: {},
      Q7: {},
      R8: {},
      S4: {},
      S5: {},
      S6: {},
      S8: {},
      TT: {},
      "TT Quattro": {},
      "TTS Quattro": {},
    },
    BMW: {
      "128i": {},
      "135i": {},
      "323i": {},
      "328i": {},
      "328i xDrive": {},
      "335d": {},
      "335i": {},
      "335i xDrive": {},
      "528i": {},
      "528i xDrive": {},
      "535i": {},
      "535i xDrive": {},
      "550i": {},
      "650i": {},
      "750Li": {},
      "750i": {},
      M3: {},
      M5: {},
      M6: {},
      X3: {},
      X5: {},
      X6: {},
      Z4: {},
    },
    Bentley: { Arnage: {}, Azure: {}, Brooklands: {}, Continental: {} },
    Buick: { Allure: {}, Enclave: {}, LaCrosse: {}, Lucerne: {} },
    Cadillac: {
      CTS: {},
      DTS: {},
      Escalade: {},
      "Escalade ESV": {},
      "Escalade EXT": {},
      SRX: {},
      STS: {},
      XLR: {},
    },
    Chevrolet: {
      Avalanche: {},
      Aveo: {},
      Aveo5: {},
      Cobalt: {},
      Colorado: {},
      Corvette: {},
      Equinox: {},
      "Express 1500": {},
      "Express 2500": {},
      "Express 3500": {},
      HHR: {},
      Impala: {},
      Malibu: {},
      "Silverado 1500": {},
      "Silverado 2500 HD": {},
      "Silverado 3500 HD": {},
      "Suburban 1500": {},
      "Suburban 2500": {},
      Tahoe: {},
      Trailblazer: {},
      Traverse: {},
      Uplander: {},
    },
    Chrysler: {
      "300": {},
      Aspen: {},
      "PT Cruiser": {},
      Sebring: {},
      "Town & Country": {},
    },
    Dodge: {
      Avenger: {},
      Caliber: {},
      Challenger: {},
      Charger: {},
      Dakota: {},
      Durango: {},
      "Grand Caravan": {},
      Journey: {},
      Nitro: {},
      "Ram 1500": {},
      "Ram 2500": {},
      "Ram 3500": {},
      "Sprinter 2500": {},
      "Sprinter 3500": {},
      Viper: {},
    },
    Ferrari: { "599 GTB": {}, "612 Scaglietti": {}, California: {}, F430: {} },
    Ford: {
      "Crown Victoria": {},
      "E-150": {},
      "E-250": {},
      "E-350 Super Duty": {},
      Edge: {},
      Escape: {},
      Expedition: {},
      Explorer: {},
      "Explorer Sport Trac": {},
      "F-150": {},
      "F-250 Super Duty": {},
      "F-350 Super Duty": {},
      Flex: {},
      Focus: {},
      Fusion: {},
      Mustang: {},
      Ranger: {},
      Taurus: {},
      "Taurus X": {},
    },
    Freightliner: { "Sprinter 2500": {}, "Sprinter 3500": {} },
    GMC: {
      Acadia: {},
      Canyon: {},
      Envoy: {},
      "Savana 1500": {},
      "Savana 2500": {},
      "Savana 3500": {},
      "Sierra 1500": {},
      "Sierra 2500 HD": {},
      "Sierra 3500 HD": {},
      Yukon: {},
      "Yukon XL 1500": {},
      "Yukon XL 2500": {},
    },
    Honda: {
      Accord: {},
      "CR-V": {},
      Civic: {},
      Element: {},
      Fit: {},
      Odyssey: {},
      Pilot: {},
      Ridgeline: {},
      S2000: {},
    },
    Hummer: { H2: {}, H3: {}, H3T: {} },
    Hyundai: {
      Accent: {},
      Azera: {},
      Elantra: {},
      Entourage: {},
      Genesis: {},
      "Santa Fe": {},
      Sonata: {},
      Tucson: {},
      Veracruz: {},
    },
    Infiniti: {
      EX35: {},
      FX35: {},
      FX50: {},
      G37: {},
      M35: {},
      M45: {},
      QX56: {},
    },
    Jaguar: {
      "Super V8": {},
      "Vanden Plas": {},
      XF: {},
      XJ8: {},
      XJR: {},
      XK: {},
      XKR: {},
    },
    Jeep: {
      Commander: {},
      Compass: {},
      "Grand Cherokee": {},
      Liberty: {},
      Patriot: {},
      Wrangler: {},
    },
    Kia: {
      Amanti: {},
      Borrego: {},
      Magentis: {},
      Optima: {},
      Rio: {},
      Rio5: {},
      Rondo: {},
      Sedona: {},
      Sorento: {},
      Spectra: {},
      Spectra5: {},
      Sportage: {},
    },
    Lamborghini: { Gallardo: {}, Murcielago: {} },
    "Land Rover": {
      LR2: {},
      LR3: {},
      "Range Rover": {},
      "Range Rover Sport": {},
    },
    Lexus: {
      ES350: {},
      GS350: {},
      GS450h: {},
      GS460: {},
      GX470: {},
      "IS F": {},
      IS250: {},
      IS350: {},
      LS460: {},
      LS600h: {},
      LX570: {},
      RX350: {},
      SC430: {},
    },
    Lincoln: { MKS: {}, MKX: {}, MKZ: {}, Navigator: {}, "Town Car": {} },
    Lotus: { Elise: {}, Exige: {} },
    Maserati: { GranTurismo: {}, Quattroporte: {} },
    Mazda: {
      "3": {},
      "5": {},
      "6": {},
      "3 Sport": {},
      B2300: {},
      B4000: {},
      "CX-7": {},
      "CX-9": {},
      "MX-5 Miata": {},
      "RX-8": {},
      Tribute: {},
    },
    "Mercedes-Benz": {
      B200: {},
      C230: {},
      C300: {},
      C350: {},
      "C63 AMG": {},
      CL550: {},
      CL600: {},
      "CL63 AMG": {},
      "CL65 AMG": {},
      CLK350: {},
      CLK550: {},
      "CLK63 AMG": {},
      CLS550: {},
      "CLS63 AMG": {},
      E300: {},
      E320: {},
      E350: {},
      E550: {},
      "E63 AMG": {},
      "G55 AMG": {},
      G550: {},
      GL320: {},
      GL450: {},
      GL550: {},
      ML320: {},
      ML350: {},
      ML550: {},
      "ML63 AMG": {},
      R320: {},
      R350: {},
      S450: {},
      S550: {},
      S600: {},
      "S63 AMG": {},
      "S65 AMG": {},
      SL550: {},
      SL600: {},
      "SL63 AMG": {},
      "SL65 AMG": {},
      SLK300: {},
      SLK350: {},
      "SLK55 AMG": {},
      "SLR McLaren": {},
    },
    Mercury: {
      "Grand Marquis": {},
      Mariner: {},
      Milan: {},
      Mountaineer: {},
      Sable: {},
    },
    Mini: { Cooper: {} },
    Mitsubishi: {
      Eclipse: {},
      Galant: {},
      Lancer: {},
      Outlander: {},
      Raider: {},
    },
    Nissan: {
      "350Z": {},
      "370Z": {},
      Altima: {},
      Armada: {},
      Cube: {},
      Frontier: {},
      "GT-R": {},
      Maxima: {},
      Murano: {},
      Pathfinder: {},
      Quest: {},
      Rogue: {},
      Sentra: {},
      Titan: {},
      Versa: {},
      Xterra: {},
    },
    Pontiac: {
      G3: {},
      "G3 Wave": {},
      G5: {},
      G6: {},
      G8: {},
      Montana: {},
      Solstice: {},
      Torrent: {},
      Vibe: {},
    },
    Porsche: { "911": {}, Boxster: {}, Cayenne: {}, Cayman: {} },
    "Rolls-Royce": { Phantom: {} },
    Saab: { "9-3": {}, "9-5": {}, "9-7x": {} },
    Saturn: { Astra: {}, Aura: {}, Outlook: {}, Sky: {}, Vue: {} },
    Scion: { tC: {}, xB: {}, xD: {} },
    Smart: { Fortwo: {} },
    Spyker: { C8: {} },
    Subaru: { Forester: {}, Impreza: {}, Legacy: {}, Outback: {}, Tribeca: {} },
    Suzuki: {
      Equator: {},
      "Grand Vitara": {},
      SX4: {},
      "Swift+": {},
      "XL-7": {},
    },
    Tesla: { Roadster: {} },
    Toyota: {
      "4Runner": {},
      Avalon: {},
      Camry: {},
      Corolla: {},
      "FJ Cruiser": {},
      Highlander: {},
      "Land Cruiser": {},
      Matrix: {},
      Prius: {},
      RAV4: {},
      Sequoia: {},
      Sienna: {},
      Tacoma: {},
      Tundra: {},
      Venza: {},
      Yaris: {},
    },
    Volkswagen: {
      Beetle: {},
      CC: {},
      Eos: {},
      GTI: {},
      "Golf City": {},
      Jetta: {},
      "Jetta City": {},
      Passat: {},
      "Passat CC": {},
      Rabbit: {},
      Routan: {},
      Tiguan: {},
      Touareg: {},
    },
    Volvo: {
      C30: {},
      C70: {},
      S40: {},
      S60: {},
      S80: {},
      V50: {},
      V70: {},
      XC70: {},
      XC90: {},
    },
  },
  "2010": {
    Acura: { CSX: {}, MDX: {}, RDX: {}, RL: {}, TL: {}, TSX: {}, ZDX: {} },
    "Aston Martin": {
      DB9: {},
      DBS: {},
      Rapide: {},
      "V12 Vantage": {},
      "V8 Vantage": {},
    },
    Audi: {
      A3: {},
      "A3 Quattro": {},
      A4: {},
      "A4 Quattro": {},
      A5: {},
      "A5 Quattro": {},
      A6: {},
      "A6 Quattro": {},
      "A8 Quattro": {},
      Q5: {},
      Q7: {},
      R8: {},
      S4: {},
      S5: {},
      S6: {},
      "TT Quattro": {},
      "TTS Quattro": {},
    },
    BMW: {
      "128i": {},
      "135i": {},
      "323i": {},
      "328i": {},
      "328i xDrive": {},
      "335d": {},
      "335i": {},
      "335i xDrive": {},
      "528i": {},
      "528i xDrive": {},
      "535i": {},
      "535i GT": {},
      "535i xDrive": {},
      "550i": {},
      "550i GT": {},
      "550i GT xDrive": {},
      "650i": {},
      "750Li": {},
      "750Li xDrive": {},
      "750i": {},
      "750i xDrive": {},
      "760Li": {},
      M3: {},
      M5: {},
      M6: {},
      X3: {},
      X5: {},
      X6: {},
      Z4: {},
    },
    Bentley: { Azure: {}, Continental: {} },
    Buick: { Allure: {}, Enclave: {}, LaCrosse: {}, Lucerne: {} },
    Cadillac: {
      CTS: {},
      DTS: {},
      Escalade: {},
      "Escalade ESV": {},
      "Escalade EXT": {},
      SRX: {},
      STS: {},
    },
    Chevrolet: {
      Avalanche: {},
      Aveo: {},
      Aveo5: {},
      Camaro: {},
      Cobalt: {},
      Colorado: {},
      Corvette: {},
      Equinox: {},
      "Express 1500": {},
      "Express 2500": {},
      "Express 3500": {},
      HHR: {},
      Impala: {},
      Malibu: {},
      "Silverado 1500": {},
      "Silverado 2500 HD": {},
      "Silverado 3500 HD": {},
      "Suburban 1500": {},
      "Suburban 2500": {},
      Tahoe: {},
      Traverse: {},
    },
    Chrysler: {
      "300": {},
      "PT Cruiser": {},
      Sebring: {},
      "Town & Country": {},
    },
    Dodge: {
      Avenger: {},
      Caliber: {},
      Challenger: {},
      Charger: {},
      Dakota: {},
      "Grand Caravan": {},
      Journey: {},
      Nitro: {},
      "Ram 1500": {},
      "Ram 2500": {},
      "Ram 3500": {},
      Viper: {},
    },
    Ferrari: {
      "458 Italia": {},
      "599 GTB": {},
      "612 Scaglietti": {},
      California: {},
    },
    Ford: {
      "Crown Victoria": {},
      "E-150": {},
      "E-250": {},
      "E-350 Super Duty": {},
      Edge: {},
      Escape: {},
      Expedition: {},
      Explorer: {},
      "Explorer Sport Trac": {},
      "F-150": {},
      "F-250 Super Duty": {},
      "F-350 Super Duty": {},
      Flex: {},
      Focus: {},
      Fusion: {},
      Mustang: {},
      Ranger: {},
      Taurus: {},
      "Transit Connect": {},
    },
    Freightliner: { "Sprinter 2500": {}, "Sprinter 3500": {} },
    GMC: {
      Acadia: {},
      Canyon: {},
      "Savana 1500": {},
      "Savana 2500": {},
      "Savana 3500": {},
      "Sierra 1500": {},
      "Sierra 2500 HD": {},
      "Sierra 3500 HD": {},
      Terrain: {},
      Yukon: {},
      "Yukon XL 1500": {},
      "Yukon XL 2500": {},
    },
    Honda: {
      Accord: {},
      "Accord Crosstour": {},
      "CR-V": {},
      Civic: {},
      Element: {},
      Fit: {},
      Insight: {},
      Odyssey: {},
      Pilot: {},
      Ridgeline: {},
    },
    Hummer: { H3: {}, H3T: {} },
    Hyundai: {
      Accent: {},
      Azera: {},
      Elantra: {},
      Entourage: {},
      Genesis: {},
      "Genesis Coupe": {},
      "Santa Fe": {},
      Sonata: {},
      Tucson: {},
      Veracruz: {},
    },
    Infiniti: {
      EX35: {},
      FX35: {},
      FX50: {},
      G37: {},
      M35: {},
      M45: {},
      QX56: {},
    },
    Jaguar: { XF: {}, XFR: {}, XJ: {}, XK: {}, XKR: {} },
    Jeep: {
      Commander: {},
      Compass: {},
      "Grand Cherokee": {},
      Liberty: {},
      Patriot: {},
      Wrangler: {},
    },
    Kia: {
      Borrego: {},
      Forte: {},
      "Forte Koup": {},
      Magentis: {},
      Optima: {},
      Rio: {},
      Rio5: {},
      Rondo: {},
      Sedona: {},
      Soul: {},
      Sportage: {},
    },
    Lamborghini: { Gallardo: {}, Murcielago: {} },
    "Land Rover": {
      LR2: {},
      LR4: {},
      "Range Rover": {},
      "Range Rover Sport": {},
    },
    Lexus: {
      ES350: {},
      GS350: {},
      GS450h: {},
      GS460: {},
      GX460: {},
      HS250h: {},
      "IS F": {},
      IS250: {},
      IS350: {},
      LS460: {},
      LS600h: {},
      LX570: {},
      RX350: {},
      RX450h: {},
      SC430: {},
    },
    Lincoln: {
      MKS: {},
      MKT: {},
      MKX: {},
      MKZ: {},
      Navigator: {},
      "Town Car": {},
    },
    Lotus: { Elise: {}, Evora: {}, Exige: {} },
    Maserati: { GranTurismo: {}, Quattroporte: {} },
    Mazda: {
      "3": {},
      "5": {},
      "6": {},
      "3 Sport": {},
      B2300: {},
      B4000: {},
      "CX-7": {},
      "CX-9": {},
      "MX-5 Miata": {},
      "RX-8": {},
      Tribute: {},
    },
    "Mercedes-Benz": {
      B200: {},
      C250: {},
      C300: {},
      C350: {},
      "C63 AMG": {},
      CL550: {},
      CL600: {},
      "CL63 AMG": {},
      "CL65 AMG": {},
      CLS550: {},
      "CLS63 AMG": {},
      E350: {},
      E550: {},
      "E63 AMG": {},
      "G55 AMG": {},
      G550: {},
      GL350: {},
      GL450: {},
      GL550: {},
      GLK350: {},
      ML350: {},
      ML450: {},
      ML550: {},
      "ML63 AMG": {},
      R350: {},
      S400: {},
      S450: {},
      S550: {},
      S600: {},
      "S63 AMG": {},
      "S65 AMG": {},
      SL550: {},
      SL600: {},
      "SL63 AMG": {},
      "SL65 AMG": {},
      SLK300: {},
      SLK350: {},
      "SLK55 AMG": {},
      "Sprinter 2500": {},
      "Sprinter 3500": {},
    },
    Mercury: { "Grand Marquis": {}, Mariner: {}, Milan: {}, Mountaineer: {} },
    Mini: { Cooper: {} },
    Mitsubishi: {
      Eclipse: {},
      Endeavor: {},
      Galant: {},
      Lancer: {},
      Outlander: {},
    },
    Nissan: {
      "370Z": {},
      Altima: {},
      Armada: {},
      Cube: {},
      Frontier: {},
      "GT-R": {},
      Maxima: {},
      Murano: {},
      Pathfinder: {},
      Rogue: {},
      Sentra: {},
      Titan: {},
      Versa: {},
      Xterra: {},
    },
    Pontiac: { G3: {}, G5: {}, G6: {}, Vibe: {} },
    Porsche: { "911": {}, Boxster: {}, Cayenne: {}, Cayman: {}, Panamera: {} },
    "Rolls-Royce": { Ghost: {}, Phantom: {} },
    Saab: { "9-3": {}, "9-3X": {}, "9-5": {} },
    Saturn: { Outlook: {}, Sky: {}, Vue: {} },
    Scion: { tC: {}, xB: {}, xD: {} },
    Smart: { Fortwo: {} },
    Spyker: { C8: {} },
    Subaru: { Forester: {}, Impreza: {}, Legacy: {}, Outback: {}, Tribeca: {} },
    Suzuki: {
      Equator: {},
      "Grand Vitara": {},
      Kizashi: {},
      SX4: {},
      "Swift+": {},
    },
    Tesla: { Roadster: {} },
    Toyota: {
      "4Runner": {},
      Avalon: {},
      Camry: {},
      Corolla: {},
      "FJ Cruiser": {},
      Highlander: {},
      "Land Cruiser": {},
      Matrix: {},
      Prius: {},
      RAV4: {},
      Sequoia: {},
      Sienna: {},
      Tacoma: {},
      Tundra: {},
      Venza: {},
      Yaris: {},
    },
    Volkswagen: {
      Beetle: {},
      CC: {},
      Eos: {},
      GTI: {},
      Golf: {},
      "Golf City": {},
      Jetta: {},
      Passat: {},
      "Passat CC": {},
      Routan: {},
      Tiguan: {},
      Touareg: {},
    },
    Volvo: {
      C30: {},
      C70: {},
      S40: {},
      S80: {},
      V50: {},
      V70: {},
      XC60: {},
      XC70: {},
      XC90: {},
    },
  },
  "2011": {
    Acura: { CSX: {}, MDX: {}, RDX: {}, RL: {}, TL: {}, TSX: {}, ZDX: {} },
    "Aston Martin": {
      DB9: {},
      DBS: {},
      "One-77": {},
      Rapide: {},
      "V12 Vantage": {},
      "V8 Vantage": {},
    },
    Audi: {
      A3: {},
      "A3 Quattro": {},
      A4: {},
      "A4 Quattro": {},
      A5: {},
      "A5 Quattro": {},
      A6: {},
      "A6 Quattro": {},
      "A8 Quattro": {},
      Q5: {},
      Q7: {},
      R8: {},
      S4: {},
      S5: {},
      S6: {},
      "TT Quattro": {},
      "TTS Quattro": {},
    },
    BMW: {
      "1 Series M": {},
      "128i": {},
      "135i": {},
      "323i": {},
      "328i": {},
      "328i xDrive": {},
      "335d": {},
      "335i": {},
      "335i xDrive": {},
      "335is": {},
      "528i": {},
      "535i": {},
      "535i GT": {},
      "535i GT xDrive": {},
      "535i xDrive": {},
      "550i": {},
      "550i GT": {},
      "550i GT xDrive": {},
      "550i xDrive": {},
      "740Li": {},
      "740i": {},
      "750Li": {},
      "750Li xDrive": {},
      "750i": {},
      "750i xDrive": {},
      "760Li": {},
      "Alpina B7": {},
      "Alpina B7 xDrive": {},
      "Alpina B7L": {},
      "Alpina B7L xDrive": {},
      M3: {},
      X3: {},
      X5: {},
      X6: {},
      Z4: {},
    },
    Bentley: { Continental: {}, Mulsanne: {} },
    Buick: { Enclave: {}, LaCrosse: {}, Lucerne: {}, Regal: {} },
    Cadillac: {
      CTS: {},
      DTS: {},
      Escalade: {},
      "Escalade ESV": {},
      "Escalade EXT": {},
      SRX: {},
      STS: {},
    },
    Chevrolet: {
      Avalanche: {},
      Aveo: {},
      Aveo5: {},
      Camaro: {},
      Caprice: {},
      Colorado: {},
      Corvette: {},
      Cruze: {},
      Equinox: {},
      "Express 1500": {},
      "Express 2500": {},
      "Express 3500": {},
      HHR: {},
      Impala: {},
      Malibu: {},
      "Silverado 1500": {},
      "Silverado 2500 HD": {},
      "Silverado 3500 HD": {},
      "Suburban 1500": {},
      "Suburban 2500": {},
      Tahoe: {},
      Traverse: {},
      Volt: {},
    },
    Chrysler: { "200": {}, "300": {}, "Town & Country": {} },
    Dodge: {
      Avenger: {},
      Caliber: {},
      Challenger: {},
      Charger: {},
      Durango: {},
      "Grand Caravan": {},
      Journey: {},
      Nitro: {},
    },
    Ferrari: { "458 Italia": {}, "599 GTO": {}, California: {} },
    Ford: {
      "Crown Victoria": {},
      "E-150": {},
      "E-250": {},
      "E-350 Super Duty": {},
      Edge: {},
      Escape: {},
      Expedition: {},
      Explorer: {},
      "F-150": {},
      "F-250 Super Duty": {},
      "F-350 Super Duty": {},
      Fiesta: {},
      Flex: {},
      Focus: {},
      Fusion: {},
      Mustang: {},
      Ranger: {},
      Taurus: {},
      "Transit Connect": {},
    },
    Freightliner: { "Sprinter 2500": {}, "Sprinter 3500": {} },
    GMC: {
      Acadia: {},
      Canyon: {},
      "Savana 1500": {},
      "Savana 2500": {},
      "Savana 3500": {},
      "Sierra 1500": {},
      "Sierra 2500 HD": {},
      "Sierra 3500 HD": {},
      Terrain: {},
      Yukon: {},
      "Yukon XL 1500": {},
      "Yukon XL 2500": {},
    },
    Honda: {
      Accord: {},
      "Accord Crosstour": {},
      "CR-V": {},
      "CR-Z": {},
      Civic: {},
      Element: {},
      Fit: {},
      Insight: {},
      Odyssey: {},
      Pilot: {},
      Ridgeline: {},
    },
    Hyundai: {
      Accent: {},
      Azera: {},
      Elantra: {},
      Equus: {},
      Genesis: {},
      "Genesis Coupe": {},
      "Santa Fe": {},
      Sonata: {},
      Tucson: {},
      Veracruz: {},
    },
    Infiniti: {
      EX35: {},
      FX35: {},
      FX50: {},
      G25: {},
      G37: {},
      M37: {},
      M56: {},
      QX56: {},
    },
    Jaguar: { XF: {}, XFR: {}, XJ: {}, XK: {}, XKR: {} },
    Jeep: {
      Compass: {},
      "Grand Cherokee": {},
      Liberty: {},
      Patriot: {},
      Wrangler: {},
    },
    Kia: {
      Borrego: {},
      Forte: {},
      "Forte Koup": {},
      Optima: {},
      Rio: {},
      Rio5: {},
      Rondo: {},
      Sedona: {},
      Sorento: {},
      Soul: {},
      Sportage: {},
    },
    Lamborghini: { Gallardo: {} },
    "Land Rover": {
      LR2: {},
      LR4: {},
      "Range Rover": {},
      "Range Rover Sport": {},
    },
    Lexus: {
      CT200h: {},
      ES350: {},
      GS350: {},
      GS450h: {},
      GS460: {},
      GX460: {},
      HS250h: {},
      "IS F": {},
      IS250: {},
      IS350: {},
      LS460: {},
      LS600h: {},
      LX570: {},
      RX350: {},
      RX450h: {},
    },
    Lincoln: {
      MKS: {},
      MKT: {},
      MKX: {},
      MKZ: {},
      Navigator: {},
      "Town Car": {},
    },
    Lotus: { Elise: {}, Evora: {}, Exige: {} },
    Maserati: { GranTurismo: {}, Quattroporte: {} },
    Mazda: {
      "2": {},
      "3": {},
      "6": {},
      "3 Sport": {},
      "CX-7": {},
      "CX-9": {},
      "MX-5 Miata": {},
      "RX-8": {},
      Tribute: {},
    },
    "Mercedes-Benz": {
      B200: {},
      C250: {},
      C300: {},
      C350: {},
      "C63 AMG": {},
      CL550: {},
      CL600: {},
      "CL63 AMG": {},
      "CL65 AMG": {},
      CLS550: {},
      "CLS63 AMG": {},
      E350: {},
      E550: {},
      "E63 AMG": {},
      "G55 AMG": {},
      G550: {},
      GL350: {},
      GL450: {},
      GL550: {},
      GLK350: {},
      ML350: {},
      ML450: {},
      ML550: {},
      "ML63 AMG": {},
      R350: {},
      S400: {},
      S450: {},
      S550: {},
      S600: {},
      "S63 AMG": {},
      "S65 AMG": {},
      SL550: {},
      SL600: {},
      "SL63 AMG": {},
      "SL65 AMG": {},
      SLK300: {},
      SLK350: {},
      "SLK55 AMG": {},
      "SLS AMG": {},
      "Sprinter 2500": {},
      "Sprinter 3500": {},
    },
    Mercury: { "Grand Marquis": {}, Mariner: {}, Milan: {} },
    Mini: { Cooper: {}, "Cooper Countryman": {} },
    Mitsubishi: {
      Eclipse: {},
      Endeavor: {},
      Galant: {},
      Lancer: {},
      Outlander: {},
      "Outlander Sport": {},
      RVR: {},
    },
    Nissan: {
      "370Z": {},
      Altima: {},
      Armada: {},
      Cube: {},
      Frontier: {},
      "GT-R": {},
      Juke: {},
      Leaf: {},
      Maxima: {},
      Murano: {},
      Pathfinder: {},
      Quest: {},
      Rogue: {},
      Sentra: {},
      Titan: {},
      Versa: {},
      Xterra: {},
    },
    Porsche: { "911": {}, Boxster: {}, Cayenne: {}, Cayman: {}, Panamera: {} },
    Ram: { "1500": {}, "2500": {}, "3500": {}, Dakota: {} },
    "Rolls-Royce": { Ghost: {}, Phantom: {} },
    Saab: { "9-3": {}, "9-3X": {}, "9-4X": {}, "9-5": {} },
    Scion: { tC: {}, xB: {}, xD: {} },
    Smart: { Fortwo: {} },
    Spyker: { C8: {} },
    Subaru: { Forester: {}, Impreza: {}, Legacy: {}, Outback: {}, Tribeca: {} },
    Suzuki: { Equator: {}, "Grand Vitara": {}, Kizashi: {}, SX4: {} },
    Tesla: { Roadster: {} },
    Think: { City: {} },
    Toyota: {
      "4Runner": {},
      Avalon: {},
      Camry: {},
      Corolla: {},
      "FJ Cruiser": {},
      Highlander: {},
      "Land Cruiser": {},
      Matrix: {},
      Prius: {},
      RAV4: {},
      Sequoia: {},
      Sienna: {},
      Tacoma: {},
      Tundra: {},
      Venza: {},
      Yaris: {},
    },
    VPG: { "MV-1": {} },
    Volkswagen: {
      CC: {},
      Eos: {},
      GTI: {},
      Golf: {},
      Jetta: {},
      Routan: {},
      Tiguan: {},
      Touareg: {},
    },
    Volvo: {
      C30: {},
      C70: {},
      S40: {},
      S60: {},
      S80: {},
      V50: {},
      XC60: {},
      XC70: {},
      XC90: {},
    },
  },
  "2012": {
    Acura: { MDX: {}, RDX: {}, RL: {}, TL: {}, TSX: {}, ZDX: {} },
    "Aston Martin": {
      DB9: {},
      DBS: {},
      Rapide: {},
      "V12 Vantage": {},
      "V8 Vantage": {},
      Virage: {},
    },
    Audi: {
      A3: {},
      "A3 Quattro": {},
      A4: {},
      "A4 Quattro": {},
      A5: {},
      "A5 Quattro": {},
      A6: {},
      "A6 Quattro": {},
      "A7 Quattro": {},
      "A8 Quattro": {},
      Q5: {},
      Q7: {},
      R8: {},
      S4: {},
      S5: {},
      "TT Quattro": {},
      "TT RS Quattro": {},
      "TTS Quattro": {},
    },
    BMW: {
      "128i": {},
      "135i": {},
      "320i": {},
      "328i": {},
      "328i xDrive": {},
      "335i": {},
      "335i xDrive": {},
      "335is": {},
      "528i": {},
      "528i xDrive": {},
      "535i": {},
      "535i GT": {},
      "535i GT xDrive": {},
      "535i xDrive": {},
      "550i": {},
      "550i GT": {},
      "550i GT xDrive": {},
      "550i xDrive": {},
      "640i": {},
      "650i": {},
      "650i xDrive": {},
      "740Li": {},
      "740i": {},
      "750Li": {},
      "750Li xDrive": {},
      "750i": {},
      "750i xDrive": {},
      "760Li": {},
      "ActiveHybrid 5": {},
      "Alpina B7": {},
      "Alpina B7 xDrive": {},
      "Alpina B7L": {},
      "Alpina B7L xDrive": {},
      M3: {},
      M5: {},
      M6: {},
      X1: {},
      X3: {},
      X5: {},
      X6: {},
      Z4: {},
    },
    Bentley: { Continental: {}, Mulsanne: {} },
    Buick: { Enclave: {}, LaCrosse: {}, Regal: {}, Verano: {} },
    Cadillac: {
      CTS: {},
      Escalade: {},
      "Escalade ESV": {},
      "Escalade EXT": {},
      SRX: {},
    },
    Chevrolet: {
      Avalanche: {},
      Camaro: {},
      Caprice: {},
      "Captiva Sport": {},
      Colorado: {},
      Corvette: {},
      Cruze: {},
      Equinox: {},
      "Express 1500": {},
      "Express 2500": {},
      "Express 3500": {},
      Impala: {},
      Malibu: {},
      Orlando: {},
      "Silverado 1500": {},
      "Silverado 2500 HD": {},
      "Silverado 3500 HD": {},
      Sonic: {},
      "Suburban 1500": {},
      "Suburban 2500": {},
      Tahoe: {},
      Traverse: {},
      Volt: {},
    },
    Chrysler: { "200": {}, "300": {}, "Town & Country": {} },
    Dodge: {
      Avenger: {},
      Caliber: {},
      Challenger: {},
      Charger: {},
      Durango: {},
      "Grand Caravan": {},
      Journey: {},
    },
    Ferrari: { "458 Italia": {}, "458 Spider": {}, California: {}, FF: {} },
    Fiat: { "500": {} },
    Ford: {
      "E-150": {},
      "E-250": {},
      "E-350 Super Duty": {},
      Edge: {},
      Escape: {},
      Expedition: {},
      Explorer: {},
      "F-150": {},
      "F-250 Super Duty": {},
      "F-350 Super Duty": {},
      Fiesta: {},
      Flex: {},
      Focus: {},
      Fusion: {},
      Mustang: {},
      Taurus: {},
      "Transit Connect": {},
    },
    Freightliner: { "Sprinter 2500": {}, "Sprinter 3500": {} },
    GMC: {
      Acadia: {},
      Canyon: {},
      "Savana 1500": {},
      "Savana 2500": {},
      "Savana 3500": {},
      "Sierra 1500": {},
      "Sierra 2500 HD": {},
      "Sierra 3500 HD": {},
      Terrain: {},
      Yukon: {},
      "Yukon XL 1500": {},
      "Yukon XL 2500": {},
    },
    Honda: {
      Accord: {},
      "CR-V": {},
      "CR-Z": {},
      Civic: {},
      Crosstour: {},
      Fit: {},
      Insight: {},
      Odyssey: {},
      Pilot: {},
      Ridgeline: {},
    },
    Hyundai: {
      Accent: {},
      Azera: {},
      Elantra: {},
      Equus: {},
      Genesis: {},
      "Genesis Coupe": {},
      "Santa Fe": {},
      Sonata: {},
      Tucson: {},
      Veloster: {},
      Veracruz: {},
    },
    Infiniti: {
      EX35: {},
      FX35: {},
      FX50: {},
      G25: {},
      G37: {},
      M35h: {},
      M37: {},
      M56: {},
      QX56: {},
    },
    Jaguar: { XF: {}, XFR: {}, XJ: {}, XK: {}, XKR: {}, "XKR-S": {} },
    Jeep: {
      Compass: {},
      "Grand Cherokee": {},
      Liberty: {},
      Patriot: {},
      Wrangler: {},
    },
    Kia: {
      Forte: {},
      "Forte Koup": {},
      Forte5: {},
      Optima: {},
      Rio: {},
      Rondo: {},
      Sedona: {},
      Sorento: {},
      Soul: {},
      Sportage: {},
    },
    Lamborghini: { Aventador: {}, Gallardo: {} },
    "Land Rover": {
      LR2: {},
      LR4: {},
      "Range Rover": {},
      "Range Rover Evoque": {},
      "Range Rover Sport": {},
    },
    Lexus: {
      CT200h: {},
      ES350: {},
      GX460: {},
      HS250h: {},
      "IS F": {},
      IS250: {},
      IS350: {},
      LFA: {},
      LS460: {},
      LS600h: {},
      RX350: {},
      RX450h: {},
    },
    Lincoln: { MKS: {}, MKT: {}, MKX: {}, MKZ: {}, Navigator: {} },
    Lotus: { Evora: {} },
    Maserati: { GranTurismo: {}, Quattroporte: {} },
    Mazda: {
      "2": {},
      "3": {},
      "5": {},
      "6": {},
      "3 Sport": {},
      "CX-7": {},
      "CX-9": {},
      "MX-5 Miata": {},
    },
    "Mercedes-Benz": {
      C250: {},
      C300: {},
      C350: {},
      "C63 AMG": {},
      CL550: {},
      CL600: {},
      "CL63 AMG": {},
      "CL65 AMG": {},
      CLS550: {},
      "CLS63 AMG": {},
      E300: {},
      E350: {},
      E550: {},
      "E63 AMG": {},
      G550: {},
      GL350: {},
      GL450: {},
      GL550: {},
      GLK350: {},
      ML350: {},
      ML550: {},
      "ML63 AMG": {},
      R350: {},
      S350: {},
      S400: {},
      S550: {},
      S600: {},
      "S63 AMG": {},
      "S65 AMG": {},
      SL550: {},
      "SL63 AMG": {},
      SLK250: {},
      SLK350: {},
      "SLK55 AMG": {},
      "SLS AMG": {},
      "Sprinter 2500": {},
      "Sprinter 3500": {},
    },
    Mini: { Cooper: {}, "Cooper Countryman": {} },
    Mitsubishi: {
      Eclipse: {},
      Galant: {},
      Lancer: {},
      Outlander: {},
      "Outlander Sport": {},
      RVR: {},
      "i-MiEV": {},
    },
    Nissan: {
      "370Z": {},
      Altima: {},
      Armada: {},
      Cube: {},
      Frontier: {},
      "GT-R": {},
      Juke: {},
      Leaf: {},
      Maxima: {},
      Murano: {},
      NV1500: {},
      NV2500: {},
      NV3500: {},
      Pathfinder: {},
      Quest: {},
      Rogue: {},
      Sentra: {},
      Titan: {},
      Versa: {},
      Xterra: {},
    },
    Porsche: { "911": {}, Boxster: {}, Cayenne: {}, Cayman: {}, Panamera: {} },
    Ram: { "1500": {}, "2500": {}, "3500": {}, "C/V": {} },
    "Rolls-Royce": { Ghost: {}, Phantom: {} },
    Saab: { "9-3 Griffin": {} },
    Scion: { iQ: {}, tC: {}, xB: {}, xD: {} },
    Smart: { Fortwo: {} },
    Spyker: { C8: {} },
    Subaru: { Forester: {}, Impreza: {}, Legacy: {}, Outback: {}, Tribeca: {} },
    Suzuki: {
      Equator: {},
      "Grand Vitara": {},
      Kizashi: {},
      SX4: {},
      "SX4 Crossover": {},
    },
    Tesla: { S: {} },
    Toyota: {
      "4Runner": {},
      Avalon: {},
      Camry: {},
      Corolla: {},
      "FJ Cruiser": {},
      Highlander: {},
      Matrix: {},
      Prius: {},
      "Prius C": {},
      "Prius Plug-In": {},
      "Prius V": {},
      RAV4: {},
      Sequoia: {},
      Sienna: {},
      Tacoma: {},
      Tundra: {},
      Venza: {},
      Yaris: {},
    },
    VPG: { "MV-1": {} },
    Volkswagen: {
      Beetle: {},
      CC: {},
      Eos: {},
      GTI: {},
      Golf: {},
      "Golf R": {},
      Jetta: {},
      Passat: {},
      Routan: {},
      Tiguan: {},
      Touareg: {},
    },
    Volvo: { C30: {}, C70: {}, S60: {}, S80: {}, XC60: {}, XC70: {}, XC90: {} },
  },
  "2013": {
    Acura: { ILX: {}, MDX: {}, RDX: {}, TL: {}, TSX: {}, ZDX: {} },
    "Aston Martin": {
      DB9: {},
      Rapide: {},
      "V12 Vantage": {},
      "V8 Vantage": {},
    },
    Audi: {
      A3: {},
      "A3 Quattro": {},
      A4: {},
      "A4 Quattro": {},
      "A4 allroad": {},
      A5: {},
      "A5 Quattro": {},
      A6: {},
      "A6 Quattro": {},
      "A7 Quattro": {},
      "A8 Quattro": {},
      Q5: {},
      Q7: {},
      RS5: {},
      S4: {},
      S5: {},
      S6: {},
      S7: {},
      S8: {},
      "TT Quattro": {},
      "TT RS Quattro": {},
      "TTS Quattro": {},
      allroad: {},
    },
    BMW: {
      "128i": {},
      "135i": {},
      "135is": {},
      "320i": {},
      "320i xDrive": {},
      "328i": {},
      "328i xDrive": {},
      "335i": {},
      "335i xDrive": {},
      "335is": {},
      "528i": {},
      "528i xDrive": {},
      "535i": {},
      "535i GT": {},
      "535i GT xDrive": {},
      "535i xDrive": {},
      "550i": {},
      "550i GT": {},
      "550i GT xDrive": {},
      "550i xDrive": {},
      "640i": {},
      "640i Gran Coupe": {},
      "650i": {},
      "650i Gran Coupe": {},
      "650i xDrive": {},
      "650i xDrive Gran Coupe": {},
      "740Li": {},
      "740Li xDrive": {},
      "740i": {},
      "750Li": {},
      "750Li xDrive": {},
      "750i": {},
      "750i xDrive": {},
      "760Li": {},
      "ActiveHybrid 3": {},
      "ActiveHybrid 5": {},
      "ActiveHybrid 7": {},
      "Alpina B7": {},
      "Alpina B7 xDrive": {},
      "Alpina B7L": {},
      "Alpina B7L xDrive": {},
      M3: {},
      M5: {},
      M6: {},
      X1: {},
      X3: {},
      X5: {},
      X6: {},
      Z4: {},
    },
    Bentley: { Continental: {}, Mulsanne: {} },
    Buick: { Enclave: {}, Encore: {}, LaCrosse: {}, Regal: {}, Verano: {} },
    Cadillac: {
      ATS: {},
      CTS: {},
      Escalade: {},
      "Escalade ESV": {},
      "Escalade EXT": {},
      SRX: {},
      XTS: {},
    },
    Chevrolet: {
      Avalanche: {},
      Camaro: {},
      Caprice: {},
      "Captiva Sport": {},
      Corvette: {},
      Cruze: {},
      Equinox: {},
      "Express 1500": {},
      "Express 2500": {},
      "Express 3500": {},
      Impala: {},
      Malibu: {},
      Orlando: {},
      "Silverado 1500": {},
      "Silverado 2500 HD": {},
      "Silverado 3500 HD": {},
      Sonic: {},
      Spark: {},
      "Suburban 1500": {},
      "Suburban 2500": {},
      Tahoe: {},
      Traverse: {},
      Trax: {},
      Volt: {},
    },
    Chrysler: { "200": {}, "300": {}, "Town & Country": {} },
    Dodge: {
      Avenger: {},
      Challenger: {},
      Charger: {},
      Dart: {},
      Durango: {},
      "Grand Caravan": {},
      Journey: {},
    },
    Ferrari: {
      "458 Italia": {},
      "458 Spider": {},
      California: {},
      "F12 Berlinetta": {},
      FF: {},
    },
    Fiat: { "500": {} },
    Ford: {
      "C-Max": {},
      "E-150": {},
      "E-250": {},
      "E-350 Super Duty": {},
      Edge: {},
      Escape: {},
      Expedition: {},
      Explorer: {},
      "F-150": {},
      "F-250 Super Duty": {},
      "F-350 Super Duty": {},
      Fiesta: {},
      Flex: {},
      Focus: {},
      Fusion: {},
      Mustang: {},
      "Police Interceptor Sedan": {},
      "Police Interceptor Utility": {},
      Taurus: {},
      "Transit Connect": {},
    },
    Freightliner: { "Sprinter 2500": {}, "Sprinter 3500": {} },
    GMC: {
      Acadia: {},
      "Savana 1500": {},
      "Savana 2500": {},
      "Savana 3500": {},
      "Sierra 1500": {},
      "Sierra 2500 HD": {},
      "Sierra 3500 HD": {},
      Terrain: {},
      Yukon: {},
      "Yukon XL 1500": {},
      "Yukon XL 2500": {},
    },
    Honda: {
      Accord: {},
      "CR-V": {},
      "CR-Z": {},
      Civic: {},
      Crosstour: {},
      Fit: {},
      Insight: {},
      Odyssey: {},
      Pilot: {},
      Ridgeline: {},
    },
    Hyundai: {
      Accent: {},
      Azera: {},
      Elantra: {},
      "Elantra Coupe": {},
      "Elantra GT": {},
      Equus: {},
      Genesis: {},
      "Genesis Coupe": {},
      "Santa Fe": {},
      "Santa Fe Sport": {},
      "Santa Fe XL": {},
      Sonata: {},
      Tucson: {},
      Veloster: {},
    },
    Infiniti: {
      EX37: {},
      FX37: {},
      FX50: {},
      G37: {},
      JX35: {},
      M35h: {},
      M37: {},
      M56: {},
      QX56: {},
    },
    Jaguar: {
      XF: {},
      XFR: {},
      "XFR-S": {},
      XJ: {},
      XK: {},
      XKR: {},
      "XKR-S": {},
    },
    Jeep: { Compass: {}, "Grand Cherokee": {}, Patriot: {}, Wrangler: {} },
    Kia: {
      Forte: {},
      "Forte Koup": {},
      Forte5: {},
      Optima: {},
      Rio: {},
      Sorento: {},
      Soul: {},
      Sportage: {},
    },
    Lamborghini: { Aventador: {}, Gallardo: {} },
    "Land Rover": {
      LR2: {},
      LR4: {},
      "Range Rover": {},
      "Range Rover Evoque": {},
      "Range Rover Sport": {},
    },
    Lexus: {
      CT200h: {},
      ES300h: {},
      ES350: {},
      GS350: {},
      GS450h: {},
      GX460: {},
      "IS F": {},
      IS250: {},
      IS350: {},
      LS460: {},
      LS600h: {},
      LX570: {},
      RX350: {},
      RX450h: {},
    },
    Lincoln: { MKS: {}, MKT: {}, MKX: {}, MKZ: {}, Navigator: {} },
    Lotus: { Evora: {} },
    Maserati: { GranTurismo: {}, Quattroporte: {} },
    Mazda: {
      "2": {},
      "3": {},
      "5": {},
      "6": {},
      "3 Sport": {},
      "CX-5": {},
      "CX-9": {},
      "MX-5 Miata": {},
    },
    "Mercedes-Benz": {
      B250: {},
      C250: {},
      C300: {},
      C350: {},
      "C63 AMG": {},
      CL550: {},
      CL600: {},
      "CL63 AMG": {},
      "CL65 AMG": {},
      CLS550: {},
      "CLS63 AMG": {},
      E300: {},
      E350: {},
      E400: {},
      E550: {},
      "E63 AMG": {},
      G550: {},
      "G63 AMG": {},
      GL350: {},
      GL450: {},
      GL550: {},
      "GL63 AMG": {},
      GLK250: {},
      GLK350: {},
      ML350: {},
      ML550: {},
      "ML63 AMG": {},
      R350: {},
      S350: {},
      S400: {},
      S550: {},
      S600: {},
      "S63 AMG": {},
      "S65 AMG": {},
      SL550: {},
      "SL63 AMG": {},
      "SL65 AMG": {},
      SLK250: {},
      SLK350: {},
      "SLK55 AMG": {},
      "SLS AMG": {},
      "Sprinter 2500": {},
      "Sprinter 3500": {},
    },
    Mini: { Cooper: {}, "Cooper Countryman": {}, "Cooper Paceman": {} },
    Mitsubishi: { Lancer: {}, Outlander: {}, "Outlander Sport": {}, RVR: {} },
    Nissan: {
      "370Z": {},
      Altima: {},
      Armada: {},
      Cube: {},
      Frontier: {},
      "GT-R": {},
      Juke: {},
      Leaf: {},
      Maxima: {},
      Murano: {},
      NV1500: {},
      NV200: {},
      NV2500: {},
      NV3500: {},
      Pathfinder: {},
      Quest: {},
      Rogue: {},
      Sentra: {},
      Titan: {},
      Versa: {},
      Xterra: {},
    },
    Porsche: { "911": {}, Boxster: {}, Cayenne: {}, Panamera: {} },
    Ram: { "1500": {}, "2500": {}, "3500": {}, "C/V": {} },
    "Rolls-Royce": { Ghost: {}, Phantom: {} },
    SRT: { Viper: {} },
    Scion: { "FR-S": {}, iQ: {}, tC: {}, xB: {}, xD: {} },
    Smart: { Fortwo: {} },
    Spyker: { C8: {} },
    Subaru: {
      BRZ: {},
      Forester: {},
      Impreza: {},
      Legacy: {},
      Outback: {},
      Tribeca: {},
      WRX: {},
      "WRX STI": {},
      "XV Crosstrek": {},
    },
    Suzuki: { "Grand Vitara": {}, Kizashi: {}, SX4: {} },
    Tesla: { S: {} },
    Toyota: {
      "4Runner": {},
      Avalon: {},
      Camry: {},
      Corolla: {},
      "FJ Cruiser": {},
      Highlander: {},
      "Land Cruiser": {},
      Matrix: {},
      Prius: {},
      "Prius C": {},
      "Prius Plug-In": {},
      "Prius V": {},
      RAV4: {},
      Sequoia: {},
      Sienna: {},
      Tacoma: {},
      Tundra: {},
      Venza: {},
      Yaris: {},
    },
    Volkswagen: {
      Beetle: {},
      CC: {},
      Eos: {},
      GTI: {},
      Golf: {},
      "Golf R": {},
      Jetta: {},
      Passat: {},
      Routan: {},
      Tiguan: {},
      Touareg: {},
    },
    Volvo: { C30: {}, C70: {}, S60: {}, S80: {}, XC60: {}, XC70: {}, XC90: {} },
  },
  "2014": {
    Acura: { ILX: {}, MDX: {}, RDX: {}, RLX: {}, TL: {}, TSX: {} },
    "Aston Martin": { DB9: {}, Rapide: {}, "V8 Vantage": {}, Vanquish: {} },
    Audi: {
      A4: {},
      "A4 Quattro": {},
      "A4 allroad": {},
      A5: {},
      "A5 Quattro": {},
      A6: {},
      "A6 Quattro": {},
      "A7 Quattro": {},
      "A8 Quattro": {},
      Q5: {},
      Q7: {},
      R8: {},
      RS5: {},
      RS7: {},
      S4: {},
      S5: {},
      S6: {},
      S7: {},
      S8: {},
      SQ5: {},
      "TT Quattro": {},
      "TTS Quattro": {},
      allroad: {},
    },
    BMW: {
      "228i": {},
      "320i": {},
      "320i xDrive": {},
      "328d": {},
      "328d xDrive": {},
      "328i": {},
      "328i GT xDrive": {},
      "328i xDrive": {},
      "335i": {},
      "335i GT xDrive": {},
      "335i xDrive": {},
      "428i": {},
      "428i xDrive": {},
      "435i": {},
      "435i xDrive": {},
      "528i": {},
      "528i xDrive": {},
      "535d": {},
      "535d xDrive": {},
      "535i": {},
      "535i GT": {},
      "535i GT xDrive": {},
      "535i xDrive": {},
      "550i": {},
      "550i GT": {},
      "550i GT xDrive": {},
      "550i xDrive": {},
      "640i": {},
      "640i Gran Coupe": {},
      "640i xDrive": {},
      "640i xDrive Gran Coupe": {},
      "650i": {},
      "650i Gran Coupe": {},
      "650i xDrive": {},
      "650i xDrive Gran Coupe": {},
      "740Li": {},
      "740Li xDrive": {},
      "740i": {},
      "750Li": {},
      "750Li xDrive": {},
      "750i": {},
      "750i xDrive": {},
      "760Li": {},
      "ActiveHybrid 3": {},
      "ActiveHybrid 5": {},
      "ActiveHybrid 7": {},
      "Alpina B7": {},
      "Alpina B7 xDrive": {},
      "Alpina B7L": {},
      "Alpina B7L xDrive": {},
      M235i: {},
      M5: {},
      M6: {},
      "M6 Gran Coupe": {},
      X1: {},
      X3: {},
      X5: {},
      X6: {},
      Z4: {},
      i3: {},
      i8: {},
    },
    Bentley: { Continental: {}, "Flying Spur": {}, Mulsanne: {} },
    Buick: { Enclave: {}, Encore: {}, LaCrosse: {}, Regal: {}, Verano: {} },
    Cadillac: {
      ATS: {},
      CTS: {},
      ELR: {},
      Escalade: {},
      "Escalade ESV": {},
      SRX: {},
      XTS: {},
    },
    Chevrolet: {
      Camaro: {},
      Caprice: {},
      "Captiva Sport": {},
      Corvette: {},
      Cruze: {},
      Equinox: {},
      "Express 1500": {},
      "Express 2500": {},
      "Express 3500": {},
      Impala: {},
      "Impala Limited": {},
      Malibu: {},
      Orlando: {},
      SS: {},
      "Silverado 1500": {},
      "Silverado 2500 HD": {},
      "Silverado 3500 HD": {},
      Sonic: {},
      Spark: {},
      "Spark EV": {},
      "Suburban 1500": {},
      Tahoe: {},
      Traverse: {},
      Trax: {},
      Volt: {},
    },
    Chrysler: { "200": {}, "300": {}, "Town & Country": {} },
    Dodge: {
      Avenger: {},
      Challenger: {},
      Charger: {},
      Dart: {},
      Durango: {},
      "Grand Caravan": {},
      Journey: {},
    },
    Ferrari: {
      "458 Italia": {},
      "458 Spider": {},
      California: {},
      "F12 Berlinetta": {},
      FF: {},
      LaFerrari: {},
    },
    Fiat: { "500": {}, "500L": {} },
    Ford: {
      "C-Max": {},
      "E-150": {},
      "E-250": {},
      "E-350 Super Duty": {},
      Edge: {},
      Escape: {},
      Expedition: {},
      Explorer: {},
      "F-150": {},
      "F-250 Super Duty": {},
      "F-350 Super Duty": {},
      Fiesta: {},
      Flex: {},
      Focus: {},
      Fusion: {},
      Mustang: {},
      "Police Interceptor Sedan": {},
      "Police Interceptor Utility": {},
      "Special Service Police Sedan": {},
      Taurus: {},
      "Transit Connect": {},
    },
    Freightliner: { "Sprinter 2500": {}, "Sprinter 3500": {} },
    GMC: {
      Acadia: {},
      "Savana 1500": {},
      "Savana 2500": {},
      "Savana 3500": {},
      "Sierra 1500": {},
      "Sierra 2500 HD": {},
      "Sierra 3500 HD": {},
      Terrain: {},
      Yukon: {},
      "Yukon XL 1500": {},
    },
    Honda: {
      Accord: {},
      "CR-V": {},
      "CR-Z": {},
      Civic: {},
      Crosstour: {},
      Fit: {},
      Insight: {},
      Odyssey: {},
      Pilot: {},
      Ridgeline: {},
    },
    Hyundai: {
      Accent: {},
      Azera: {},
      Elantra: {},
      "Elantra Coupe": {},
      "Elantra GT": {},
      Equus: {},
      Genesis: {},
      "Genesis Coupe": {},
      "Santa Fe": {},
      "Santa Fe Sport": {},
      "Santa Fe XL": {},
      Sonata: {},
      Tucson: {},
      Veloster: {},
    },
    Infiniti: {
      Q50: {},
      Q60: {},
      Q70: {},
      QX50: {},
      QX60: {},
      QX70: {},
      QX80: {},
    },
    Jaguar: {
      "F-Type": {},
      XF: {},
      XFR: {},
      "XFR-S": {},
      XJ: {},
      XJR: {},
      XK: {},
      XKR: {},
      "XKR-S": {},
    },
    Jeep: {
      Cherokee: {},
      Compass: {},
      "Grand Cherokee": {},
      Patriot: {},
      Wrangler: {},
    },
    Kia: {
      Cadenza: {},
      Forte: {},
      "Forte Koup": {},
      Forte5: {},
      Optima: {},
      Rio: {},
      Rondo: {},
      Sedona: {},
      Sorento: {},
      Soul: {},
      Sportage: {},
    },
    Lamborghini: { Aventador: {}, Gallardo: {} },
    "Land Rover": {
      LR2: {},
      LR4: {},
      "Range Rover": {},
      "Range Rover Evoque": {},
      "Range Rover Sport": {},
    },
    Lexus: {
      CT200h: {},
      ES300h: {},
      ES350: {},
      GS350: {},
      GS450h: {},
      GX460: {},
      "IS F": {},
      IS250: {},
      IS350: {},
      LS460: {},
      LS600h: {},
      LX570: {},
      RX350: {},
      RX450h: {},
    },
    Lincoln: { MKS: {}, MKT: {}, MKX: {}, MKZ: {}, Navigator: {} },
    Lotus: { Evora: {} },
    Maserati: { Ghibli: {}, GranTurismo: {}, Quattroporte: {} },
    Mazda: {
      "2": {},
      "3": {},
      "5": {},
      "6": {},
      "3 Sport": {},
      "CX-5": {},
      "CX-9": {},
      "MX-5 Miata": {},
    },
    "Mercedes-Benz": {
      "B Electric Drive": {},
      B250: {},
      C250: {},
      C300: {},
      C350: {},
      "C63 AMG": {},
      CL550: {},
      CL600: {},
      "CL63 AMG": {},
      "CL65 AMG": {},
      CLA250: {},
      "CLA45 AMG": {},
      CLS550: {},
      "CLS63 AMG": {},
      "CLS63 AMG S": {},
      E250: {},
      E300: {},
      E350: {},
      E400: {},
      E550: {},
      "E63 AMG": {},
      "E63 AMG S": {},
      G550: {},
      "G63 AMG": {},
      GL350: {},
      GL450: {},
      GL550: {},
      "GL63 AMG": {},
      GLK250: {},
      GLK350: {},
      ML350: {},
      ML550: {},
      "ML63 AMG": {},
      S550: {},
      "S63 AMG": {},
      SL550: {},
      "SL63 AMG": {},
      "SL65 AMG": {},
      SLK250: {},
      SLK350: {},
      "SLK55 AMG": {},
      "SLS AMG": {},
      "Sprinter 2500": {},
      "Sprinter 3500": {},
    },
    Mini: { Cooper: {}, "Cooper Countryman": {}, "Cooper Paceman": {} },
    Mitsubishi: {
      Lancer: {},
      Mirage: {},
      Outlander: {},
      "Outlander Sport": {},
      RVR: {},
      "i-MiEV": {},
    },
    "Mobility Ventures": { "MV-1": {} },
    Nissan: {
      "370Z": {},
      Altima: {},
      Armada: {},
      Cube: {},
      Frontier: {},
      "GT-R": {},
      Juke: {},
      Leaf: {},
      Maxima: {},
      Murano: {},
      NV1500: {},
      NV200: {},
      NV2500: {},
      NV3500: {},
      Pathfinder: {},
      Quest: {},
      Rogue: {},
      "Rogue Select": {},
      Sentra: {},
      Titan: {},
      Versa: {},
      "Versa Note": {},
      Xterra: {},
    },
    Porsche: { "911": {}, Boxster: {}, Cayenne: {}, Cayman: {}, Panamera: {} },
    Ram: {
      "1500": {},
      "2500": {},
      "3500": {},
      "C/V": {},
      "ProMaster 1500": {},
      "ProMaster 2500": {},
      "ProMaster 3500": {},
    },
    "Rolls-Royce": { Ghost: {}, Phantom: {}, Wraith: {} },
    SRT: { Viper: {} },
    Scion: { "FR-S": {}, iQ: {}, tC: {}, xB: {}, xD: {} },
    Smart: { Fortwo: {} },
    Spyker: { C8: {} },
    Subaru: {
      BRZ: {},
      Forester: {},
      Impreza: {},
      Legacy: {},
      Outback: {},
      Tribeca: {},
      WRX: {},
      "WRX STI": {},
      "XV Crosstrek": {},
    },
    Tesla: { S: {} },
    Toyota: {
      "4Runner": {},
      Avalon: {},
      Camry: {},
      Corolla: {},
      "FJ Cruiser": {},
      Highlander: {},
      "Land Cruiser": {},
      Matrix: {},
      Prius: {},
      "Prius C": {},
      "Prius Plug-In": {},
      "Prius V": {},
      RAV4: {},
      Sequoia: {},
      Sienna: {},
      Tacoma: {},
      Tundra: {},
      Venza: {},
      Yaris: {},
    },
    Volkswagen: {
      Beetle: {},
      CC: {},
      Eos: {},
      GTI: {},
      Golf: {},
      Jetta: {},
      Passat: {},
      Routan: {},
      Tiguan: {},
      Touareg: {},
    },
    Volvo: { S60: {}, S80: {}, XC60: {}, XC70: {}, XC90: {} },
  },
  "2015": {
    Acura: { ILX: {}, MDX: {}, RDX: {}, RLX: {}, TLX: {} },
    "Alfa Romeo": { "4C": {} },
    "Aston Martin": {
      DB9: {},
      Rapide: {},
      "V12 Vantage": {},
      "V8 Vantage": {},
      Vanquish: {},
    },
    Audi: {
      A3: {},
      "A3 Quattro": {},
      A4: {},
      "A4 Quattro": {},
      "A4 allroad": {},
      "A5 Quattro": {},
      A6: {},
      "A6 Quattro": {},
      "A7 Quattro": {},
      "A8 Quattro": {},
      Q3: {},
      "Q3 Quattro": {},
      Q5: {},
      Q7: {},
      R8: {},
      RS5: {},
      RS7: {},
      S3: {},
      S4: {},
      S5: {},
      S6: {},
      S7: {},
      S8: {},
      SQ5: {},
      "TT Quattro": {},
      "TTS Quattro": {},
      allroad: {},
    },
    BMW: {
      "228i": {},
      "228i xDrive": {},
      "320i": {},
      "320i xDrive": {},
      "328d": {},
      "328d xDrive": {},
      "328i": {},
      "328i GT xDrive": {},
      "328i xDrive": {},
      "335i": {},
      "335i GT xDrive": {},
      "335i xDrive": {},
      "428i": {},
      "428i Gran Coupe": {},
      "428i xDrive": {},
      "428i xDrive Gran Coupe": {},
      "435i": {},
      "435i Gran Coupe": {},
      "435i xDrive": {},
      "435i xDrive Gran Coupe": {},
      "528i": {},
      "528i xDrive": {},
      "535d": {},
      "535d xDrive": {},
      "535i": {},
      "535i GT": {},
      "535i GT xDrive": {},
      "535i xDrive": {},
      "550i": {},
      "550i GT": {},
      "550i GT xDrive": {},
      "550i xDrive": {},
      "640i": {},
      "640i Gran Coupe": {},
      "640i xDrive": {},
      "640i xDrive Gran Coupe": {},
      "650i": {},
      "650i Gran Coupe": {},
      "650i xDrive": {},
      "650i xDrive Gran Coupe": {},
      "740Ld xDrive": {},
      "740Li": {},
      "740Li xDrive": {},
      "740i": {},
      "750Li": {},
      "750Li xDrive": {},
      "750i": {},
      "750i xDrive": {},
      "760Li": {},
      "ActiveHybrid 3": {},
      "ActiveHybrid 5": {},
      "ActiveHybrid 7": {},
      "Alpina B6 xDrive Gran Coupe": {},
      "Alpina B7": {},
      "Alpina B7 xDrive": {},
      "Alpina B7L": {},
      "Alpina B7L xDrive": {},
      M235i: {},
      "M235i xDrive": {},
      M3: {},
      M4: {},
      M5: {},
      M6: {},
      "M6 Gran Coupe": {},
      X1: {},
      X3: {},
      X4: {},
      X5: {},
      X6: {},
      Z4: {},
      i3: {},
      i8: {},
    },
    Bentley: { Continental: {}, "Flying Spur": {}, Mulsanne: {} },
    Buick: { Enclave: {}, Encore: {}, LaCrosse: {}, Regal: {}, Verano: {} },
    Cadillac: {
      ATS: {},
      CTS: {},
      ELR: {},
      Escalade: {},
      "Escalade ESV": {},
      SRX: {},
      XTS: {},
    },
    Chevrolet: {
      Camaro: {},
      Caprice: {},
      "Captiva Sport": {},
      "City Express": {},
      Colorado: {},
      Corvette: {},
      Cruze: {},
      Equinox: {},
      "Express 2500": {},
      "Express 3500": {},
      Impala: {},
      "Impala Limited": {},
      Malibu: {},
      SS: {},
      "Silverado 1500": {},
      "Silverado 2500 HD": {},
      "Silverado 3500 HD": {},
      Sonic: {},
      Spark: {},
      "Spark EV": {},
      Suburban: {},
      Tahoe: {},
      Traverse: {},
      Trax: {},
      Volt: {},
    },
    Chrysler: { "200": {}, "300": {}, "Town & Country": {} },
    Dodge: {
      Challenger: {},
      Charger: {},
      Dart: {},
      Durango: {},
      "Grand Caravan": {},
      Journey: {},
      Viper: {},
    },
    Ferrari: {
      "458 Italia": {},
      "458 Speciale": {},
      "458 Spider": {},
      "California T": {},
      "F12 Berlinetta": {},
      FF: {},
      LaFerrari: {},
    },
    Fiat: { "500": {}, "500L": {} },
    Ford: {
      "C-Max": {},
      "E-350 Super Duty": {},
      Edge: {},
      Escape: {},
      Expedition: {},
      Explorer: {},
      "F-150": {},
      "F-250 Super Duty": {},
      "F-350 Super Duty": {},
      Fiesta: {},
      Flex: {},
      Focus: {},
      Fusion: {},
      Mustang: {},
      "Police Interceptor Sedan": {},
      "Police Interceptor Utility": {},
      "Special Service Police Sedan": {},
      Taurus: {},
      "Transit Connect": {},
      "Transit-150": {},
      "Transit-250": {},
      "Transit-350": {},
      "Transit-350 HD": {},
    },
    Freightliner: { "Sprinter 2500": {}, "Sprinter 3500": {} },
    GMC: {
      Acadia: {},
      Canyon: {},
      "Savana 2500": {},
      "Savana 3500": {},
      "Sierra 1500": {},
      "Sierra 2500 HD": {},
      "Sierra 3500 HD": {},
      Terrain: {},
      Yukon: {},
      "Yukon XL": {},
    },
    Honda: {
      Accord: {},
      "CR-V": {},
      "CR-Z": {},
      Civic: {},
      Crosstour: {},
      Fit: {},
      Odyssey: {},
      Pilot: {},
    },
    Hyundai: {
      Accent: {},
      Azera: {},
      Elantra: {},
      "Elantra GT": {},
      Equus: {},
      Genesis: {},
      "Genesis Coupe": {},
      "Santa Fe": {},
      "Santa Fe Sport": {},
      "Santa Fe XL": {},
      Sonata: {},
      Tucson: {},
      Veloster: {},
    },
    Infiniti: {
      Q40: {},
      Q50: {},
      Q60: {},
      Q70: {},
      Q70L: {},
      QX50: {},
      QX60: {},
      QX70: {},
      QX80: {},
    },
    Jaguar: {
      "F-Type": {},
      XF: {},
      XFR: {},
      "XFR-S": {},
      XJ: {},
      XJR: {},
      XK: {},
      XKR: {},
      "XKR-S": {},
    },
    Jeep: {
      Cherokee: {},
      Compass: {},
      "Grand Cherokee": {},
      Patriot: {},
      Renegade: {},
      Wrangler: {},
    },
    Kia: {
      Cadenza: {},
      Forte: {},
      "Forte Koup": {},
      Forte5: {},
      K900: {},
      Optima: {},
      Rio: {},
      Rondo: {},
      Sedona: {},
      Sorento: {},
      Soul: {},
      "Soul EV": {},
      Sportage: {},
    },
    Lamborghini: { Aventador: {}, Huracan: {} },
    "Land Rover": {
      "Discovery Sport": {},
      LR2: {},
      LR4: {},
      "Range Rover": {},
      "Range Rover Evoque": {},
      "Range Rover Sport": {},
    },
    Lexus: {
      CT200h: {},
      ES300h: {},
      ES350: {},
      GS350: {},
      GS450h: {},
      GX460: {},
      IS250: {},
      IS350: {},
      LS460: {},
      LS600h: {},
      LX570: {},
      NX200t: {},
      NX300h: {},
      "RC F": {},
      RC350: {},
      RX350: {},
      RX450h: {},
    },
    Lincoln: { MKC: {}, MKS: {}, MKT: {}, MKX: {}, MKZ: {}, Navigator: {} },
    Maserati: { Ghibli: {}, GranTurismo: {}, Quattroporte: {} },
    Mazda: {
      "3": {},
      "5": {},
      "6": {},
      "3 Sport": {},
      "CX-5": {},
      "CX-9": {},
      "MX-5 Miata": {},
    },
    "Mercedes-Benz": {
      "B Electric Drive": {},
      B250: {},
      C250: {},
      C300: {},
      C350: {},
      C400: {},
      "C63 AMG": {},
      "C63 AMG S": {},
      CLA250: {},
      "CLA45 AMG": {},
      CLS400: {},
      CLS550: {},
      "CLS63 AMG S": {},
      E250: {},
      E300: {},
      E350: {},
      E400: {},
      E550: {},
      "E63 AMG": {},
      "E63 AMG S": {},
      G550: {},
      "G63 AMG": {},
      GL350: {},
      GL450: {},
      GL550: {},
      "GL63 AMG": {},
      GLA250: {},
      "GLA45 AMG": {},
      GLK250: {},
      GLK350: {},
      ML250: {},
      ML350: {},
      ML400: {},
      ML550: {},
      "ML63 AMG": {},
      S550: {},
      S550e: {},
      S600: {},
      "S63 AMG": {},
      "S65 AMG": {},
      SL400: {},
      SL550: {},
      "SL63 AMG": {},
      "SL65 AMG": {},
      SLK250: {},
      SLK350: {},
      "SLK55 AMG": {},
      "SLS AMG": {},
      "Sprinter 2500": {},
      "Sprinter 3500": {},
    },
    Mini: { Cooper: {}, "Cooper Countryman": {}, "Cooper Paceman": {} },
    Mitsubishi: {
      Lancer: {},
      Mirage: {},
      Outlander: {},
      "Outlander Sport": {},
      RVR: {},
    },
    "Mobility Ventures": { "MV-1": {} },
    Nissan: {
      "370Z": {},
      Altima: {},
      Armada: {},
      Frontier: {},
      "GT-R": {},
      Juke: {},
      Leaf: {},
      Micra: {},
      Murano: {},
      NV1500: {},
      NV200: {},
      NV2500: {},
      NV3500: {},
      Pathfinder: {},
      Quest: {},
      Rogue: {},
      "Rogue Select": {},
      Sentra: {},
      Titan: {},
      Versa: {},
      "Versa Note": {},
      Xterra: {},
    },
    Porsche: {
      "911": {},
      "918 Spyder": {},
      Boxster: {},
      Cayenne: {},
      Cayman: {},
      Macan: {},
      Panamera: {},
    },
    Ram: {
      "1500": {},
      "2500": {},
      "3500": {},
      "C/V": {},
      "ProMaster 1500": {},
      "ProMaster 2500": {},
      "ProMaster 3500": {},
      "ProMaster City": {},
    },
    "Rolls-Royce": { Ghost: {}, Phantom: {}, Wraith: {} },
    Scion: { "FR-S": {}, iQ: {}, tC: {}, xB: {} },
    Smart: { Fortwo: {} },
    Spyker: { C8: {} },
    Subaru: {
      BRZ: {},
      Forester: {},
      Impreza: {},
      Legacy: {},
      Outback: {},
      WRX: {},
      "WRX STI": {},
      "XV Crosstrek": {},
    },
    Tesla: { S: {} },
    Toyota: {
      "4Runner": {},
      Avalon: {},
      Camry: {},
      Corolla: {},
      Highlander: {},
      "Land Cruiser": {},
      Prius: {},
      "Prius C": {},
      "Prius Plug-In": {},
      "Prius V": {},
      RAV4: {},
      Sequoia: {},
      Sienna: {},
      Tacoma: {},
      Tundra: {},
      Venza: {},
      Yaris: {},
    },
    Volkswagen: {
      Beetle: {},
      CC: {},
      Eos: {},
      GTI: {},
      Golf: {},
      "Golf R": {},
      "Golf SportWagen": {},
      Jetta: {},
      Passat: {},
      Tiguan: {},
      Touareg: {},
      "e-Golf": {},
    },
    Volvo: {
      S60: {},
      S80: {},
      V60: {},
      "V60 Cross Country": {},
      XC60: {},
      XC70: {},
    },
  },
  "2016": {
    Acura: { ILX: {}, MDX: {}, RDX: {}, RLX: {}, TLX: {} },
    "Alfa Romeo": { "4C": {} },
    "Aston Martin": {
      DB9: {},
      Rapide: {},
      "V12 Vantage": {},
      "V8 Vantage": {},
      Vanquish: {},
    },
    Audi: {
      A3: {},
      "A3 Quattro": {},
      "A3 Sportback e-tron": {},
      A4: {},
      "A4 Quattro": {},
      "A4 allroad": {},
      "A5 Quattro": {},
      A6: {},
      "A6 Quattro": {},
      "A7 Quattro": {},
      "A8 Quattro": {},
      Q3: {},
      "Q3 Quattro": {},
      Q5: {},
      RS7: {},
      S3: {},
      S4: {},
      S5: {},
      S6: {},
      S7: {},
      S8: {},
      SQ5: {},
      "TT Quattro": {},
      "TTS Quattro": {},
      allroad: {},
    },
    BMW: {
      "228i": {},
      "228i xDrive": {},
      "320i": {},
      "320i xDrive": {},
      "328d": {},
      "328d xDrive": {},
      "328i": {},
      "328i GT xDrive": {},
      "328i xDrive": {},
      "330e": {},
      "335i GT xDrive": {},
      "340i": {},
      "340i xDrive": {},
      "428i": {},
      "428i Gran Coupe": {},
      "428i xDrive": {},
      "428i xDrive Gran Coupe": {},
      "435i": {},
      "435i Gran Coupe": {},
      "435i xDrive": {},
      "435i xDrive Gran Coupe": {},
      "528i": {},
      "528i xDrive": {},
      "535d": {},
      "535d xDrive": {},
      "535i": {},
      "535i GT": {},
      "535i GT xDrive": {},
      "535i xDrive": {},
      "550i": {},
      "550i GT xDrive": {},
      "550i xDrive": {},
      "640i": {},
      "640i Gran Coupe": {},
      "640i xDrive": {},
      "640i xDrive Gran Coupe": {},
      "650i": {},
      "650i Gran Coupe": {},
      "650i xDrive": {},
      "650i xDrive Gran Coupe": {},
      "740i": {},
      "750i": {},
      "750i xDrive": {},
      "ActiveHybrid 5": {},
      "Alpina B6 Gran Coupe": {},
      "Alpina B6 xDrive Gran Coupe": {},
      M2: {},
      M235i: {},
      "M235i xDrive": {},
      M3: {},
      M4: {},
      M5: {},
      M6: {},
      "M6 Gran Coupe": {},
      X1: {},
      X3: {},
      X4: {},
      X5: {},
      X6: {},
      Z4: {},
      i3: {},
      i8: {},
    },
    Bentley: { Continental: {}, "Flying Spur": {}, Mulsanne: {} },
    Buick: {
      Cascada: {},
      Enclave: {},
      Encore: {},
      Envision: {},
      LaCrosse: {},
      Regal: {},
      Verano: {},
    },
    Cadillac: {
      ATS: {},
      CT6: {},
      CTS: {},
      ELR: {},
      Escalade: {},
      "Escalade ESV": {},
      SRX: {},
      XTS: {},
    },
    Chevrolet: {
      Camaro: {},
      Caprice: {},
      "City Express": {},
      Colorado: {},
      Corvette: {},
      Cruze: {},
      "Cruze Limited": {},
      Equinox: {},
      "Express 2500": {},
      "Express 3500": {},
      Impala: {},
      "Impala Limited": {},
      "LCF 3500": {},
      Malibu: {},
      "Malibu Limited": {},
      SS: {},
      "Silverado 1500": {},
      "Silverado 2500 HD": {},
      "Silverado 3500 HD": {},
      Sonic: {},
      Spark: {},
      "Spark EV": {},
      Suburban: {},
      "Suburban 3500 HD": {},
      Tahoe: {},
      Traverse: {},
      Trax: {},
      Volt: {},
    },
    Chrysler: { "200": {}, "300": {}, "Town & Country": {} },
    Dodge: {
      Challenger: {},
      Charger: {},
      Dart: {},
      Durango: {},
      "Grand Caravan": {},
      Journey: {},
      Viper: {},
    },
    Ferrari: {
      "488 GTB": {},
      "488 Spider": {},
      "California T": {},
      "F12 Berlinetta": {},
      F12tdf: {},
      FF: {},
      LaFerrari: {},
    },
    Fiat: { "500": {}, "500L": {}, "500X": {} },
    Ford: {
      "C-Max": {},
      "E-350 Super Duty": {},
      Edge: {},
      Escape: {},
      Expedition: {},
      Explorer: {},
      "F-150": {},
      "F-250 Super Duty": {},
      "F-350 Super Duty": {},
      Fiesta: {},
      Flex: {},
      Focus: {},
      Fusion: {},
      Mustang: {},
      "Police Interceptor Sedan": {},
      "Police Interceptor Utility": {},
      "Special Service Police Sedan": {},
      Taurus: {},
      "Transit Connect": {},
      "Transit-150": {},
      "Transit-250": {},
      "Transit-350": {},
      "Transit-350 HD": {},
    },
    Freightliner: { "Sprinter 2500": {}, "Sprinter 3500": {} },
    GMC: {
      Acadia: {},
      Canyon: {},
      "Savana 2500": {},
      "Savana 3500": {},
      "Sierra 1500": {},
      "Sierra 2500 HD": {},
      "Sierra 3500 HD": {},
      Terrain: {},
      Yukon: {},
      "Yukon XL": {},
    },
    Honda: {
      Accord: {},
      "CR-V": {},
      "CR-Z": {},
      Civic: {},
      Fit: {},
      "HR-V": {},
      Odyssey: {},
      Pilot: {},
    },
    Hyundai: {
      Accent: {},
      Azera: {},
      Elantra: {},
      "Elantra GT": {},
      Equus: {},
      Genesis: {},
      "Genesis Coupe": {},
      "Santa Fe": {},
      "Santa Fe Sport": {},
      "Santa Fe XL": {},
      Sonata: {},
      Tucson: {},
      Veloster: {},
    },
    Infiniti: {
      Q50: {},
      Q70: {},
      Q70L: {},
      QX50: {},
      QX60: {},
      QX70: {},
      QX80: {},
    },
    Jaguar: { "F-Type": {}, XF: {}, XJ: {}, XJR: {} },
    Jeep: {
      Cherokee: {},
      Compass: {},
      "Grand Cherokee": {},
      Patriot: {},
      Renegade: {},
      Wrangler: {},
    },
    Kia: {
      Cadenza: {},
      Forte: {},
      "Forte Koup": {},
      Forte5: {},
      K900: {},
      Optima: {},
      Rio: {},
      Rondo: {},
      Sedona: {},
      Sorento: {},
      Soul: {},
      "Soul EV": {},
      Sportage: {},
    },
    Lamborghini: { Aventador: {}, Huracan: {} },
    "Land Rover": {
      "Discovery Sport": {},
      LR4: {},
      "Range Rover": {},
      "Range Rover Evoque": {},
      "Range Rover Sport": {},
    },
    Lexus: {
      CT200h: {},
      ES300h: {},
      ES350: {},
      "GS F": {},
      GS200t: {},
      GS350: {},
      GS450h: {},
      GX460: {},
      IS200t: {},
      IS300: {},
      IS350: {},
      LS460: {},
      LS600h: {},
      LX570: {},
      NX200t: {},
      NX300h: {},
      "RC F": {},
      RC200t: {},
      RC300: {},
      RC350: {},
      RX350: {},
      RX450h: {},
    },
    Lincoln: { MKC: {}, MKS: {}, MKT: {}, MKX: {}, MKZ: {}, Navigator: {} },
    Lotus: { Evora: {} },
    Maserati: { Ghibli: {}, GranTurismo: {}, Quattroporte: {} },
    Mazda: {
      "3": {},
      "5": {},
      "6": {},
      "3 Sport": {},
      "CX-3": {},
      "CX-5": {},
      "CX-9": {},
      "MX-5 Miata": {},
    },
    "Mercedes-Benz": {
      "AMG GT S": {},
      B250: {},
      B250e: {},
      C300: {},
      C350e: {},
      "C450 AMG": {},
      "C63 AMG": {},
      "C63 AMG S": {},
      CLA250: {},
      "CLA45 AMG": {},
      CLS400: {},
      CLS550: {},
      "CLS63 AMG S": {},
      E250: {},
      E300: {},
      E350: {},
      E400: {},
      E550: {},
      "E63 AMG S": {},
      G550: {},
      "G63 AMG": {},
      "G65 AMG": {},
      GL350: {},
      GL450: {},
      GL550: {},
      "GL63 AMG": {},
      GLA250: {},
      "GLA45 AMG": {},
      GLC300: {},
      GLE300d: {},
      GLE350: {},
      GLE350d: {},
      GLE400: {},
      "GLE450 AMG": {},
      GLE550: {},
      GLE550e: {},
      "GLE63 AMG": {},
      "GLE63 AMG S": {},
      "Maybach S600": {},
      Metris: {},
      S550: {},
      S550e: {},
      S600: {},
      "S63 AMG": {},
      "S65 AMG": {},
      SL400: {},
      SL550: {},
      "SL63 AMG": {},
      "SL65 AMG": {},
      SLK300: {},
      SLK350: {},
      "SLK55 AMG": {},
      "Sprinter 2500": {},
      "Sprinter 3500": {},
    },
    Mini: {
      Cooper: {},
      "Cooper Clubman": {},
      "Cooper Countryman": {},
      "Cooper Paceman": {},
    },
    Mitsubishi: {
      Lancer: {},
      Outlander: {},
      "Outlander Sport": {},
      RVR: {},
      "i-MiEV": {},
    },
    "Mobility Ventures": { "MV-1": {} },
    Nissan: {
      "370Z": {},
      Altima: {},
      Frontier: {},
      "GT-R": {},
      Juke: {},
      Leaf: {},
      Maxima: {},
      Micra: {},
      Murano: {},
      NV1500: {},
      NV200: {},
      NV2500: {},
      NV3500: {},
      Pathfinder: {},
      Quest: {},
      Rogue: {},
      Sentra: {},
      "Titan XD": {},
      Versa: {},
      "Versa Note": {},
    },
    Porsche: {
      "911": {},
      Boxster: {},
      Cayenne: {},
      Cayman: {},
      Macan: {},
      Panamera: {},
    },
    Ram: {
      "1500": {},
      "2500": {},
      "3500": {},
      "ProMaster 1500": {},
      "ProMaster 2500": {},
      "ProMaster 3500": {},
      "ProMaster City": {},
    },
    "Rolls-Royce": { Dawn: {}, Ghost: {}, Phantom: {}, Wraith: {} },
    Scion: { "FR-S": {}, iA: {}, iM: {}, tC: {} },
    Smart: { Fortwo: {} },
    Spyker: { C8: {} },
    Subaru: {
      BRZ: {},
      Crosstrek: {},
      Forester: {},
      Impreza: {},
      Legacy: {},
      Outback: {},
      WRX: {},
      "WRX STI": {},
    },
    Tesla: { S: {}, X: {} },
    Toyota: {
      "4Runner": {},
      Avalon: {},
      Camry: {},
      Corolla: {},
      Highlander: {},
      "Land Cruiser": {},
      Mirai: {},
      Prius: {},
      "Prius C": {},
      "Prius V": {},
      RAV4: {},
      Sequoia: {},
      Sienna: {},
      Tacoma: {},
      Tundra: {},
      Venza: {},
      Yaris: {},
    },
    Volkswagen: {
      Beetle: {},
      CC: {},
      Eos: {},
      GTI: {},
      Golf: {},
      "Golf R": {},
      "Golf SportWagen": {},
      Jetta: {},
      Passat: {},
      Tiguan: {},
      Touareg: {},
      "e-Golf": {},
    },
    Volvo: {
      S60: {},
      "S60 Cross Country": {},
      S80: {},
      V60: {},
      "V60 Cross Country": {},
      XC60: {},
      XC70: {},
      XC90: {},
    },
  },
  "2017": {
    Acura: { ILX: {}, MDX: {}, NSX: {}, RDX: {}, RLX: {}, TLX: {} },
    "Alfa Romeo": { "4C": {}, Giulia: {} },
    "Aston Martin": {
      DB11: {},
      Rapide: {},
      "V12 Vantage": {},
      "V8 Vantage": {},
      Vanquish: {},
    },
    Audi: {
      A3: {},
      "A3 Quattro": {},
      "A3 Sportback e-tron": {},
      A4: {},
      "A4 Quattro": {},
      "A4 allroad": {},
      "A5 Quattro": {},
      A6: {},
      "A6 Quattro": {},
      "A7 Quattro": {},
      "A8 Quattro": {},
      Q3: {},
      "Q3 Quattro": {},
      Q5: {},
      Q7: {},
      R8: {},
      RS3: {},
      RS7: {},
      S3: {},
      S5: {},
      S6: {},
      S7: {},
      S8: {},
      SQ5: {},
      "TT Quattro": {},
      "TTS Quattro": {},
    },
    BMW: {
      "230i": {},
      "230i xDrive": {},
      "320i": {},
      "320i xDrive": {},
      "328d": {},
      "328d xDrive": {},
      "330e": {},
      "330i": {},
      "330i GT xDrive": {},
      "330i xDrive": {},
      "340i": {},
      "340i GT xDrive": {},
      "340i xDrive": {},
      "430i": {},
      "430i Gran Coupe": {},
      "430i xDrive": {},
      "430i xDrive Gran Coupe": {},
      "440i": {},
      "440i Gran Coupe": {},
      "440i xDrive": {},
      "440i xDrive Gran Coupe": {},
      "530i": {},
      "530i xDrive": {},
      "535i GT": {},
      "535i GT xDrive": {},
      "540i": {},
      "540i xDrive": {},
      "550i GT xDrive": {},
      "640i": {},
      "640i Gran Coupe": {},
      "640i xDrive": {},
      "640i xDrive Gran Coupe": {},
      "650i": {},
      "650i Gran Coupe": {},
      "650i xDrive": {},
      "650i xDrive Gran Coupe": {},
      "740Le xDrive": {},
      "740e xDrive": {},
      "740i": {},
      "740i xDrive": {},
      "750Li xDrive": {},
      "750i": {},
      "750i xDrive": {},
      "Alpina B6 xDrive Gran Coupe": {},
      "Alpina B7": {},
      M2: {},
      M240i: {},
      "M240i xDrive": {},
      M3: {},
      M4: {},
      M6: {},
      "M6 Gran Coupe": {},
      "M760Li xDrive": {},
      "M760i xDrive": {},
      X1: {},
      X3: {},
      X4: {},
      X5: {},
      X6: {},
      i3: {},
      i8: {},
    },
    Bentley: { Bentayga: {}, Continental: {}, "Flying Spur": {}, Mulsanne: {} },
    Buick: {
      Cascada: {},
      Enclave: {},
      Encore: {},
      Envision: {},
      LaCrosse: {},
      Regal: {},
      Verano: {},
    },
    Cadillac: {
      ATS: {},
      CT6: {},
      CTS: {},
      Escalade: {},
      "Escalade ESV": {},
      XT5: {},
      XTS: {},
    },
    Chevrolet: {
      "Bolt EV": {},
      Camaro: {},
      Caprice: {},
      "City Express": {},
      Colorado: {},
      Corvette: {},
      Cruze: {},
      Equinox: {},
      "Express 2500": {},
      "Express 3500": {},
      Impala: {},
      "LCF 3500": {},
      Malibu: {},
      SS: {},
      "Silverado 1500": {},
      "Silverado 2500 HD": {},
      "Silverado 3500 HD": {},
      Sonic: {},
      Spark: {},
      Suburban: {},
      "Suburban 3500 HD": {},
      Tahoe: {},
      Traverse: {},
      Trax: {},
      Volt: {},
    },
    Chrysler: { "200": {}, "300": {}, Pacifica: {} },
    Dodge: {
      Challenger: {},
      Charger: {},
      Durango: {},
      "Grand Caravan": {},
      Journey: {},
      Viper: {},
    },
    Ferrari: {
      "488 GTB": {},
      "488 Spider": {},
      "California T": {},
      "F12 Berlinetta": {},
      F12tdf: {},
      GTC4Lusso: {},
      LaFerrari: {},
    },
    Fiat: { "500": {}, "124 Spider": {}, "500L": {}, "500X": {} },
    Ford: {
      "C-Max": {},
      "E-350 Super Duty": {},
      Edge: {},
      Escape: {},
      Expedition: {},
      Explorer: {},
      "F-150": {},
      "F-250 Super Duty": {},
      "F-350 Super Duty": {},
      Fiesta: {},
      Flex: {},
      Focus: {},
      Fusion: {},
      GT: {},
      Mustang: {},
      "Police Interceptor Sedan": {},
      "Police Interceptor Utility": {},
      "Special Service Police Sedan": {},
      Taurus: {},
      "Transit Connect": {},
      "Transit-150": {},
      "Transit-250": {},
      "Transit-350": {},
      "Transit-350 HD": {},
    },
    Freightliner: { "Sprinter 2500": {}, "Sprinter 3500": {} },
    GMC: {
      Acadia: {},
      "Acadia Limited": {},
      Canyon: {},
      "Savana 2500": {},
      "Savana 3500": {},
      "Sierra 1500": {},
      "Sierra 2500 HD": {},
      "Sierra 3500 HD": {},
      Terrain: {},
      Yukon: {},
      "Yukon XL": {},
    },
    Genesis: { G80: {}, G90: {} },
    Honda: {
      Accord: {},
      "CR-V": {},
      Civic: {},
      Clarity: {},
      Fit: {},
      "HR-V": {},
      Odyssey: {},
      Pilot: {},
      Ridgeline: {},
    },
    Hyundai: {
      Accent: {},
      Azera: {},
      Elantra: {},
      "Elantra GT": {},
      Ioniq: {},
      "Santa Fe": {},
      "Santa Fe Sport": {},
      "Santa Fe XL": {},
      Sonata: {},
      Tucson: {},
      Veloster: {},
    },
    Infiniti: {
      Q50: {},
      Q60: {},
      Q70: {},
      Q70L: {},
      QX30: {},
      QX50: {},
      QX60: {},
      QX70: {},
      QX80: {},
    },
    Jaguar: { "F-Pace": {}, "F-Type": {}, XE: {}, XF: {}, XJ: {}, XJR: {} },
    Jeep: {
      Cherokee: {},
      Compass: {},
      "Grand Cherokee": {},
      Patriot: {},
      Renegade: {},
      Wrangler: {},
    },
    Karma: { Revero: {} },
    Kia: {
      Cadenza: {},
      Forte: {},
      "Forte Koup": {},
      Forte5: {},
      K900: {},
      Niro: {},
      Optima: {},
      Rio: {},
      Rondo: {},
      Sedona: {},
      Sorento: {},
      Soul: {},
      "Soul EV": {},
      Sportage: {},
    },
    Lamborghini: { Aventador: {}, Centenario: {}, Huracan: {} },
    "Land Rover": {
      Discovery: {},
      "Discovery Sport": {},
      "Range Rover": {},
      "Range Rover Evoque": {},
      "Range Rover Sport": {},
    },
    Lexus: {
      CT200h: {},
      ES300h: {},
      ES350: {},
      "GS F": {},
      GS200t: {},
      GS350: {},
      GS450h: {},
      GX460: {},
      IS200t: {},
      IS300: {},
      IS350: {},
      LS460: {},
      LX570: {},
      NX200t: {},
      NX300h: {},
      "RC F": {},
      RC200t: {},
      RC300: {},
      RC350: {},
      RX350: {},
      RX450h: {},
    },
    Lincoln: {
      Continental: {},
      MKC: {},
      MKT: {},
      MKX: {},
      MKZ: {},
      Navigator: {},
    },
    Lotus: { Evora: {} },
    Maserati: { Ghibli: {}, GranTurismo: {}, Levante: {}, Quattroporte: {} },
    Mazda: {
      "3": {},
      "5": {},
      "6": {},
      "3 Sport": {},
      "CX-3": {},
      "CX-5": {},
      "CX-9": {},
      "MX-5 Miata": {},
    },
    "Mercedes-Benz": {
      "AMG GT": {},
      "AMG GT S": {},
      B250: {},
      B250e: {},
      C300: {},
      C350e: {},
      "C43 AMG": {},
      "C63 AMG": {},
      "C63 AMG S": {},
      CLA250: {},
      "CLA45 AMG": {},
      CLS400: {},
      CLS550: {},
      "CLS63 AMG S": {},
      E300: {},
      E400: {},
      "E43 AMG": {},
      E550: {},
      G550: {},
      "G550 4x4": {},
      "G63 AMG": {},
      "G65 AMG": {},
      GLA250: {},
      "GLA45 AMG": {},
      GLC300: {},
      "GLC43 AMG": {},
      GLE300d: {},
      GLE350: {},
      GLE400: {},
      "GLE43 AMG": {},
      GLE550: {},
      GLE550e: {},
      "GLE63 AMG": {},
      "GLE63 AMG S": {},
      GLS350d: {},
      GLS450: {},
      GLS550: {},
      "GLS63 AMG": {},
      "Maybach S550": {},
      "Maybach S600": {},
      Metris: {},
      S550: {},
      S550e: {},
      S600: {},
      "S63 AMG": {},
      "S65 AMG": {},
      SL450: {},
      SL550: {},
      "SL63 AMG": {},
      "SL65 AMG": {},
      SLC300: {},
      "SLC43 AMG": {},
      "Sprinter 2500": {},
      "Sprinter 3500": {},
    },
    Mini: { Cooper: {}, "Cooper Clubman": {}, "Cooper Countryman": {} },
    Mitsubishi: {
      Lancer: {},
      Mirage: {},
      "Mirage G4": {},
      Outlander: {},
      "Outlander Sport": {},
      RVR: {},
      "i-MiEV": {},
    },
    "Mobility Ventures": { "MV-1": {} },
    Nissan: {
      "370Z": {},
      Altima: {},
      Armada: {},
      Frontier: {},
      "GT-R": {},
      Juke: {},
      Leaf: {},
      Maxima: {},
      Micra: {},
      Murano: {},
      NV1500: {},
      NV200: {},
      NV2500: {},
      NV3500: {},
      Pathfinder: {},
      Qashqai: {},
      Quest: {},
      Rogue: {},
      "Rogue Sport": {},
      Sentra: {},
      Titan: {},
      "Titan XD": {},
      Versa: {},
      "Versa Note": {},
    },
    Porsche: {
      "911": {},
      "718 Boxster": {},
      "718 Cayman": {},
      Cayenne: {},
      Macan: {},
      Panamera: {},
    },
    Ram: {
      "1500": {},
      "2500": {},
      "3500": {},
      "ProMaster 1500": {},
      "ProMaster 2500": {},
      "ProMaster 3500": {},
      "ProMaster City": {},
    },
    "Rolls-Royce": { Dawn: {}, Ghost: {}, Phantom: {}, Wraith: {} },
    Smart: { Fortwo: {} },
    Spyker: { C8: {} },
    Subaru: {
      BRZ: {},
      Crosstrek: {},
      Forester: {},
      Impreza: {},
      Legacy: {},
      Outback: {},
      WRX: {},
      "WRX STI": {},
    },
    Tesla: { "3": {}, S: {}, X: {} },
    Toyota: {
      "86": {},
      "4Runner": {},
      Avalon: {},
      Camry: {},
      Corolla: {},
      "Corolla iM": {},
      Highlander: {},
      "Land Cruiser": {},
      Mirai: {},
      Prius: {},
      "Prius C": {},
      "Prius Prime": {},
      "Prius V": {},
      RAV4: {},
      Sequoia: {},
      Sienna: {},
      Tacoma: {},
      Tundra: {},
      Yaris: {},
      "Yaris iA": {},
    },
    Volkswagen: {
      Beetle: {},
      CC: {},
      GTI: {},
      Golf: {},
      "Golf Alltrack": {},
      "Golf R": {},
      "Golf SportWagen": {},
      Jetta: {},
      Passat: {},
      Tiguan: {},
      "Tiguan Limited": {},
      Touareg: {},
      "e-Golf": {},
    },
    Volvo: {
      S60: {},
      "S60 Cross Country": {},
      S90: {},
      V60: {},
      "V60 Cross Country": {},
      "V90 Cross Country": {},
      XC60: {},
      XC90: {},
    },
  },
  "2018": {
    Acura: { ILX: {}, MDX: {}, NSX: {}, RDX: {}, RLX: {}, TLX: {} },
    "Alfa Romeo": { "4C": {}, Giulia: {}, Stelvio: {} },
    "Aston Martin": { DB11: {}, Rapide: {}, Vanquish: {}, Vantage: {} },
    Audi: {
      A3: {},
      "A3 Quattro": {},
      "A3 Sportback e-tron": {},
      A4: {},
      "A4 Quattro": {},
      "A4 allroad": {},
      "A5 Quattro": {},
      "A5 Sportback": {},
      A6: {},
      "A6 Quattro": {},
      "A7 Quattro": {},
      "A8 Quattro": {},
      Q3: {},
      "Q3 Quattro": {},
      Q5: {},
      Q7: {},
      R8: {},
      RS3: {},
      RS5: {},
      RS7: {},
      S3: {},
      S4: {},
      S5: {},
      "S5 Sportback": {},
      S6: {},
      S7: {},
      S8: {},
      SQ5: {},
      "TT Quattro": {},
      "TT RS Quattro": {},
      "TTS Quattro": {},
    },
    BMW: {
      "230i": {},
      "230i xDrive": {},
      "320i": {},
      "320i xDrive": {},
      "328d": {},
      "328d xDrive": {},
      "330e": {},
      "330i": {},
      "330i GT xDrive": {},
      "330i xDrive": {},
      "340i": {},
      "340i GT xDrive": {},
      "340i xDrive": {},
      "430i": {},
      "430i Gran Coupe": {},
      "430i xDrive": {},
      "430i xDrive Gran Coupe": {},
      "440i": {},
      "440i Gran Coupe": {},
      "440i xDrive": {},
      "440i xDrive Gran Coupe": {},
      "530e": {},
      "530e xDrive": {},
      "530i": {},
      "530i xDrive": {},
      "540d xDrive": {},
      "540i": {},
      "540i xDrive": {},
      "640i": {},
      "640i Gran Coupe": {},
      "640i xDrive": {},
      "640i xDrive Gran Coupe": {},
      "640i xDrive Gran Turismo": {},
      "650i": {},
      "650i Gran Coupe": {},
      "650i xDrive": {},
      "650i xDrive Gran Coupe": {},
      "740e xDrive": {},
      "740i": {},
      "740i xDrive": {},
      "750Li xDrive": {},
      "750i": {},
      "750i xDrive": {},
      "Alpina B6 xDrive Gran Coupe": {},
      "Alpina B7": {},
      M2: {},
      M240i: {},
      "M240i xDrive": {},
      M3: {},
      M4: {},
      M5: {},
      "M550i xDrive": {},
      M6: {},
      "M6 Gran Coupe": {},
      "M760Li xDrive": {},
      "M760i xDrive": {},
      X1: {},
      X2: {},
      X3: {},
      X4: {},
      X5: {},
      X6: {},
      i3: {},
      i3s: {},
    },
    Bentley: { Bentayga: {}, Continental: {}, "Flying Spur": {}, Mulsanne: {} },
    Buick: {
      Cascada: {},
      Enclave: {},
      Encore: {},
      Envision: {},
      LaCrosse: {},
      "Regal Sportback": {},
      "Regal TourX": {},
    },
    Cadillac: {
      ATS: {},
      CT6: {},
      CTS: {},
      Escalade: {},
      "Escalade ESV": {},
      XT5: {},
      XTS: {},
    },
    Chevrolet: {
      "Bolt EV": {},
      Camaro: {},
      "City Express": {},
      Colorado: {},
      Corvette: {},
      Cruze: {},
      Equinox: {},
      "Express 2500": {},
      "Express 3500": {},
      Impala: {},
      "LCF 3500": {},
      Malibu: {},
      "Silverado 1500": {},
      "Silverado 2500 HD": {},
      "Silverado 3500 HD": {},
      Sonic: {},
      Spark: {},
      Suburban: {},
      "Suburban 3500 HD": {},
      Tahoe: {},
      Traverse: {},
      Trax: {},
      Volt: {},
    },
    Chrysler: { "300": {}, Pacifica: {} },
    Dodge: {
      Challenger: {},
      Charger: {},
      Durango: {},
      "Grand Caravan": {},
      Journey: {},
    },
    Ferrari: {
      "488 GTB": {},
      "488 Spider": {},
      "812 Superfast": {},
      GTC4Lusso: {},
      "GTC4Lusso T": {},
      Portofino: {},
    },
    Fiat: { "500": {}, "124 Spider": {}, "500L": {}, "500X": {} },
    Ford: {
      "C-Max": {},
      "E-350 Super Duty": {},
      EcoSport: {},
      Edge: {},
      Escape: {},
      Expedition: {},
      Explorer: {},
      "F-150": {},
      "F-250 Super Duty": {},
      "F-350 Super Duty": {},
      Fiesta: {},
      Flex: {},
      Focus: {},
      Fusion: {},
      GT: {},
      Mustang: {},
      "Police Interceptor Sedan": {},
      "Police Interceptor Utility": {},
      "Special Service Police Sedan": {},
      Taurus: {},
      "Transit Connect": {},
      "Transit-150": {},
      "Transit-250": {},
      "Transit-350": {},
      "Transit-350 HD": {},
    },
    Freightliner: { "Sprinter 2500": {}, "Sprinter 3500": {} },
    GMC: {
      Acadia: {},
      Canyon: {},
      "Savana 2500": {},
      "Savana 3500": {},
      "Sierra 1500": {},
      "Sierra 2500 HD": {},
      "Sierra 3500 HD": {},
      Terrain: {},
      Yukon: {},
      "Yukon XL": {},
    },
    Genesis: { G80: {}, G90: {} },
    Honda: {
      Accord: {},
      "CR-V": {},
      Civic: {},
      Clarity: {},
      Fit: {},
      "HR-V": {},
      Odyssey: {},
      Pilot: {},
      Ridgeline: {},
    },
    Hyundai: {
      Accent: {},
      Elantra: {},
      "Elantra GT": {},
      Ioniq: {},
      Kona: {},
      "Santa Fe": {},
      "Santa Fe Sport": {},
      "Santa Fe XL": {},
      Sonata: {},
      Tucson: {},
    },
    Infiniti: {
      Q50: {},
      Q60: {},
      Q70: {},
      Q70L: {},
      QX30: {},
      QX60: {},
      QX80: {},
    },
    Jaguar: {
      "E-Pace": {},
      "F-Pace": {},
      "F-Type": {},
      XE: {},
      XF: {},
      XJ: {},
      XJR575: {},
    },
    Jeep: {
      Cherokee: {},
      Compass: {},
      "Grand Cherokee": {},
      Renegade: {},
      Wrangler: {},
      "Wrangler JK": {},
    },
    Karma: { Revero: {} },
    Kia: {
      Cadenza: {},
      Forte: {},
      Forte5: {},
      Niro: {},
      Optima: {},
      Rio: {},
      Sedona: {},
      Sorento: {},
      Soul: {},
      "Soul EV": {},
      Sportage: {},
      Stinger: {},
    },
    Lamborghini: { Aventador: {}, Huracan: {} },
    "Land Rover": {
      Discovery: {},
      "Discovery Sport": {},
      "Range Rover": {},
      "Range Rover Evoque": {},
      "Range Rover Sport": {},
      "Range Rover Velar": {},
    },
    Lexus: {
      ES300h: {},
      ES350: {},
      "GS F": {},
      GS300: {},
      GS350: {},
      GS450h: {},
      GX460: {},
      IS300: {},
      IS350: {},
      LC500: {},
      LC500h: {},
      LS500: {},
      LS500h: {},
      LX570: {},
      NX300: {},
      NX300h: {},
      "RC F": {},
      RC300: {},
      RC350: {},
      RX350: {},
      RX350L: {},
      RX450h: {},
      RX450hL: {},
    },
    Lincoln: {
      Continental: {},
      MKC: {},
      MKT: {},
      MKX: {},
      MKZ: {},
      Navigator: {},
    },
    Lotus: { Evora: {} },
    Maserati: { Ghibli: {}, GranTurismo: {}, Levante: {}, Quattroporte: {} },
    Mazda: {
      "3": {},
      "6": {},
      "3 Sport": {},
      "CX-3": {},
      "CX-5": {},
      "CX-9": {},
      "MX-5 Miata": {},
    },
    "Mercedes-Benz": {
      "AMG GT": {},
      "AMG GT C": {},
      "AMG GT R": {},
      "AMG GT S": {},
      B250: {},
      C300: {},
      C350e: {},
      "C43 AMG": {},
      "C63 AMG": {},
      "C63 AMG S": {},
      CLA250: {},
      "CLA45 AMG": {},
      CLS550: {},
      "CLS63 AMG S": {},
      E300: {},
      E400: {},
      "E43 AMG": {},
      "E63 AMG S": {},
      G550: {},
      "G550 4x4": {},
      "G63 AMG": {},
      "G65 AMG": {},
      GLA250: {},
      "GLA45 AMG": {},
      GLC300: {},
      GLC350e: {},
      "GLC43 AMG": {},
      "GLC63 AMG": {},
      "GLC63 AMG S": {},
      GLE350: {},
      GLE400: {},
      "GLE43 AMG": {},
      GLE550: {},
      GLE550e: {},
      "GLE63 AMG": {},
      "GLE63 AMG S": {},
      GLS450: {},
      GLS550: {},
      "GLS63 AMG": {},
      "Maybach S560": {},
      "Maybach S650": {},
      Metris: {},
      S450: {},
      S560: {},
      "S63 AMG": {},
      "S65 AMG": {},
      SL450: {},
      SL550: {},
      "SL63 AMG": {},
      "SL65 AMG": {},
      SLC300: {},
      "SLC43 AMG": {},
      "Sprinter 2500": {},
      "Sprinter 3500": {},
    },
    Mini: { Cooper: {}, "Cooper Clubman": {}, "Cooper Countryman": {} },
    Mitsubishi: {
      "Eclipse Cross": {},
      Mirage: {},
      "Mirage G4": {},
      Outlander: {},
      "Outlander PHEV": {},
      "Outlander Sport": {},
      RVR: {},
    },
    Nissan: {
      "370Z": {},
      Altima: {},
      Armada: {},
      Frontier: {},
      "GT-R": {},
      Kicks: {},
      Leaf: {},
      Maxima: {},
      Micra: {},
      Murano: {},
      NV1500: {},
      NV200: {},
      NV2500: {},
      NV3500: {},
      Pathfinder: {},
      Qashqai: {},
      Rogue: {},
      "Rogue Sport": {},
      Sentra: {},
      Titan: {},
      "Titan XD": {},
      Versa: {},
      "Versa Note": {},
    },
    Porsche: {
      "911": {},
      "718 Boxster": {},
      "718 Cayman": {},
      Cayenne: {},
      Macan: {},
      Panamera: {},
    },
    Ram: {
      "1500": {},
      "2500": {},
      "3500": {},
      "ProMaster 1500": {},
      "ProMaster 2500": {},
      "ProMaster 3500": {},
      "ProMaster City": {},
    },
    "Rolls-Royce": { Dawn: {}, Ghost: {}, Phantom: {}, Wraith: {} },
    Smart: { Fortwo: {} },
    Spyker: { C8: {} },
    Subaru: {
      BRZ: {},
      Crosstrek: {},
      Forester: {},
      Impreza: {},
      Legacy: {},
      Outback: {},
      WRX: {},
      "WRX STI": {},
    },
    Tesla: { "3": {}, S: {}, X: {} },
    Toyota: {
      "86": {},
      "4Runner": {},
      Avalon: {},
      "C-HR": {},
      Camry: {},
      Corolla: {},
      "Corolla iM": {},
      Highlander: {},
      "Land Cruiser": {},
      Mirai: {},
      Prius: {},
      "Prius C": {},
      "Prius Prime": {},
      "Prius V": {},
      RAV4: {},
      Sequoia: {},
      Sienna: {},
      Tacoma: {},
      Tundra: {},
      Yaris: {},
      "Yaris iA": {},
    },
    Volkswagen: {
      Atlas: {},
      Beetle: {},
      GTI: {},
      Golf: {},
      "Golf Alltrack": {},
      "Golf R": {},
      "Golf SportWagen": {},
      Jetta: {},
      Passat: {},
      Tiguan: {},
      "Tiguan Limited": {},
      "e-Golf": {},
    },
    Volvo: {
      S60: {},
      "S60 Cross Country": {},
      S90: {},
      V60: {},
      "V60 Cross Country": {},
      V90: {},
      "V90 Cross Country": {},
      XC60: {},
      XC90: {},
    },
  },
  "2019": {
    Acura: { ILX: {}, MDX: {}, NSX: {}, RDX: {}, RLX: {}, TLX: {} },
    "Alfa Romeo": { "4C": {}, Giulia: {}, Stelvio: {} },
    "Aston Martin": { DB11: {}, DBS: {}, Rapide: {}, Vantage: {} },
    Audi: {
      A3: {},
      "A3 Quattro": {},
      A4: {},
      "A4 Quattro": {},
      "A4 allroad": {},
      "A5 Quattro": {},
      "A5 Sportback": {},
      "A6 Quattro": {},
      "A7 Quattro": {},
      "A7 Sportback": {},
      "A8 Quattro": {},
      Q3: {},
      Q5: {},
      Q7: {},
      Q8: {},
      RS3: {},
      RS5: {},
      "RS5 Sportback": {},
      S3: {},
      S4: {},
      S5: {},
      "S5 Sportback": {},
      SQ5: {},
      "TT Quattro": {},
      "TT RS Quattro": {},
      "TTS Quattro": {},
      "e-tron Quattro": {},
    },
    BMW: {
      "230i": {},
      "230i xDrive": {},
      "330i": {},
      "330i GT xDrive": {},
      "330i xDrive": {},
      "340i GT xDrive": {},
      "430i": {},
      "430i Gran Coupe": {},
      "430i xDrive": {},
      "430i xDrive Gran Coupe": {},
      "440i": {},
      "440i Gran Coupe": {},
      "440i xDrive": {},
      "440i xDrive Gran Coupe": {},
      "530e": {},
      "530e xDrive": {},
      "530i": {},
      "530i xDrive": {},
      "540i": {},
      "540i xDrive": {},
      "640i Gran Coupe": {},
      "640i xDrive Gran Coupe": {},
      "640i xDrive Gran Turismo": {},
      "650i Gran Coupe": {},
      "650i xDrive Gran Coupe": {},
      "740e xDrive": {},
      "740i": {},
      "740i xDrive": {},
      "750Li xDrive": {},
      "750i": {},
      "750i xDrive": {},
      "Alpina B6 xDrive Gran Coupe": {},
      "Alpina B7": {},
      M2: {},
      M240i: {},
      "M240i xDrive": {},
      M4: {},
      M5: {},
      "M550i xDrive": {},
      "M6 Gran Coupe": {},
      "M760Li xDrive": {},
      "M760i xDrive": {},
      "M850i xDrive": {},
      X1: {},
      X2: {},
      X3: {},
      X4: {},
      X5: {},
      X6: {},
      X7: {},
      Z4: {},
      i3: {},
      i3s: {},
      i8: {},
    },
    Bentley: { Bentayga: {}, Continental: {}, "Flying Spur": {}, Mulsanne: {} },
    Buick: {
      Cascada: {},
      Enclave: {},
      Encore: {},
      Envision: {},
      LaCrosse: {},
      "Regal Sportback": {},
      "Regal TourX": {},
    },
    Cadillac: {
      ATS: {},
      CT6: {},
      CTS: {},
      Escalade: {},
      "Escalade ESV": {},
      XT4: {},
      XT5: {},
      XTS: {},
    },
    Chevrolet: {
      Blazer: {},
      "Bolt EV": {},
      Camaro: {},
      Colorado: {},
      Corvette: {},
      Cruze: {},
      Equinox: {},
      "Express 2500": {},
      "Express 3500": {},
      Impala: {},
      Malibu: {},
      "Silverado 1500": {},
      "Silverado 1500 LD": {},
      "Silverado 2500 HD": {},
      "Silverado 3500 HD": {},
      Sonic: {},
      Spark: {},
      Suburban: {},
      "Suburban 3500 HD": {},
      Tahoe: {},
      Traverse: {},
      Trax: {},
      Volt: {},
    },
    Chrysler: { "300": {}, Pacifica: {} },
    Dodge: {
      Challenger: {},
      Charger: {},
      Durango: {},
      "Grand Caravan": {},
      Journey: {},
    },
    Ferrari: {
      "488 GTB": {},
      "488 Pista": {},
      "488 Pista Spider": {},
      "488 Spider": {},
      "812 Superfast": {},
      GTC4Lusso: {},
      "GTC4Lusso T": {},
      Portofino: {},
    },
    Fiat: { "500": {}, "124 Spider": {}, "500L": {}, "500X": {} },
    Ford: {
      "E-350 Super Duty": {},
      EcoSport: {},
      Edge: {},
      Escape: {},
      Expedition: {},
      Explorer: {},
      "F-150": {},
      "F-250 Super Duty": {},
      "F-350 Super Duty": {},
      Fiesta: {},
      Flex: {},
      Fusion: {},
      GT: {},
      Mustang: {},
      "Police Interceptor Sedan": {},
      "Police Interceptor Utility": {},
      "Police Responder Hybrid": {},
      Ranger: {},
      "SSV Plug-In Hybrid": {},
      Taurus: {},
      "Transit Connect": {},
      "Transit-150": {},
      "Transit-250": {},
      "Transit-350": {},
      "Transit-350 HD": {},
    },
    Freightliner: {
      "Sprinter 1500": {},
      "Sprinter 2500": {},
      "Sprinter 3500": {},
    },
    GMC: {
      Acadia: {},
      Canyon: {},
      "Savana 2500": {},
      "Savana 3500": {},
      "Sierra 1500": {},
      "Sierra 1500 Limited": {},
      "Sierra 2500 HD": {},
      "Sierra 3500 HD": {},
      Terrain: {},
      Yukon: {},
      "Yukon XL": {},
    },
    Genesis: { G70: {}, G80: {}, G90: {} },
    Honda: {
      Accord: {},
      "CR-V": {},
      Civic: {},
      Clarity: {},
      Fit: {},
      "HR-V": {},
      Insight: {},
      Odyssey: {},
      Passport: {},
      Pilot: {},
      Ridgeline: {},
    },
    Hyundai: {
      Accent: {},
      Elantra: {},
      "Elantra GT": {},
      Ioniq: {},
      Kona: {},
      "Kona Electric": {},
      Nexo: {},
      "Santa Fe": {},
      "Santa Fe XL": {},
      Sonata: {},
      Tucson: {},
      Veloster: {},
      "Veloster N": {},
    },
    Infiniti: {
      Q50: {},
      Q60: {},
      Q70: {},
      Q70L: {},
      QX30: {},
      QX50: {},
      QX60: {},
      QX80: {},
    },
    Jaguar: {
      "E-Pace": {},
      "F-Pace": {},
      "F-Type": {},
      "I-Pace": {},
      XE: {},
      XF: {},
      XJ: {},
      XJR575: {},
    },
    Jeep: {
      Cherokee: {},
      Compass: {},
      "Grand Cherokee": {},
      Renegade: {},
      Wrangler: {},
    },
    Karma: { Revero: {} },
    Kia: {
      Cadenza: {},
      Forte: {},
      K900: {},
      Niro: {},
      "Niro EV": {},
      Optima: {},
      Rio: {},
      Sedona: {},
      Sorento: {},
      Soul: {},
      "Soul EV": {},
      Sportage: {},
      Stinger: {},
    },
    Lamborghini: { Aventador: {}, Huracan: {}, Urus: {} },
    "Land Rover": {
      Discovery: {},
      "Discovery Sport": {},
      "Range Rover": {},
      "Range Rover Evoque": {},
      "Range Rover Sport": {},
      "Range Rover Velar": {},
    },
    Lexus: {
      ES300h: {},
      ES350: {},
      "GS F": {},
      GS300: {},
      GS350: {},
      GX460: {},
      IS300: {},
      IS350: {},
      LC500: {},
      LC500h: {},
      LS500: {},
      LS500h: {},
      LX570: {},
      NX300: {},
      NX300h: {},
      "RC F": {},
      RC300: {},
      RC350: {},
      RX350: {},
      RX350L: {},
      RX450h: {},
      RX450hL: {},
      UX200: {},
      UX250h: {},
    },
    Lincoln: {
      Continental: {},
      MKC: {},
      MKT: {},
      MKZ: {},
      Nautilus: {},
      Navigator: {},
    },
    Lotus: { Evora: {} },
    Maserati: { Ghibli: {}, GranTurismo: {}, Levante: {}, Quattroporte: {} },
    Mazda: {
      "3": {},
      "6": {},
      "3 Sport": {},
      "CX-3": {},
      "CX-5": {},
      "CX-9": {},
      "MX-5 Miata": {},
    },
    "Mercedes-Benz": {
      A220: {},
      A250: {},
      "AMG GT": {},
      "AMG GT 53": {},
      "AMG GT 63": {},
      "AMG GT 63 S": {},
      "AMG GT C": {},
      "AMG GT R": {},
      "AMG GT S": {},
      B250: {},
      C300: {},
      "C43 AMG": {},
      "C63 AMG": {},
      "C63 AMG S": {},
      CLA250: {},
      "CLA45 AMG": {},
      CLS450: {},
      "CLS53 AMG": {},
      E300: {},
      E450: {},
      "E53 AMG": {},
      "E63 AMG S": {},
      G550: {},
      "G63 AMG": {},
      GLA250: {},
      "GLA45 AMG": {},
      GLC300: {},
      GLC350e: {},
      "GLC43 AMG": {},
      "GLC63 AMG": {},
      "GLC63 AMG S": {},
      GLE400: {},
      "GLE43 AMG": {},
      "GLE63 AMG": {},
      "GLE63 AMG S": {},
      GLS450: {},
      GLS550: {},
      "GLS63 AMG": {},
      "Maybach S560": {},
      "Maybach S650": {},
      Metris: {},
      S450: {},
      S560: {},
      "S63 AMG": {},
      "S65 AMG": {},
      SL450: {},
      SL550: {},
      "SL63 AMG": {},
      SLC300: {},
      "SLC43 AMG": {},
      "Sprinter 1500": {},
      "Sprinter 2500": {},
      "Sprinter 3500": {},
    },
    Mini: { Cooper: {}, "Cooper Clubman": {}, "Cooper Countryman": {} },
    Mitsubishi: {
      "Eclipse Cross": {},
      Mirage: {},
      "Mirage G4": {},
      Outlander: {},
      "Outlander PHEV": {},
      "Outlander Sport": {},
      RVR: {},
    },
    Nissan: {
      "370Z": {},
      Altima: {},
      Armada: {},
      Frontier: {},
      "GT-R": {},
      Kicks: {},
      Leaf: {},
      Maxima: {},
      Micra: {},
      Murano: {},
      NV1500: {},
      NV200: {},
      NV2500: {},
      NV3500: {},
      Pathfinder: {},
      Qashqai: {},
      Rogue: {},
      "Rogue Sport": {},
      Sentra: {},
      Titan: {},
      "Titan XD": {},
      Versa: {},
      "Versa Note": {},
    },
    Porsche: {
      "911": {},
      "718 Boxster": {},
      "718 Cayman": {},
      Cayenne: {},
      Macan: {},
      Panamera: {},
    },
    Ram: {
      "1500": {},
      "2500": {},
      "3500": {},
      "1500 Classic": {},
      "ProMaster 1500": {},
      "ProMaster 2500": {},
      "ProMaster 3500": {},
      "ProMaster City": {},
    },
    "Rolls-Royce": {
      Cullinan: {},
      Dawn: {},
      Ghost: {},
      Phantom: {},
      Wraith: {},
    },
    Smart: { "EQ fortwo": {} },
    Spyker: { C8: {} },
    Subaru: {
      Ascent: {},
      BRZ: {},
      Crosstrek: {},
      Forester: {},
      Impreza: {},
      Legacy: {},
      Outback: {},
      WRX: {},
      "WRX STI": {},
    },
    Tesla: { "3": {}, S: {}, X: {} },
    Toyota: {
      "86": {},
      "4Runner": {},
      Avalon: {},
      "C-HR": {},
      Camry: {},
      Corolla: {},
      Highlander: {},
      "Land Cruiser": {},
      Mirai: {},
      Prius: {},
      "Prius AWD-e": {},
      "Prius C": {},
      "Prius Prime": {},
      RAV4: {},
      Sequoia: {},
      Sienna: {},
      Tacoma: {},
      Tundra: {},
      Yaris: {},
    },
    Volkswagen: {
      Arteon: {},
      Atlas: {},
      Beetle: {},
      GTI: {},
      Golf: {},
      "Golf Alltrack": {},
      "Golf R": {},
      "Golf SportWagen": {},
      Jetta: {},
      Passat: {},
      Tiguan: {},
      "e-Golf": {},
    },
    Volvo: {
      S60: {},
      S90: {},
      V60: {},
      "V60 Cross Country": {},
      V90: {},
      "V90 Cross Country": {},
      XC40: {},
      XC60: {},
      XC90: {},
    },
  },
  "2020": {
    Acura: { ILX: {}, MDX: {}, NSX: {}, RDX: {}, RLX: {}, TLX: {} },
    "Alfa Romeo": { "4C": {}, Giulia: {}, Stelvio: {} },
    "Aston Martin": { DB11: {}, DBS: {}, "Rapide E": {}, Vantage: {} },
    Audi: {
      A3: {},
      A4: {},
      "A4 Quattro": {},
      "A4 allroad": {},
      "A6 Quattro": {},
      "A6 allroad": {},
      "A7 Quattro": {},
      "A7 Sportback": {},
      "A8 Quattro": {},
      Q3: {},
      Q5: {},
      "Q5 PHEV": {},
      Q7: {},
      Q8: {},
      R8: {},
      RS5: {},
      "RS5 Sportback": {},
      S3: {},
      S4: {},
      "S5 Sportback": {},
      S6: {},
      S7: {},
      "S7 Sportback": {},
      SQ5: {},
      "TT Quattro": {},
      "TT RS Quattro": {},
      "TTS Quattro": {},
      "e-tron Quattro": {},
    },
    BMW: {
      "228i xDrive Gran Coupe": {},
      "230i": {},
      "230i xDrive": {},
      "330i": {},
      "330i xDrive": {},
      "430i": {},
      "430i Gran Coupe": {},
      "430i xDrive": {},
      "430i xDrive Gran Coupe": {},
      "440i": {},
      "440i Gran Coupe": {},
      "440i xDrive": {},
      "440i xDrive Gran Coupe": {},
      "530e": {},
      "530e xDrive": {},
      "530i": {},
      "530i xDrive": {},
      "540i": {},
      "540i xDrive": {},
      "740i": {},
      "740i xDrive": {},
      "745Le xDrive": {},
      "745e xDrive": {},
      "750i xDrive": {},
      "840i": {},
      "840i Gran Coupe": {},
      "840i xDrive": {},
      "840i xDrive Gran Coupe": {},
      "Alpina B7": {},
      M2: {},
      "M235i xDrive Gran Coupe": {},
      M240i: {},
      "M240i xDrive": {},
      M340i: {},
      "M340i xDrive": {},
      M4: {},
      M5: {},
      "M550i xDrive": {},
      "M760Li xDrive": {},
      "M760i xDrive": {},
      M8: {},
      "M8 Gran Coupe": {},
      "M850i xDrive": {},
      "M850i xDrive Gran Coupe": {},
      X1: {},
      X2: {},
      X3: {},
      X4: {},
      X5: {},
      X6: {},
      X7: {},
      Z4: {},
      i3: {},
      i3s: {},
      i8: {},
    },
    Bentley: { Bentayga: {}, Continental: {}, "Flying Spur": {}, Mulsanne: {} },
    Buick: {
      Enclave: {},
      Encore: {},
      "Encore GX": {},
      Envision: {},
      "Regal Sportback": {},
      "Regal TourX": {},
    },
    Cadillac: {
      CT4: {},
      CT5: {},
      CT6: {},
      Escalade: {},
      "Escalade ESV": {},
      XT4: {},
      XT5: {},
      XT6: {},
    },
    Chevrolet: {
      Blazer: {},
      "Bolt EV": {},
      Camaro: {},
      Colorado: {},
      Corvette: {},
      Equinox: {},
      "Express 2500": {},
      "Express 3500": {},
      Impala: {},
      Malibu: {},
      "Silverado 1500": {},
      "Silverado 2500 HD": {},
      "Silverado 3500 HD": {},
      Sonic: {},
      Spark: {},
      Suburban: {},
      Tahoe: {},
      Traverse: {},
      Trax: {},
    },
    Chrysler: { "300": {}, Pacifica: {}, Voyager: {} },
    Dodge: {
      Challenger: {},
      Charger: {},
      Durango: {},
      "Grand Caravan": {},
      Journey: {},
    },
    Ferrari: {
      "488 Pista": {},
      "488 Pista Spider": {},
      "812 Superfast": {},
      "F8 Tributo": {},
      GTC4Lusso: {},
      "GTC4Lusso T": {},
      Portofino: {},
      Roma: {},
      "SF90 Stradale": {},
    },
    Fiat: { "124 Spider": {}, "500L": {}, "500X": {} },
    Ford: {
      EcoSport: {},
      Edge: {},
      Escape: {},
      Expedition: {},
      Explorer: {},
      "F-150": {},
      "F-250 Super Duty": {},
      "F-350 Super Duty": {},
      Fusion: {},
      GT: {},
      Mustang: {},
      "Police Interceptor Utility": {},
      "Police Responder Hybrid": {},
      Ranger: {},
      "SSV Plug-In Hybrid": {},
      "Transit Connect": {},
      "Transit-150": {},
      "Transit-250": {},
      "Transit-350": {},
      "Transit-350 HD": {},
    },
    Freightliner: {
      "Sprinter 1500": {},
      "Sprinter 2500": {},
      "Sprinter 3500": {},
    },
    GMC: {
      Acadia: {},
      Canyon: {},
      "Savana 2500": {},
      "Savana 3500": {},
      "Sierra 1500": {},
      "Sierra 2500 HD": {},
      "Sierra 3500 HD": {},
      Terrain: {},
      Yukon: {},
      "Yukon XL": {},
    },
    Genesis: { G70: {}, G80: {}, G90: {} },
    Honda: {
      Accord: {},
      "CR-V": {},
      Civic: {},
      Clarity: {},
      Fit: {},
      "HR-V": {},
      Insight: {},
      Odyssey: {},
      Passport: {},
      Pilot: {},
      Ridgeline: {},
    },
    Hyundai: {
      Accent: {},
      Elantra: {},
      "Elantra GT": {},
      Ioniq: {},
      Kona: {},
      "Kona Electric": {},
      Nexo: {},
      Palisade: {},
      "Santa Fe": {},
      Sonata: {},
      Tucson: {},
      Veloster: {},
      "Veloster N": {},
      Venue: {},
    },
    Infiniti: { Q50: {}, Q60: {}, QX50: {}, QX60: {}, QX80: {} },
    Jaguar: {
      "E-Pace": {},
      "F-Pace": {},
      "F-Type": {},
      "I-Pace": {},
      XE: {},
      XF: {},
    },
    Jeep: {
      Cherokee: {},
      Compass: {},
      Gladiator: {},
      "Grand Cherokee": {},
      Renegade: {},
      Wrangler: {},
    },
    Karma: { Revero: {} },
    Kia: {
      Forte: {},
      Forte5: {},
      K900: {},
      Niro: {},
      "Niro EV": {},
      Optima: {},
      Rio: {},
      Sedona: {},
      Sorento: {},
      Soul: {},
      "Soul EV": {},
      Sportage: {},
      Stinger: {},
      Telluride: {},
    },
    Lamborghini: { Aventador: {}, Huracan: {}, Urus: {} },
    "Land Rover": {
      "Defender 110": {},
      "Defender 90": {},
      Discovery: {},
      "Discovery Sport": {},
      "Range Rover": {},
      "Range Rover Evoque": {},
      "Range Rover Sport": {},
      "Range Rover Velar": {},
    },
    Lexus: {
      ES300h: {},
      ES350: {},
      "GS F": {},
      GS350: {},
      GX460: {},
      IS300: {},
      IS350: {},
      LC500: {},
      LC500h: {},
      LS500: {},
      LS500h: {},
      LX570: {},
      NX300: {},
      NX300h: {},
      "RC F": {},
      RC300: {},
      RC350: {},
      RX350: {},
      RX350L: {},
      RX450h: {},
      RX450hL: {},
      UX200: {},
      UX250h: {},
    },
    Lincoln: {
      Aviator: {},
      Continental: {},
      Corsair: {},
      MKZ: {},
      Nautilus: {},
      Navigator: {},
    },
    Lotus: { "Evora GT": {} },
    Maserati: { Ghibli: {}, Levante: {}, Quattroporte: {} },
    Mazda: {
      "3": {},
      "6": {},
      "CX-3": {},
      "CX-30": {},
      "CX-5": {},
      "CX-9": {},
      "MX-5 Miata": {},
    },
    "Mercedes-Benz": {
      A220: {},
      A250: {},
      "A35 AMG": {},
      "AMG GT": {},
      "AMG GT 53": {},
      "AMG GT 63": {},
      "AMG GT 63 S": {},
      "AMG GT C": {},
      "AMG GT R": {},
      "AMG GT R Pro": {},
      "AMG GT S": {},
      C300: {},
      "C43 AMG": {},
      "C63 AMG": {},
      "C63 AMG S": {},
      CLA250: {},
      "CLA35 AMG": {},
      "CLA45 AMG": {},
      CLS450: {},
      "CLS53 AMG": {},
      E350: {},
      E450: {},
      "E53 AMG": {},
      "E63 AMG S": {},
      G550: {},
      "G63 AMG": {},
      GLA250: {},
      "GLA45 AMG": {},
      GLB250: {},
      GLC300: {},
      GLC350e: {},
      "GLC43 AMG": {},
      "GLC63 AMG": {},
      "GLC63 AMG S": {},
      GLE350: {},
      GLE450: {},
      GLS450: {},
      GLS580: {},
      "Maybach S560": {},
      "Maybach S650": {},
      Metris: {},
      S450: {},
      S560: {},
      "S63 AMG": {},
      "S65 AMG": {},
      SL450: {},
      SL550: {},
      "SL63 AMG": {},
      SLC300: {},
      "SLC43 AMG": {},
      "Sprinter 1500": {},
      "Sprinter 2500": {},
      "Sprinter 3500": {},
    },
    Mini: { Cooper: {}, "Cooper Clubman": {}, "Cooper Countryman": {} },
    Mitsubishi: {
      "Eclipse Cross": {},
      Mirage: {},
      "Mirage G4": {},
      Outlander: {},
      "Outlander PHEV": {},
      "Outlander Sport": {},
      RVR: {},
    },
    Nissan: {
      "370Z": {},
      Altima: {},
      Armada: {},
      Frontier: {},
      "GT-R": {},
      Kicks: {},
      Leaf: {},
      Maxima: {},
      Murano: {},
      NV1500: {},
      NV200: {},
      NV2500: {},
      NV3500: {},
      Pathfinder: {},
      Qashqai: {},
      Rogue: {},
      "Rogue Sport": {},
      Versa: {},
    },
    Porsche: {
      "911": {},
      "718 Boxster": {},
      "718 Cayman": {},
      Cayenne: {},
      Macan: {},
      Panamera: {},
      Taycan: {},
    },
    Ram: {
      "1500": {},
      "2500": {},
      "3500": {},
      "ProMaster 1500": {},
      "ProMaster 2500": {},
      "ProMaster 3500": {},
      "ProMaster City": {},
    },
    "Rolls-Royce": {
      Cullinan: {},
      Dawn: {},
      Ghost: {},
      Phantom: {},
      Wraith: {},
    },
    Subaru: {
      Ascent: {},
      BRZ: {},
      Crosstrek: {},
      Forester: {},
      Impreza: {},
      Legacy: {},
      Outback: {},
      WRX: {},
      "WRX STI": {},
    },
    Tesla: { "3": {}, S: {}, X: {} },
    Toyota: {
      "86": {},
      "4Runner": {},
      Avalon: {},
      "C-HR": {},
      Camry: {},
      Corolla: {},
      "GR Supra": {},
      Highlander: {},
      "Land Cruiser": {},
      Prius: {},
      "Prius AWD-e": {},
      "Prius Prime": {},
      RAV4: {},
      Sequoia: {},
      Sienna: {},
      Tacoma: {},
      Tundra: {},
      Yaris: {},
    },
    Volkswagen: {
      Arteon: {},
      Atlas: {},
      "Atlas Cross Sport": {},
      GTI: {},
      Golf: {},
      Jetta: {},
      Passat: {},
      Tiguan: {},
      "e-Golf": {},
    },
    Volvo: {
      S60: {},
      S90: {},
      V60: {},
      "V60 Cross Country": {},
      V90: {},
      "V90 Cross Country": {},
      XC40: {},
      XC60: {},
      XC90: {},
    },
  },
};
