import { useMutation } from "@apollo/client";
import { Mutation, MutationUpdateJobArgs } from "../../generated/nest-graphql";
import { PreJobCheckListForm } from "../Forms/PreJobCheckListForm";
import { UPDATE_JOB } from "../../graphql/mutations/updateJob";
import { cleanObject } from "../../lib/functions";
import React from "react";
import { FullScreenDialog } from "../FullScreenDialog";
import { PreJobCheckList } from "../../types/PreJobCheckList";
import { flow } from "fp-ts/lib/function";
import { jobDetailsSpec } from "./jobDetailsSpec";
import { showSuccessAlert } from "../../actions";
import { useDispatch } from "../../contexts/snackbar-context";

export type PreJobCheckListFormValues = PreJobCheckList & {
  odometer: string;
};

export const PreJobCheckListDialog = ({
  jobId,
  open,
  onClose,
  initialValues,
}: {
  jobId: string;
  open: boolean;
  onClose: () => void;
  initialValues: PreJobCheckListFormValues;
}) => {
  const dispatch = useDispatch();
  const [updateJob] = useMutation<Mutation, MutationUpdateJobArgs>(UPDATE_JOB);
  const onSubmit = async (values: PreJobCheckListFormValues) => {
    // @ts-ignore
    const input = flow(jobDetailsSpec, cleanObject)(values);
    await updateJob({
      variables: {
        id: jobId,
        updateJobInput: input,
      },
    });
    onClose();
    showSuccessAlert(dispatch, "Success");
  };
  return (
    <FullScreenDialog title={"Pre-Job Checklist"} open={open}>
      <PreJobCheckListForm initialValues={initialValues} onSubmit={onSubmit} />
    </FullScreenDialog>
  );
};
