import * as React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { ReceiptViewLineItem } from "./ReceiptViewLineItem";
import { ReceiptVewSummaryItem } from "./ReceiptVewSummaryItem";

export const ReceiptView: React.FC<{
  subtotal: string;
  total: string;
  tax: string;
  parts: string;
  labor: string;
}> = ({ subtotal, tax, total, parts, labor }) => {
  return (
    <Card raised={true}>
      <CardContent>
        <ReceiptViewLineItem label={"Subtotal"} value={`$${subtotal}`} />
        <ReceiptViewLineItem subItem label={"Parts"} value={`$${parts}`} />
        <ReceiptViewLineItem subItem label={"Labor"} value={`$${labor}`} />
        <ReceiptViewLineItem label={"Tax"} value={`$${tax}`} />
        <ReceiptVewSummaryItem label={"Total"} value={`$${total}`} />
      </CardContent>
    </Card>
  );
};
