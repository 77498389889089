import gql from "graphql-tag";

export const preJobCheckListItemField = `{
            partCondition
            additionalInfo
        } `;

export const preJobCheckListField = `
    preJobCheckList {
        frontPads ${preJobCheckListItemField}
        frontRotors ${preJobCheckListItemField}
        rearPads ${preJobCheckListItemField}
        rearRotors ${preJobCheckListItemField}
        brakeFluid ${preJobCheckListItemField}
        brakeShoes ${preJobCheckListItemField}
        brakeDrums ${preJobCheckListItemField}
        calipers ${preJobCheckListItemField}
        shocksStruts ${preJobCheckListItemField}
        controlArms ${preJobCheckListItemField}
        wheelHubs ${preJobCheckListItemField}
        sparkPlugs ${preJobCheckListItemField}
        otherBrakeParts ${preJobCheckListItemField}
        dashboardLights ${preJobCheckListItemField}
    }
`;

export const jobDetailsFields = `
        invoice {
            id
        }
        vehicleInfo {
            frontPadLife
            licensePlate
            make
            model
            odometer
            rearPadLife
            vin
            year
            extraInfo
            symptoms
            frontBrakeSymptoms
            rearBrakeSymptoms
            customerExpectation
            additionalNotes
        }
        appointment {
            id
            allDay
            endDate
            startDate
            subject
            technician {
                id
                firstName
                lastName
            }
        }
        contact {
            id
            phoneNumber
            firstName
            lastName
            email
            stripeCustomer
            stripePaymentMethods
        }
        feedback {
            rating
            doneBetter
            additionalComments
        }
        partsOrderingIssuesSurvey {
            partsCorrect
            partsStores
            partsIssues
            additionalDetails 
        }
        description
        id
        jobNumber
        market
        items {
            product
            partsStore {
                id
                name
                vendor
                address
            }
            name
            amount
            description
            isInEstimate
            partNumber
            laborCost
            partsCost
            createdAt
            updatedAt
        }
        partsStore {
            id
            name
            vendor
        }
        jobName
        type
        serviceCallReason
        serviceCallReasonExtraInfo
        contact {
            id
            firstName
            lastName
            fullName
            files {
                fileName
            }
            stripeCustomer
            stripePaymentMethods
        }
        serviceLocation
        partsInfo {
            partsLocation
            partsOrderNumber
            partsOrdered
            partsNotes
        }
        ${preJobCheckListField}
        estimate {
            id
        }
        privateNotes
        serviceLocationNotes
        taxable
        createdAt
        updatedAt
        status`;

export const GET_JOB = gql`query getJob($id: ID!) {
    getJob(id: $id) {
        ${jobDetailsFields}
    }
}`;
