import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import { useField } from "formik";
import FormControlLabel from "@material-ui/core/FormControlLabel";

export const CheckboxField: React.FC<{
  name: string;
  label?: string;
  required?: boolean;
}> = ({ name, label, required, ...props }) => {
  const [field] = useField(name);
  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            defaultChecked
            color="primary"
            inputProps={{ "aria-label": "secondary checkbox" }}
            {...field}
            {...props}
          />
        }
        label={label}
      />
    </div>
  );
};
