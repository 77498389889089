import * as Sentry from "@sentry/browser";
import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  defaultDataIdFromObject,
  InMemoryCache,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import { setContext } from "@apollo/client/link/context";

export default function createApolloClient(getToken: any) {
  const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_BACKEND_GRAPHQL_ENDPOINT}`, // Server URL (must be absolute)
  });
  const errorLink = onError((errorHandler) => {
    errorHandler.graphQLErrors.forEach((err) => {
      Sentry.captureException(err, {
        extra: {
          errorHandler,
        },
      });
    });
    if (errorHandler.networkError) {
      Sentry.captureException(errorHandler.networkError, {
        extra: {
          errorHandler,
        },
      });
    }
    console.error({ errorHandler });
  });
  const retryLink = new RetryLink();
  const authLink = setContext(async (_, { headers }) => {
    const token = await getToken();
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    };
  });
  return new ApolloClient({
    link: ApolloLink.from([errorLink, retryLink, authLink, httpLink]),
    connectToDevTools: true,
    cache: new InMemoryCache({
      possibleTypes: {
        CustomerGetInvoiceResult: ["InvoicePaidResult", "CustomerInvoice"],
        PerformActionResult: ["Contact", "Estimate", "Job", "Invoice"],
      },
      dataIdFromObject: (object) => defaultDataIdFromObject(object),
    }),
  });
}
