import { UpdateInvoiceDetailsFormValues } from "../Forms/UpdateInvoiceDetailsForm";
import { prop } from "ramda";
import { itemsSpec, preJobCheckListItemSpec } from "../Jobs/jobDetailsSpec";

export const invoiceDetailsSpec = (formData: UpdateInvoiceDetailsFormValues) => {
  const {
    year,
    taxable,
    technician,
    extraInfo,
    frontPadLife,
    licensePlate,
    make,
    model,
    odometer,
    rearPadLife,
    symptoms,
    vin,
    contact,
    market,
    jobId,
    estimate,
    serviceLocation,
    privateNotes,
    items,
    status,
    issuedDate,
    dueDate,
    customerMessage,
    frontPads,
    frontRotors,
    rearPads,
    rearRotors,
    brakeFluid,
    brakeShoes,
    brakeDrums,
    calipers,
    shocksStruts,
    controlArms,
    wheelHubs,
    sparkPlugs,
    otherBrakeParts,
    dashboardLights,
  } = formData;
  return {
    contact: prop("id", contact),
    customerMessage,
    dueDate,
    issuedDate,
    technician: prop("id", technician),
    market,
    taxable,
    items: items && itemsSpec(items),
    privateNotes,
    serviceLocation,
    status,
    estimate,
    job: jobId,
    vehicleInfo: {
      year,
      extraInfo,
      frontPadLife,
      licensePlate,
      make,
      model,
      odometer,
      rearPadLife,
      symptoms,
      vin,
    },
    preJobCheckList: {
      frontPads: frontPads && preJobCheckListItemSpec(frontPads),
      frontRotors: frontRotors && preJobCheckListItemSpec(frontRotors),
      rearPads: rearPads && preJobCheckListItemSpec(rearPads),
      rearRotors: rearRotors && preJobCheckListItemSpec(rearRotors),
      brakeFluid: brakeFluid && preJobCheckListItemSpec(brakeFluid),
      brakeShoes: brakeShoes && preJobCheckListItemSpec(brakeShoes),
      brakeDrums: brakeDrums && preJobCheckListItemSpec(brakeDrums),
      calipers: calipers && preJobCheckListItemSpec(calipers),
      shocksStruts: shocksStruts && preJobCheckListItemSpec(shocksStruts),
      controlArms: controlArms && preJobCheckListItemSpec(controlArms),
      wheelHubs: wheelHubs && preJobCheckListItemSpec(wheelHubs),
      sparkPlugs: sparkPlugs && preJobCheckListItemSpec(sparkPlugs),
      otherBrakeParts: otherBrakeParts && preJobCheckListItemSpec(otherBrakeParts),
      dashboardLights: dashboardLights && preJobCheckListItemSpec(dashboardLights),
    },
  };
};
