import React from "react";
import { Field, Form, Formik } from "formik";
import { Mutation, MutationCreatePaymentIntentArgs, Query, QueryGetContactArgs } from "../../generated/nest-graphql";
import { CREATE_CONFIRMED_PAYMENT_INTENT } from "../../graphql/mutations/createConfirmedPaymentIntent";
import { useMutation, useQuery } from "@apollo/client";
import { applySpec, find, isNil, map, path, pick, pipe, prop, propEq, propOr } from "ramda";
import * as Sentry from "@sentry/react";
import { showSuccessAlert, showErrorAlert } from "../../actions";
import { useDispatch } from "../../contexts/snackbar-context";
import { hashIdempotencyKey } from "../../lib/functions";
import CurrencyInput from "../Forms/fields/CurrencyInput";
import { GET_CONTACT_DETAILS } from "../../graphql/queries/getContactDetails";
import * as Yup from "yup";
import { CheckboxField } from "../Forms/fields/CheckboxField";
import { SubmitButton } from "../Buttons/SubmitButton";
import { SelectOptions } from "../Forms/fields/SelectOptions";
import { Link } from "@material-ui/core";
import { useToggle } from "../../hooks/useToggle";
import { AddPaymentOptionsDialog } from "./AddPaymentOptionsDialog";

export type CollectCreditCardFormValues = {
  paymentMethod: string;
  amount: string;
  sendReceipt: boolean;
};

type PaymentMethodCollection = {
  label: string;
  value: string;
  default: boolean;
  paymentMethod: any;
};

const validationSchema = Yup.object().shape({
  paymentMethod: Yup.string().required(),
  amount: Yup.string().required(),
  sendReceipt: Yup.boolean(),
});

export const CollectCardPaymentFields: React.FC<{
  invoiceId: string;
  contactId: string;
  amount: string;
  laborCost: string;
  partsCost: string;
  partsTax: string;
  laborTax: string;
  totalTax: string;
  subTotal: string;
  refetch: any;
  onClose: () => void;
}> = ({
  invoiceId,
  contactId,
  amount,
  refetch,
  onClose,
  laborCost,
  partsCost,
  partsTax,
  laborTax,
  totalTax,
  subTotal,
}) => {
  const dispatch = useDispatch();
  const cleanedAmount = amount.includes(".") ? amount : amount + ".00";
  const [addNewCardOptionStatus, , toggleAddNewCardOption] = useToggle();

  const [createConfirmedPayment] = useMutation<Mutation, MutationCreatePaymentIntentArgs>(
    CREATE_CONFIRMED_PAYMENT_INTENT
  );
  const { data, refetch: contactRefetch } = useQuery<Query, QueryGetContactArgs>(GET_CONTACT_DETAILS, {
    variables: {
      id: contactId,
    },
  });

  if (!data) {
    return null;
  }
  const contact = prop("getContact", data);
  const paymentMethods: PaymentMethodCollection[] = pipe(
    prop("stripePaymentMethods"),
    map(
      applySpec({
        label: ({ card }) => `${prop("brand", card)} ${prop("last4", card)}`,
        value: prop<any, string>("id"),
        default: prop<any, boolean>("default"),
        paymentMethod: (pm) => pm,
      })
    )
  )(contact);

  const defaultPaymentMethod = find<PaymentMethodCollection>(propEq("default", true), paymentMethods as any);
  const initialValues: CollectCreditCardFormValues = {
    paymentMethod: defaultPaymentMethod ? prop("value", defaultPaymentMethod) : null,
    amount,
    sendReceipt: true,
  };

  const onSubmit = async (values: CollectCreditCardFormValues) => {
    const paymentMethodId = prop("paymentMethod", values);
    if (paymentMethodId) {
      const { amount, sendReceipt } = values;
      const paymentMethod = pipe<PaymentMethodCollection[], PaymentMethodCollection, any>(
        find<PaymentMethodCollection>(propEq("value", paymentMethodId)),
        prop("paymentMethod")
      )(paymentMethods);
      try {
        const idempotencyKey = await hashIdempotencyKey(`onFile:${invoiceId}:${paymentMethodId}`);
        const result = await createConfirmedPayment({
          variables: {
            createPaymentInput: {
              type: "Invoice",
              paymentMethod: "Credit Card",
              status: "processing",
              invoice: invoiceId,
              laborCost,
              partsCost,
              partsTax,
              laborTax,
              totalTax,
              subTotal,
              contact: contact.id,
              customer: path(["stripeCustomer", "id"], contact),
              stripePaymentMethod: prop("id", paymentMethod),
              amount,
              invoicePrice: cleanedAmount,
              payer: `${contact.firstName} ${contact.lastName}`,
              sendReceipt,
              source: "stored stripe payment methods",
              receivedDate: new Date(),
            },
            idempotencyKey,
          },
        });

        const status = path(["data", "createConfirmedPaymentIntent", "status"], result);
        if (status === "succeeded") {
          showSuccessAlert(dispatch, "Payment Charge Success");

          await refetch({
            variables: {
              id: invoiceId,
            },
          });
          onClose();
          return;
        }
      } catch (error) {
        console.log({ error });
        Sentry.captureException(propOr("", "message", error), {
          extra: error,
        });
      }
      showErrorAlert(dispatch, "Payment Charge Failed");
    }
  };

  const cardOptions = map(pick(["label", "value"]))(paymentMethods);
  return (
    <Formik<CollectCreditCardFormValues>
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ isValid, isSubmitting, setFieldValue, values: { paymentMethod } }) => {
        return (
          <Form>
            <div className="grid px-4">
              <div className="grid gap-4">
                <Field name="paymentMethod">
                  {({ field }) => (
                    <SelectOptions
                      {...field}
                      options={cardOptions}
                      label={"Card"}
                      onChange={(event) => {
                        const value = path<string>(["target", "value"], event);
                        setFieldValue("paymentMethod", value);
                      }}
                      errorMessage={isNil(paymentMethod) ? "No card selected, please add a card." : ""}
                    />
                  )}
                </Field>
                <div className="flex justify-between">
                  <Link href="#" onClick={toggleAddNewCardOption}>
                    Add New Card
                  </Link>
                  <Link
                    href="#"
                    onClick={() => {
                      contactRefetch();
                    }}
                  >
                    Refresh
                  </Link>
                </div>
                <div className="mb-4">
                  <CurrencyInput name={"amount"} readOnly={true} label={"Amount"} required={true} />
                  <CheckboxField name={"sendReceipt"} label={"Send Receipt"} required={true} />
                </div>
                <SubmitButton isSubmitting={isSubmitting} isValid={isValid && !!paymentMethod} />
              </div>
            </div>
            <AddPaymentOptionsDialog
              contact={contact}
              open={addNewCardOptionStatus}
              onClose={toggleAddNewCardOption}
              setPaymentMethod={(paymentMethodId: string) => setFieldValue("paymentMethod", paymentMethodId)}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
