import React from "react";
import { useField } from "formik";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";

export const DateInput: React.FC<{
  name: string;
  label: string;
  required?: boolean;
}> = ({ name, label, required, ...props }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helpers] = useField(name);

  return (
    <div>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="MM/dd/yyyy"
          margin="normal"
          label={label}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          {...field}
          {...props}
          onChange={(date) => helpers.setValue(date)}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
};
