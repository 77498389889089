import * as React from "react";
import { map, pipe, pluck, sum } from "ramda";
import { Form, Formik } from "formik";
import { Contact, Item, Mutation, MutationDeleteInvoiceArgs, Technician } from "../../generated/nest-graphql";
import { TextAreaField } from "./fields/TextAreaField";
import Container from "@material-ui/core/Container";
import { ListItemWithSubList } from "../ListItems/ListItemWithSubList";
import { Link, useHistory } from "react-router-dom";
import List from "@material-ui/core/List";
import { ContactListItem } from "../ListItems/ContactListItem";
import { ItemsField } from "./fields/ItemsField";
import Paper from "@material-ui/core/Paper";
import { toNumber } from "../../lib/functions";
import { ReceiptView } from "../Invoices/ReceiptView";
import { BalanceDueView } from "../Invoices/BalanceDueView";
import { VehicleInfoValues } from "../../types/VehicleInfoValues";
import { PreJobCheckList } from "../../types/PreJobCheckList";
import { BottomSubmitButtonRow } from "../Buttons/BottomSubmitButtonRow";
import { DeleteButton } from "../Invoices/DeleteButton";
import { useMutation } from "@apollo/client";
import { DELETE_INVOICE } from "../../graphql/mutations/deleteInvoice";
import { useMarkets } from "../../hooks/useMarkets";
import { defaultTo, find, prop, propEq, propOr, props } from "ramda";
import { flow } from "fp-ts/lib/function";
import currency from "currency.js";

export type UpdateInvoiceDetailsFormValues = {
  contact: Contact;
  serviceLocation: string;
  status: string;
  estimate?: string;
  taxable?: boolean;
  privateNotes?: string;
  market: string;
  technician: Technician;
  items: Item[];
  customerMessage?: string;
  issuedDate: Date;
  jobNumber?: string;
  dueDate: Date;
  jobId?: string;
} & VehicleInfoValues &
  PreJobCheckList;

type UpdateInvoiceDetailsFormProps = {
  pinSaveToBottom: boolean;
  itemsEditable: boolean;
  initialValues: UpdateInvoiceDetailsFormValues;
  onSubmit: any;
  amountPaid: string;
  balanceDue: string;
  invoiceId: string;
};
export const UpdateInvoiceDetailsForm: React.FC<UpdateInvoiceDetailsFormProps> = ({
  initialValues,
  onSubmit,
  amountPaid,
  balanceDue,
  itemsEditable,
  pinSaveToBottom,
  invoiceId,
}) => {
  const { goBack } = useHistory();
  const [deleteInvoice] = useMutation<Mutation, MutationDeleteInvoiceArgs>(DELETE_INVOICE);
  const markets = useMarkets();

  return (
    <Formik<UpdateInvoiceDetailsFormValues> initialValues={initialValues} onSubmit={onSubmit} enableReinitialize={true}>
      {({ values, setFieldValue, isSubmitting, isValid, submitForm, dirty }) => {
        const taxable: boolean = propOr(false, "taxable", values);
        const subtotal = pipe(pluck("amount"), map(toNumber), sum, currency)(values.items);
        const partsCost = pipe(pluck("partsCost"), map(toNumber), sum, currency)(values.items);
        const laborCost = subtotal.subtract(partsCost);
        const [partsTaxRate, laborTaxRate]: any = flow(
          defaultTo([]),
          find(propEq("name", prop("market", initialValues))),
          props(["partsTaxRate", "laborTaxRate"])
        )(markets);
        const partsTax = partsCost.multiply(partsTaxRate / 100);
        const laborTax = laborCost.multiply(laborTaxRate / 100);
        const taxAmount = taxable ? partsTax.add(laborTax) : 0;
        const total = subtotal.add(taxAmount);
        return (
          <Form
            // padding bottom to offset save button
            className="pb-4"
          >
            <Paper>
              <List>
                <ContactListItem contact={values.contact} />
                <Link to={`/jobs/${values.jobId}`}>
                  <ListItemWithSubList
                    arrow={false}
                    divider={true}
                    title={`View Job: ${values.jobNumber}`}
                    secondaries={[]}
                    button={true}
                  />
                </Link>
              </List>
              <Container className={"py-4 mb-4"}>
                <div className="grid gap-4">
                  <ItemsField
                    parentSubmit={submitForm}
                    name={"items"}
                    setFieldValue={setFieldValue}
                    editable={itemsEditable}
                  />
                  <ReceiptView
                    subtotal={subtotal.toString()}
                    total={total.toString()}
                    tax={taxAmount.toString()}
                    parts={partsCost.toString()}
                    labor={laborCost.toString()}
                  />
                  <BalanceDueView amountPaid={amountPaid} balanceDue={balanceDue} />
                  <TextAreaField name={"customerMessage"} label={"Customer Message"} />
                  <TextAreaField name={"privateNotes"} label={"Private Notes"} />
                  {values.status === "Draft" && (
                    <DeleteButton
                      onDelete={async () => {
                        await deleteInvoice({
                          variables: {
                            id: invoiceId,
                          },
                        });
                        goBack();
                      }}
                    />
                  )}
                </div>
              </Container>
            </Paper>
            <BottomSubmitButtonRow
              isSubmitting={isSubmitting}
              isValid={isValid && dirty}
              pinToBottom={pinSaveToBottom}
              label={"Update Invoice"}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
