import * as React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./pages";
import MorePage from "./pages/more";
import NotificationsPage from "./pages/notifications";
import ContactSummaryPage from "./pages/contacts/[id]";
import InvoiceDetailsPage from "./pages/invoices/[id]";
import JobsHistoryPage from "./pages/jobs-history/[id]";
import JobSummaryPage from "./pages/jobs/[id]";
import * as Sentry from "@sentry/react";
import LogRocket from "logrocket";
import { FeedbackPage } from "./pages/feedback";
import PreferencesPage from "./pages/preferences";

export const Routes: React.FC<{}> = () => {
  const { user } = useAuth0();
  Sentry.setUser({ username: user.name });
  LogRocket.identify(user.name);
  return (
    <Router>
      <Switch>
        <Route path={"/"} exact component={Home} />
        <Route path={"/more"} exact component={MorePage} />
        <Route path={"/feedback"} exact component={FeedbackPage} />
        <Route path={"/contacts/:id"} component={ContactSummaryPage} />
        <Route path={"/invoices/:id"} component={InvoiceDetailsPage} />
        <Route path={"/notifications"} exact component={NotificationsPage} />
        <Route path={"/preferences"} exact component={PreferencesPage} />
        <Route path={"/jobs/:id"} component={JobSummaryPage} />
        <Route path={"/jobs-history/:id"} component={JobsHistoryPage} />
      </Switch>
    </Router>
  );
};
