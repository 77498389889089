import React from "react";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import { AddPaymentsDialog } from "../Payments/AddPaymentsDialog";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { InvoiceActionButton } from "./InvoiceActionButton";
import { useToggle } from "../../hooks/useToggle";
import { CheckCircle } from "@material-ui/icons";
import { CompleteJobDialog } from "../Payments/CompleteJobDialog";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > *": {
        marginLeft: theme.spacing(1.0),
      },
    },
  })
);

export const InvoiceStatusSection: React.FC<{
  status: string;
  invoiceId: string;
  contactId: string;
  balanceDue: string;
  refetch: any;
  laborCost: string;
  partsCost: string;
  payer: string;
  partsTax: string;
  laborTax: string;
  totalTax: string;
  subTotal: string;
}> = ({
  laborCost,
  partsCost,
  partsTax,
  laborTax,
  subTotal,
  payer,
  totalTax,
  status,
  invoiceId,
  contactId,
  balanceDue,
  refetch,
}) => {
  const classes = useStyles();
  const [addPaymentDialogStatus, , toggleAddPayment] = useToggle();
  const [completeJobDialogStatus, , toggleCompleteJob] = useToggle();
  return (
    <Paper square>
      <Box display={"flex"}>
        <Box className="py-2 px-2 w-1/2 flex flex-col justify-center">
          <Chip color={"primary"} label={status} />
        </Box>
        <Box className={classes.root + " flex-1 flex flex-row justify-end"}>
          <InvoiceActionButton invoiceId={invoiceId} invoiceStatus={status} />
          {Number(balanceDue) === 0 ? (
            <Button
              className="w-1/3"
              variant={"contained"}
              color={"secondary"}
              onClick={toggleCompleteJob}
              disabled={status === "Paid"}
            >
              <div>
                <CheckCircle />
                <Typography display={"block"} variant={"caption"}>
                  Complete Job & Invoice
                </Typography>
              </div>
            </Button>
          ) : (
            <Button
              className="w-1/3"
              variant={"contained"}
              color={"secondary"}
              onClick={toggleAddPayment}
              disabled={status === "Paid"}
            >
              <div>
                <MonetizationOnIcon />
                <Typography display={"block"} variant={"caption"}>
                  Get Paid
                </Typography>
              </div>
            </Button>
          )}
        </Box>
      </Box>
      <CompleteJobDialog
        open={completeJobDialogStatus}
        onClose={toggleCompleteJob}
        invoiceId={invoiceId}
        contactId={contactId}
        amount={balanceDue}
        refetch={refetch}
      />
      <AddPaymentsDialog
        open={addPaymentDialogStatus}
        onClose={toggleAddPayment}
        invoiceId={invoiceId}
        contactId={contactId}
        payer={payer}
        amount={balanceDue}
        laborCost={laborCost}
        partsCost={partsCost}
        partsTax={partsTax}
        laborTax={laborTax}
        subTotal={subTotal}
        totalTax={totalTax}
        refetch={refetch}
      />
    </Paper>
  );
};
