import * as React from "react";
import { FullScreenDialog } from "../FullScreenDialog";
import { UpdateInvoiceDetailsFormValues } from "../Forms/UpdateInvoiceDetailsForm";
import { invoiceDetailsSpec } from "./invoiceDetailsSpec";
import { pipe } from "ramda";
import { cleanObject } from "../../lib/functions";
import { Mutation, MutationCreateInvoiceArgs, Query, QueryGetJobArgs } from "../../generated/nest-graphql";
import { CREATE_INVOICE } from "../../graphql/mutations/createInvoice";
import { GET_JOB } from "../../graphql/queries/getJob";
import { useHistory } from "react-router-dom";
import { showSuccessAlert } from "../../actions";
import { useDispatch } from "../../contexts/snackbar-context";
import { AddInvoiceDetailsForm } from "../Forms/AddInvoiceDetailsForm";
import { useMutation } from "@apollo/client";

export const AddInvoiceDialog: React.FC<{
  initialValues?: UpdateInvoiceDetailsFormValues;
  open: boolean;
  onClose: () => void;
  jobId: string;
}> = ({ initialValues, open, onClose, jobId }) => {
  const [creatInvoice] = useMutation<Mutation, MutationCreateInvoiceArgs>(CREATE_INVOICE);
  const dispatch = useDispatch();
  const history = useHistory();
  const onSubmit = async (values: UpdateInvoiceDetailsFormValues, helpers) => {
    const input = pipe(invoiceDetailsSpec, cleanObject)(values);
    const result = await creatInvoice({
      variables: {
        // @ts-ignore
        createInvoiceInput: input,
      },
      update: (cache, { data: { createInvoice } }) => {
        const { getJob } = cache.readQuery<Query, QueryGetJobArgs>({
          variables: {
            id: jobId,
          },
          query: GET_JOB,
        });
        const objectToPlace = {
          getJob: {
            ...getJob,
            invoice: createInvoice,
          },
        };
        cache.writeQuery({
          query: GET_JOB,
          variables: {
            id: jobId,
          },
          data: objectToPlace,
        });
      },
    });
    const id = result.data.createInvoice.id;
    showSuccessAlert(dispatch, "Success");
    await history.push(`/invoices/${id}`);
  };

  return (
    <FullScreenDialog title={"Add Invoice"} open={open} onClose={onClose}>
      <AddInvoiceDetailsForm
        initialValues={
          initialValues
            ? initialValues
            : {
                status: "Draft",
                contact: null,
                serviceLocation: "",
                market: "",
                technician: null,
                items: [],
                issuedDate: new Date(),
                dueDate: new Date(),
              }
        }
        onSubmit={onSubmit}
      />
    </FullScreenDialog>
  );
};
