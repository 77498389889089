import * as React from "react";
import { Form, Formik } from "formik";
import { List, ListItemText, Paper, Typography } from "@material-ui/core";
import { ContactListItem } from "../ListItems/ContactListItem";
import { AppointmentListItem } from "../ListItems/AppointmentListItem";
import { ServiceLocationListItem } from "../ListItems/ServiceLocationListItem";
import { defaultTo, equals, prop, anyPass, isEmpty, isNil, startsWith, uniqBy, reject, pluck } from "ramda";
import ListItem from "@material-ui/core/ListItem";
import { ListItemWithSubList } from "../ListItems/ListItemWithSubList";
import { LinkListItem } from "../ListItems/LinkListItem";
import { VehicleInfoListItem } from "../ListItems/VehicleInfoListItem";
import { ItemsField } from "./fields/ItemsField";
import { FilesListItem } from "../ListItems/FilesListItem";
import { Appointment, Contact, Item, JobFeedback, PartsStore, Query } from "../../generated/nest-graphql";
import { VehicleInfoValues } from "../../types/VehicleInfoValues";
import { PreJobCheckList } from "../../types/PreJobCheckList";
import * as Yup from "yup";
import { TextAreaField } from "./fields/TextAreaField";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import { BottomSubmitButtonRow } from "../Buttons/BottomSubmitButtonRow";
import { PartsInfoListItem } from "../ListItems/PartsInfoListItem";
import { VehicleInfoSymptomsFormSection } from "./VehicleInfoSymptomsFormSection";
import { JobTypeListItem } from "../ListItems/JobTypeListItem";
import { flow, pipe } from "fp-ts/lib/function";
import { PartsStoreListItem } from "../ListItems/PartsStoreListItem";
import { useQuery } from "@apollo/client";
import { TECHNICIAN_GET_ME } from "../../graphql/queries/technicianGetMe";
import { PartsOrderingSurveyFormValues } from "./Surveys/PartsOrderingSurveyForm";

export type JobDetailsFormValues = VehicleInfoValues &
  PreJobCheckList &
  JobFeedback &
  PartsOrderingSurveyFormValues & {
    contact: Contact;
    market: string;
    taxable: boolean;
    estimate?: string;
    serviceLocation: string;
    status: string;
    type: string;
    serviceCallReason: string;
    serviceCallReasonExtraInfo: string;
    appointmentId: string;
    jobName: string;
    privateNotes?: string;
    serviceLocationNotes?: string;
    description?: string;
    items: Item[];
    partsOrderNumber?: string;
    partsLocation?: string;
    partsNotes?: string;
    partsOrdered?: boolean;
  };
const jobDetailsFormValidationSchema = Yup.object().shape({
  status: Yup.string().required("Required"),
  contact: Yup.object().required("Required"),
  serviceLocation: Yup.string().required("Required"),
  type: Yup.string().required("Required"),
  serviceCallReason: Yup.string().when("type", {
    is: flow(defaultTo(""), startsWith("Service")),
    then: Yup.string().required("Must Provide a Service Call Reason"),
    otherwise: Yup.string().nullable(),
  }),
  serviceCallReasonExtraInfo: Yup.string().when("serviceCallReason", {
    is: flow(defaultTo(""), startsWith("Other")),
    then: Yup.string().required("Must Provide Additional Details"),
    otherwise: Yup.string().nullable(),
  }),
  jobName: Yup.string().required("Required"),
});
type JobDetailsFormProps = {
  initialValues: JobDetailsFormValues;
  onSubmit: (values: any, helpers: any) => void;
  appointment: Appointment;
  fileNames: string[];
};

export const JobDetailsForm: React.FC<JobDetailsFormProps> = ({ initialValues, onSubmit, appointment, fileNames }) => {
  const { data: technicianGetMeData } = useQuery<Query>(TECHNICIAN_GET_ME);
  return (
    <Formik<JobDetailsFormValues>
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={jobDetailsFormValidationSchema}
      onSubmit={onSubmit}
    >
      {({ isValid, isSubmitting, setFieldValue, values, submitForm, dirty }) => {
        const {
          contact,
          description,
          jobName,
          serviceLocation,
          serviceLocationNotes,
          type,
          year,
          make,
          model,
          customerExpectation,
          items,
          partsOrderNumber,
          partsLocation,
          partsNotes,
        } = values;
        const partsStores = pipe(
          pluck("partsStore", items),
          reject(anyPass([isEmpty, isNil])) as () => PartsStore[],
          uniqBy(prop("id"))
        );
        const contactId: string = prop("id", contact);
        return (
          <Form
            // padding bottom to offset save button
            className="pb-4"
          >
            <Paper className={"pb-3"}>
              <List>
                <ContactListItem contact={values.contact} />
                {appointment && <AppointmentListItem appointment={appointment} />}
                <ServiceLocationListItem address={serviceLocation} notes={serviceLocationNotes} />
                <LinkListItem divider={true} title="Job History" to={`/jobs-history/${contactId}`} />
                <JobTypeListItem
                  jobType={type}
                  parentSubmitForm={submitForm}
                  parentSubmitting={isSubmitting}
                  isValid={isValid && dirty}
                  values={values}
                />
                <ListItemWithSubList arrow={false} divider={false} title={"Job Name"} secondaries={[jobName]} />
                <ListItemWithSubList arrow={false} divider={true} title={"Description"} secondaries={[description]} />
                <VehicleInfoListItem
                  {...{ year, make, model }}
                  parentSetFieldValue={setFieldValue}
                  parentSubmitForm={submitForm}
                  parentSubmitting={isSubmitting}
                  isValid={isValid && dirty}
                />
                <VehicleInfoSymptomsFormSection />
                {customerExpectation && (
                  <ListItemWithSubList
                    arrow={false}
                    divider={true}
                    title={"Customer Expectation for Repairs"}
                    secondaries={[customerExpectation]}
                  />
                )}
                {values.partsOrderNumber && (
                  <PartsInfoListItem
                    partsOrderNumber={partsOrderNumber}
                    partsLocation={partsLocation}
                    partsNotes={partsNotes}
                  />
                )}
                {!isEmpty(partsStores) && (
                  <>
                    <ListItem divider={false}>
                      <ListItemText
                        primary={<Typography style={{ fontWeight: "bold" }}>Parts Store(s)</Typography>}
                        secondary={partsStores.map((partsStore: any, idx: string | number | undefined) => {
                          return (
                            <PartsStoreListItem
                              key={idx}
                              partsStore={partsStore}
                              isHomePartsStore={equals(
                                (partsStore as any)?.id,
                                technicianGetMeData?.technicianGetMe?.homePartsStore?.id
                              )}
                            />
                          );
                        })}
                      />
                    </ListItem>
                  </>
                )}
                <Container>
                  <div className="grid gap-4 pt-2">
                    <ItemsField
                      additionalPartsInfoRenderer={({ partsStore }) => {
                        if (partsStore) {
                          const homePartsStore = technicianGetMeData?.technicianGetMe?.homePartsStore;
                          return !homePartsStore || equals(partsStore.id, homePartsStore?.id) ? (
                            <Typography component={"span"} className={"block"} variant={"subtitle1"}>
                              {partsStore.vendor} {partsStore.name}
                            </Typography>
                          ) : (
                            <Typography
                              component={"span"}
                              className={"block"}
                              variant={"subtitle1"}
                              style={{ color: "#f04e23" }}
                            >
                              {partsStore.vendor} {partsStore.name}
                            </Typography>
                          );
                        }
                      }}
                      parentSubmit={submitForm}
                      setFieldValue={setFieldValue}
                      name={"items"}
                      editable={false}
                    />
                  </div>
                </Container>
                <Divider />
                <ListItem divider={true}>
                  <TextAreaField name="privateNotes" label={"Private Notes"} />
                </ListItem>
                <FilesListItem divider={false} contactId={contactId} numFiles={fileNames.length} />
              </List>
            </Paper>
            <BottomSubmitButtonRow label={"Update Job"} isValid={isValid && dirty} isSubmitting={isSubmitting} />
          </Form>
        );
      }}
    </Formik>
  );
};
