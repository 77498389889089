import * as React from "react";
import { Field, FieldArray, Form, Formik } from "formik";
import { Box, Paper } from "@material-ui/core";
import { TextAreaField } from "../fields/TextAreaField";
import Container from "@material-ui/core/Container";
import { isEmpty, isNil } from "ramda";
import * as Yup from "yup";
import { YesNoField } from "../fields/YesNoField";
import { CheckboxesField, CheckboxesFieldOption } from "../fields/CheckboxesField";
import { PartsStore } from "../../../generated/nest-graphql";
import { SubmitButton } from "../../Buttons/SubmitButton";

export type PartsOrderingSurveyFormValues = {
  partsCorrect?: boolean;
  partsStores?: string[];
  partsIssues?: string[];
  additionalDetails?: string;
};

type PartsOrderingSurveyFormProps = {
  initialValues: PartsOrderingSurveyFormValues;
  onSubmit: any;
  partsStores: PartsStore[];
};

const partIssueOptions: CheckboxesFieldOption[] = [
  { label: "Wrong parts ordered by us", value: "wrongPartsOrderedByUs" },
  { label: "Some parts not ordered", value: "somePartsNotOrdered" },
  { label: "Store never received order", value: "storeNeverReceivedOrder" },
  { label: "Wrong parts ordered by store", value: "wrongPartsOrderedByStore" },
  { label: "Unnecessary parts ordered", value: "unnecessaryPartsOrdered" },
  { label: "Parts misboxed", value: "partsMixboxed" },
  { label: "Order placed too late", value: "unnecessaryPartsOrdered" },
  { label: "Parts not pulled in time", value: "partsNotPulledInTime" },
  { label: "AA/OR substituted parts", value: "aa/orSubstitutedParts" },
];

const partsIssueValidationSchema = Yup.object().shape({
  partsCorrect: Yup.boolean().required("Required"),
  partsStores: Yup.array()
    .of(Yup.string())
    .when("partsCorrect", (partsCorrect, schema) => {
      return partsCorrect ? schema.nullable() : schema.required("Must have an issue").min(1, "Minimum of 1 issue");
    }),
  partsIssues: Yup.array()
    .of(Yup.string())
    .when("partsCorrect", (partsCorrect, schema) => {
      return partsCorrect ? schema.nullable() : schema.required("Must have an issue").min(1, "Minimum of 1 issue");
    }),
  additionalDetails: Yup.string().nullable(),
});

export const PartsOrderingSurveyForm: React.FC<PartsOrderingSurveyFormProps> = ({
  initialValues,
  onSubmit,
  partsStores,
}) => {
  const partsStoreOptions = partsStores.map((partsStore) => ({
    label: `${partsStore.vendor} - ${partsStore.name}`,
    value: `${partsStore.id}`,
  }));
  return (
    <Formik<PartsOrderingSurveyFormValues>
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={partsIssueValidationSchema}
      onSubmit={onSubmit}
    >
      {({ isValid, isSubmitting, values, errors, dirty, setValues }) => {
        return (
          <Form>
            <Paper>
              <Container className={"py-4"}>
                <div className="grid gap-4">
                  <Box fontWeight="fontWeightBold" fontSize="h6.fontSize">
                    Were all of the parts available, on time, and correct?
                  </Box>
                  <Field name={"partsCorrect"}>
                    {({ field, meta, form }: any) => (
                      <YesNoField
                        name={"partsCorrect"}
                        onChange={(event) => {
                          if (event.target.value === "true") {
                            setValues({
                              ...values,
                              partsCorrect: true,
                              partsStores: [],
                              partsIssues: [],
                              additionalDetails: null,
                            });
                          } else {
                            const newValues = { ...values, partsCorrect: false };
                            setValues(newValues);
                            // make sure the parts stores get set if there is only 1 because we hide the UI in that case
                            if (partsStores.length === 1) {
                              setValues({ ...newValues, partsStores: [partsStores[0].id] });
                            }
                          }
                        }}
                        value={field.value}
                        error={meta.error}
                        errorText={form.errors[field.name]}
                        yesLabel="Yep!"
                        noLabel="Nope"
                      />
                    )}
                  </Field>
                  {values.partsCorrect === false && (
                    <>
                      {(partsStores.length > 1 || values.partsStores?.length === 0) && (
                        <>
                          <Box fontWeight="fontWeightBold" fontSize="h6.fontSize">
                            Which parts store(s) is the issue related to?
                          </Box>
                          <FieldArray
                            name={"partsStores"}
                            render={(arrayHelpers) => {
                              const partsStoresError = !isNil(errors?.partsStores) && !isEmpty(errors?.partsStores);
                              return (
                                <>
                                  <CheckboxesField
                                    name={"partsStores"}
                                    options={partsStoreOptions}
                                    values={values.partsStores}
                                    onChange={(event) => {
                                      if (event.target.checked) {
                                        arrayHelpers.push(event.target.value);
                                      } else {
                                        const idx = values.partsStores.indexOf(event.target.value);
                                        arrayHelpers.remove(idx);
                                      }
                                    }}
                                    error={partsStoresError}
                                  />
                                </>
                              );
                            }}
                          />
                        </>
                      )}
                      <Box fontWeight="fontWeightBold" fontSize="h6.fontSize">
                        What was wrong with the parts?
                      </Box>
                      <FieldArray
                        name={"partsIssues"}
                        render={(arrayHelpers) => {
                          const partsIssuesError = !isNil(errors?.partsIssues) && !isEmpty(errors?.partsIssues);
                          return (
                            <>
                              <CheckboxesField
                                name={"partsIssues"}
                                options={partIssueOptions}
                                values={values.partsIssues}
                                onChange={(event) => {
                                  if (event.target.checked) {
                                    arrayHelpers.push(event.target.value);
                                  } else {
                                    const idx = values.partsIssues.indexOf(event.target.value);
                                    arrayHelpers.remove(idx);
                                  }
                                }}
                                error={partsIssuesError}
                              />
                            </>
                          );
                        }}
                      />
                      <Box fontWeight="fontWeightBold" fontSize="h6.fontSize">
                        Can you explain what happened?
                      </Box>
                      <TextAreaField
                        name="additionalDetails"
                        label={"Please provide details so that we can improve next time!"}
                      />
                    </>
                  )}
                </div>
              </Container>
            </Paper>
            <Box
              mt={2}
              mb={2}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              style={{ backgroundColor: "black" }}
            >
              <SubmitButton isSubmitting={isSubmitting} isValid={isValid && dirty} label={"Submit"} />
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};
