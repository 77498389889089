import { isNil, keys, path, pathOr, pipe, pluck, prop, find, pathEq, complement, equals } from "ramda";
import React from "react";
import { AppBar } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { GET_JOB } from "../../graphql/queries/getJob";
import {
  Mutation,
  MutationUpdateJobArgs,
  MutationCreateInvoiceArgs,
  Query,
  QueryGetJobArgs,
  Item,
  MutationUpdateInvoiceArgs,
  CreateInvoiceInput,
} from "../../generated/nest-graphql";
import { useMutation, useQuery } from "@apollo/client";
import { Layout } from "../../components/Layout";
import { JobStatusSection } from "../../components/Jobs/JobStatusSection";
import { JobDetailsForm, JobDetailsFormValues } from "../../components/Forms/JobDetailsForm";
import { cleanObject, objectDiff } from "../../lib/functions";
import { jobDetailsSpec } from "../../components/Jobs/jobDetailsSpec";
import { UPDATE_JOB } from "../../graphql/mutations/updateJob";
import { useHistory } from "react-router-dom";
import { useEntityId } from "../../hooks/useEntityId";
import { showSuccessAlert } from "../../actions";
import { useDispatch } from "../../contexts/snackbar-context";
import { UpdateInvoiceDetailsFormValues } from "../../components/Forms/UpdateInvoiceDetailsForm";
import { CREATE_INVOICE } from "../../graphql/mutations/createInvoice";
import { GET_PRODUCTS } from "../../graphql/queries/getProducts";
import { invoiceDetailsSpec } from "../../components/Invoices/invoiceDetailsSpec";
import { UPDATE_INVOICE } from "../../graphql/mutations/updateInvoice";

const JobDetailsAppBar: React.FC<{
  addInvoiceInitialValues: UpdateInvoiceDetailsFormValues;
  message: string;
  phoneNumber: string;
  jobNumber: string;
  jobId: string;
  status: string;
  invoiceId: string;
}> = ({ jobId, jobNumber, status, invoiceId, addInvoiceInitialValues, message, phoneNumber }) => {
  const { goBack } = useHistory();
  return (
    <AppBar position={"sticky"}>
      <Toolbar>
        <IconButton onClick={goBack}>
          <ArrowBackIosIcon />
        </IconButton>
        <Typography variant="h6">{`Job: ${jobNumber}`}</Typography>
        <Box />
      </Toolbar>
      <JobStatusSection
        message={message}
        phoneNumber={phoneNumber}
        jobId={jobId}
        status={status}
        invoiceId={invoiceId}
        addInvoiceInitialValues={addInvoiceInitialValues}
      />
    </AppBar>
  );
};

const checkListItemDefault = { partCondition: "" };

const JobSummaryPage = () => {
  const jobId = useEntityId();
  const dispatch = useDispatch();
  const { data } = useQuery<Query, QueryGetJobArgs>(GET_JOB, {
    variables: {
      id: jobId,
    },
    returnPartialData: true,
  });
  const products = useQuery<Query, {}>(GET_PRODUCTS);
  const [createInvoice] = useMutation<Mutation, MutationCreateInvoiceArgs>(CREATE_INVOICE);
  const [updateInvoice] = useMutation<Mutation, MutationUpdateInvoiceArgs>(UPDATE_INVOICE);
  const [updateJob] = useMutation<Mutation, MutationUpdateJobArgs>(UPDATE_JOB);
  if (!prop("getJob", data)) return null;
  const job = data.getJob;
  const { market, contact, serviceLocation, items, vehicleInfo, privateNotes } = job;
  const initialValues: JobDetailsFormValues = {
    type: job.type,
    serviceCallReason: pathOr("", ["serviceCallReason"], job),
    serviceCallReasonExtraInfo: pathOr("", ["serviceCallReasonExtraInfo"], job),
    appointmentId: null,
    taxable: job?.taxable,
    status: job.status,
    market: job.market,
    jobName: job.jobName,
    year: pathOr("", ["vehicleInfo", "year"], job),
    vin: pathOr("", ["vehicleInfo", "vin"], job),
    rearPadLife: pathOr("", ["vehicleInfo", "rearPadLife"], job),
    odometer: pathOr("", ["vehicleInfo", "odometer"], job),
    model: pathOr("", ["vehicleInfo", "model"], job),
    make: pathOr("", ["vehicleInfo", "make"], job),
    licensePlate: pathOr("", ["vehicleInfo", "licensePlate"], job),
    frontPadLife: pathOr("", ["vehicleInfo", "frontPadLife"], job),
    extraInfo: pathOr("", ["vehicleInfo", "extraInfo"], job),
    symptoms: pathOr("", ["vehicleInfo", "symptoms"], job),
    frontBrakeSymptoms: pathOr([], ["vehicleInfo", "frontBrakeSymptoms"], job),
    rearBrakeSymptoms: pathOr([], ["vehicleInfo", "rearBrakeSymptoms"], job),
    additionalNotes: pathOr("", ["vehicleInfo", "additionalNotes"], job),
    customerExpectation: pathOr("", ["vehicleInfo", "customerExpectation"], job),
    partsOrderNumber: pathOr("", ["partsInfo", "partsOrderNumber"], job),
    partsOrdered: pathOr(false, ["partsInfo", "partsOrdered"], job),
    partsLocation: pathOr("", ["partsInfo", "partsLocation"], job),
    partsNotes: pathOr("", ["partsInfo", "partsNotes"], job),
    frontPads: pathOr(checkListItemDefault, ["preJobCheckList", "frontPads"], job),
    frontRotors: pathOr(checkListItemDefault, ["preJobCheckList", "frontRotors"], job),
    rearPads: pathOr(checkListItemDefault, ["preJobCheckList", "rearPads"], job),
    rearRotors: pathOr(checkListItemDefault, ["preJobCheckList", "rearRotors"], job),
    brakeFluid: pathOr(checkListItemDefault, ["preJobCheckList", "brakeFluid"], job),
    brakeShoes: pathOr(checkListItemDefault, ["preJobCheckList", "brakeShoes"], job),
    brakeDrums: pathOr(checkListItemDefault, ["preJobCheckList", "brakeDrums"], job),
    calipers: pathOr(checkListItemDefault, ["preJobCheckList", "calipers"], job),
    shocksStruts: pathOr(checkListItemDefault, ["preJobCheckList", "shocksStruts"], job),
    controlArms: pathOr(checkListItemDefault, ["preJobCheckList", "controlArms"], job),
    wheelHubs: pathOr(checkListItemDefault, ["preJobCheckList", "wheelHubs"], job),
    sparkPlugs: pathOr(checkListItemDefault, ["preJobCheckList", "sparkPlugs"], job),
    otherBrakeParts: pathOr(checkListItemDefault, ["preJobCheckList", "otherBrakeParts"], job),
    dashboardLights: pathOr(checkListItemDefault, ["preJobCheckList", "dashboardLights"], job),
    serviceLocation: job.serviceLocation,
    contact: job.contact,
    description: job.description,
    // @ts-ignore
    items: job.items,
    privateNotes: job.privateNotes,
    serviceLocationNotes: job.serviceLocationNotes,
  };
  const jobNumber: string = prop("jobNumber", job);
  const invoiceInitialValues: UpdateInvoiceDetailsFormValues = {
    market,
    jobNumber,
    status: "Draft",
    taxable: job?.taxable,
    contact,
    serviceLocation,
    estimate: path(["estimate", "id"], job),
    technician: path(["appointment", "technician"], job),
    items,
    issuedDate: new Date(),
    dueDate: new Date(),
    ...vehicleInfo,
    privateNotes,
    jobId,
    frontPads: pathOr(checkListItemDefault, ["preJobCheckList", "frontPads"], job),
    frontRotors: pathOr(checkListItemDefault, ["preJobCheckList", "frontRotors"], job),
    rearPads: pathOr(checkListItemDefault, ["preJobCheckList", "rearPads"], job),
    rearRotors: pathOr(checkListItemDefault, ["preJobCheckList", "rearRotors"], job),
    brakeFluid: pathOr(checkListItemDefault, ["preJobCheckList", "brakeFluid"], job),
    brakeShoes: pathOr(checkListItemDefault, ["preJobCheckList", "brakeShoes"], job),
    brakeDrums: pathOr(checkListItemDefault, ["preJobCheckList", "brakeDrums"], job),
    calipers: pathOr(checkListItemDefault, ["preJobCheckList", "calipers"], job),
    shocksStruts: pathOr(checkListItemDefault, ["preJobCheckList", "shocksStruts"], job),
    controlArms: pathOr(checkListItemDefault, ["preJobCheckList", "controlArms"], job),
    wheelHubs: pathOr(checkListItemDefault, ["preJobCheckList", "wheelHubs"], job),
    sparkPlugs: pathOr(checkListItemDefault, ["preJobCheckList", "sparkPlugs"], job),
    otherBrakeParts: pathOr(checkListItemDefault, ["preJobCheckList", "otherBrakeParts"], job),
    dashboardLights: pathOr(checkListItemDefault, ["preJobCheckList", "dashboardLights"], job),
  };
  const firstName = path(["contact", "firstName"], job);
  const technicianFirstName = path(["appointment", "technician", "firstName"], job);
  const appointment = prop("appointment", job);
  const invoiceId = path<string>(["invoice", "id"], job);
  const jobStatus = prop("status", job);
  // @ts-ignore
  const fileNames: string[] = pipe(pathOr([], ["contact", "files"]), pluck("fileNames"))(job);
  const createServiceCallInvoiceInput = () => {
    const serviceCallProduct = pipe(prop("getProducts"), find(pathEq(["name"], "Service Call")))(products.data);
    const serviceJobItem: Item = {
      ...serviceCallProduct,
      isInEstimate: false,
      product: path(["id"], serviceCallProduct),
    };
    const invoiceValues: UpdateInvoiceDetailsFormValues = {
      ...invoiceInitialValues,
      items: [serviceJobItem],
    };
    return pipe(invoiceDetailsSpec, cleanObject)(invoiceValues);
  };
  const onSubmit = async (values: JobDetailsFormValues) => {
    const diff = objectDiff(values, initialValues);
    const type = prop("type", diff);
    const exists = complement(isNil);
    // create or update an invoice for a service type job
    if (exists(type) && equals(type, "Service Call")) {
      const input = await createServiceCallInvoiceInput();
      if (isNil(invoiceId)) {
        await createInvoice({
          variables: {
            createInvoiceInput: input as CreateInvoiceInput,
          },
          update: (cache, { data: { createInvoice } }) => {
            const { getJob } = cache.readQuery<Query, QueryGetJobArgs>({
              variables: {
                id: jobId,
              },
              query: GET_JOB,
            });
            const objectToPlace = {
              getJob: {
                ...getJob,
                invoice: createInvoice,
              },
            };
            cache.writeQuery({
              query: GET_JOB,
              variables: {
                id: jobId,
              },
              data: objectToPlace,
            });
          },
        });
      } else {
        await updateInvoice({
          variables: {
            id: invoiceId,
            updateInvoiceInput: input,
          },
        });
      }
    }
    // @ts-ignore
    const updates = pipe(jobDetailsSpec, cleanObject)(diff);
    if (keys(updates).length) {
      await updateJob({
        variables: {
          id: jobId,
          updateJobInput: updates,
        },
      });
      showSuccessAlert(dispatch, "Success");
    }
  };

  return (
    <Layout
      appBar={
        <JobDetailsAppBar
          addInvoiceInitialValues={invoiceInitialValues}
          jobNumber={jobNumber}
          jobId={jobId}
          phoneNumber={path(["contact", "phoneNumber"], job)}
          message={`Hi ${firstName}, This is ${technicianFirstName} with NuBrakes. I'm on my way!`}
          status={jobStatus}
          invoiceId={invoiceId}
        />
      }
    >
      <JobDetailsForm
        onSubmit={onSubmit}
        initialValues={initialValues}
        fileNames={fileNames}
        appointment={appointment}
      />
    </Layout>
  );
};

export default JobSummaryPage;
