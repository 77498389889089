import * as React from "react";
import { useField } from "formik";
import Fab from "@material-ui/core/Fab";
import { ThumbUp, ThumbDown, ThumbsUpDown, DomainDisabled, Error, ErrorOutline } from "@material-ui/icons";

import makeStyles from "@material-ui/core/styles/makeStyles";
import { red, green, yellow, grey } from "@material-ui/core/colors";

const useStyles = makeStyles({
  options: {
    position: "fixed",
    paddingLeft: 0,
    top: 65,
    zIndex: 3,
  },
  up: {
    backgroundColor: green[500],
    marginRight: 25,
    "&:focus": {
      backgroundColor: green[500],
    },
  },
  down: {
    backgroundColor: red[500],
    marginRight: 25,
    "&:focus": {
      backgroundColor: red[500],
    },
  },
  upDown: {
    backgroundColor: yellow[500],
    marginRight: 25,
    "&:focus": {
      backgroundColor: yellow[500],
    },
  },
  na: {
    backgroundColor: grey[500],
    "&:focus": {
      backgroundColor: grey[500],
    },
  },
});

export const partConditionOptions = [
  "Green (Good condition)",
  "Yellow (Has wear, not in need of immediate repair)",
  "Red (Needs immediate repair)",
  "N/A (Not applicable to vehicle)",
];

export const dashboardLightsOptions = ["Warning lights off", "Warning lights on"];

export const PartConditionOptions: React.FC<{
  name: string;
  setOptionName: (name: string) => void;
}> = ({ name, setOptionName }) => {
  const classes = useStyles();
  const [, , helper] = useField(`${name}.partCondition`);
  const handleClick = (value: string) => {
    helper.setValue(value);
    setOptionName(null);
  };
  if (!name) {
    return null;
  }
  return (
    <div className={classes.options + " h-16 w-full flex justify-center"}>
      {name !== "dashboardLights" ? (
        <>
          <Fab className={classes.up} onClick={() => handleClick(partConditionOptions[0])}>
            <ThumbUp />
          </Fab>
          <Fab className={classes.upDown} onClick={() => handleClick(partConditionOptions[1])}>
            <ThumbsUpDown />
          </Fab>
          <Fab className={classes.down} onClick={() => handleClick(partConditionOptions[2])}>
            <ThumbDown />
          </Fab>
          <Fab className={classes.na} onClick={() => handleClick(partConditionOptions[3])}>
            <DomainDisabled />
          </Fab>
        </>
      ) : (
        <>
          <Fab className={classes.up} onClick={() => handleClick(dashboardLightsOptions[0])}>
            <ErrorOutline />
          </Fab>
          <Fab className={classes.down} onClick={() => handleClick(dashboardLightsOptions[1])}>
            <Error />
          </Fab>
        </>
      )}
    </div>
  );
};
