import { createMuiTheme } from "@material-ui/core";

export const darkTheme = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#f04e23",
    },
    background: {
      default: "#000000",
    },
  },
});
