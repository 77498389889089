import { keys, path, pathOr, prop, props, anyPass, isEmpty, isNil, pluck, reject, uniqBy } from "ramda";
import React, { useEffect } from "react";
import {
  Mutation,
  MutationUpdateInvoiceArgs,
  MutationUpdateJobArgs,
  PartsStore,
  Query,
  QueryGetInvoiceArgs,
} from "../../generated/nest-graphql";
import { GET_INVOICE } from "../../graphql/queries/getInvoice";
import { useMutation, useQuery } from "@apollo/client";
import Paper from "@material-ui/core/Paper";
import { Layout } from "../../components/Layout";
import { UPDATE_INVOICE } from "../../graphql/mutations/updateInvoice";
import { cleanObject, objectDiff } from "../../lib/functions";
import { InvoiceDetailsAppBar } from "../../components/Invoices/InvoiceDetailsAppBar";
import {
  UpdateInvoiceDetailsForm,
  UpdateInvoiceDetailsFormValues,
} from "../../components/Forms/UpdateInvoiceDetailsForm";
import { invoiceDetailsSpec } from "../../components/Invoices/invoiceDetailsSpec";
import { useEntityId } from "../../hooks/useEntityId";
import { showSuccessAlert } from "../../actions";
import { useDispatch } from "../../contexts/snackbar-context";
import { useToggle } from "../../hooks/useToggle";
import {
  PartsOrderingSurveyForm,
  PartsOrderingSurveyFormValues,
} from "../../components/Forms/Surveys/PartsOrderingSurveyForm";
import { FullScreenDialog } from "../../components/FullScreenDialog";
import { pipe } from "fp-ts/lib/function";
import { TECHNICIAN_GET_ME } from "../../graphql/queries/technicianGetMe";
import { jobDetailsSpec } from "../../components/Jobs/jobDetailsSpec";
import { UPDATE_JOB } from "../../graphql/mutations/updateJob";
import { useHistory } from "react-router-dom";
import { isToday } from "date-fns";

const InvoiceDetailsPage = () => {
  const [open, setOpen] = useToggle();
  const history = useHistory();
  const invoiceId = useEntityId();
  const { data, refetch } = useQuery<Query, QueryGetInvoiceArgs>(GET_INVOICE, {
    variables: {
      id: invoiceId,
    },
  });
  const { data: technicianGetMeData } = useQuery<Query>(TECHNICIAN_GET_ME);
  const dispatch = useDispatch();
  const [updateInvoice] = useMutation<Mutation, MutationUpdateInvoiceArgs>(UPDATE_INVOICE);
  const [updateJob] = useMutation<Mutation, MutationUpdateJobArgs>(UPDATE_JOB);
  const invoice = prop("getInvoice", data);
  const [laborCost, partsCost, partsTax, laborTax, subTotal, totalTax] = props(
    ["laborCost", "partsCost", "partsTax", "laborTax", "subTotal", "totalTax"],
    invoice as any
  );
  useEffect(() => {
    if (invoice) {
      const partsOrderingIssuesSurvey = path(["job", "partsOrderingIssuesSurvey"], invoice);
      const invoiceIssuedDate = new Date(invoice.issuedDate);
      if (invoice.status === "Paid" && partsOrderingIssuesSurvey === null && isToday(invoiceIssuedDate)) {
        setOpen(true);
      }
    }
  }, [invoice, setOpen]);
  if (!invoice) return null;
  const contactId = path<string>(["contact", "id"], invoice);
  const jobId = path<string>(["job", "id"], invoice);
  const initialValues: UpdateInvoiceDetailsFormValues = {
    contact: invoice.contact,
    serviceLocation: invoice.serviceLocation,
    status: invoice.status,
    items: invoice.items,
    market: invoice.market,
    technician: invoice.technician,
    privateNotes: invoice.privateNotes,
    customerMessage: invoice.customerMessage,
    issuedDate: invoice.issuedDate,
    dueDate: invoice.dueDate,
    taxable: invoice.taxable,
    jobId,
    jobNumber: path<string>(["job", "jobNumber"], invoice),
    year: pathOr("", ["vehicleInfo", "year"], invoice),
    extraInfo: pathOr("", ["vehicleInfo", "extraInfo"], invoice),
    symptoms: pathOr("", ["vehicleInfo", "symptoms"], invoice),
    vin: pathOr("", ["vehicleInfo", "vin"], invoice),
    rearPadLife: pathOr("", ["vehicleInfo", "rearPadLife"], invoice),
    odometer: pathOr("", ["vehicleInfo", "odometer"], invoice),
    model: pathOr("", ["vehicleInfo", "model"], invoice),
    make: pathOr("", ["vehicleInfo", "make"], invoice),
    licensePlate: pathOr("", ["vehicleInfo", "licensePlate"], invoice),
    frontPadLife: pathOr("", ["vehicleInfo", "frontPadLife"], invoice),
    frontPads: pathOr({}, ["preJobCheckList", "frontPads"], invoice),
    frontRotors: pathOr({}, ["preJobCheckList", "frontRotors"], invoice),
    rearPads: pathOr({}, ["preJobCheckList", "rearPads"], invoice),
    rearRotors: pathOr({}, ["preJobCheckList", "rearRotors"], invoice),
    brakeFluid: pathOr({}, ["preJobCheckList", "brakeFluid"], invoice),
    brakeShoes: pathOr({}, ["preJobCheckList", "brakeShoes"], invoice),
    brakeDrums: pathOr({}, ["preJobCheckList", "brakeDrums"], invoice),
    calipers: pathOr({}, ["preJobCheckList", "calipers"], invoice),
    shocksStruts: pathOr({}, ["preJobCheckList", "shocksStruts"], invoice),
    controlArms: pathOr({}, ["preJobCheckList", "controlArms"], invoice),
    wheelHubs: pathOr({}, ["preJobCheckList", "wheelHubs"], invoice),
    sparkPlugs: pathOr({}, ["preJobCheckList", "sparkPlugs"], invoice),
    otherBrakeParts: pathOr({}, ["preJobCheckList", "otherBrakeParts"], invoice),
    dashboardLights: pathOr({}, ["preJobCheckList", "dashboardLights"], invoice),
  };

  const partsStores = pipe(
    pluck("partsStore", invoice.items),
    // adds the home part store in case its not there
    (stores) => {
      const homePartsStore = technicianGetMeData?.technicianGetMe?.homePartsStore;
      if (homePartsStore) {
        stores.push(homePartsStore);
      }
      return stores;
    },
    reject(anyPass([isEmpty, isNil])) as () => PartsStore[],
    uniqBy(prop("id")),
    (stores) => {
      const noPartsStoreFound: PartsStore = { vendor: "NA", name: "No parts store found", id: "", storeNumber: "" };
      return anyPass([isEmpty, isNil])(stores) ? [noPartsStoreFound] : stores;
    }
  );

  const onSubmit = async (values: UpdateInvoiceDetailsFormValues, _formikHelkpers) => {
    const diff = objectDiff(values, initialValues);

    // @ts-ignore
    const updates = invoiceDetailsSpec(diff);
    const cleanedUpdates = cleanObject(updates);
    if (keys(cleanedUpdates).length) {
      await updateInvoice({
        variables: {
          id: invoiceId,
          updateInvoiceInput: cleanedUpdates,
        },
      });
      showSuccessAlert(dispatch, "Success");
    }
  };
  const onPartsSurveySubmit = async (values: PartsOrderingSurveyFormValues, helpers) => {
    const updates = pipe(values, jobDetailsSpec, cleanObject);
    if (keys(updates).length) {
      await updateJob({
        variables: {
          id: jobId,
          updateJobInput: updates,
        },
      });
      showSuccessAlert(dispatch, "Thanks for your feedback");
      history.push("/");
    }
  };

  const invoiceNumber = prop("invoiceNumber", invoice);
  return (
    <Layout
      appBar={
        <InvoiceDetailsAppBar
          laborCost={laborCost}
          partsCost={partsCost}
          partsTax={partsTax}
          laborTax={laborTax}
          payer={invoice.contact?.fullName ?? "Unknown"}
          subTotal={subTotal}
          totalTax={totalTax}
          invoiceNumber={invoiceNumber}
          status={prop("status", invoice)}
          invoiceId={invoiceId}
          contactId={contactId}
          balanceDue={prop("balanceDue", invoice)}
          refetch={refetch}
        />
      }
    >
      <Paper>
        <UpdateInvoiceDetailsForm
          pinSaveToBottom={false}
          initialValues={initialValues}
          onSubmit={onSubmit}
          invoiceId={invoiceId}
          itemsEditable={invoice.status === "Draft"}
          amountPaid={prop("amountPaid", invoice)}
          balanceDue={prop("balanceDue", invoice)}
        />
      </Paper>
      <FullScreenDialog title={"Help us improve!"} open={open}>
        <PartsOrderingSurveyForm
          initialValues={{
            partsCorrect: null,
            partsIssues: [],
            additionalDetails: "",
            partsStores: [],
          }}
          onSubmit={onPartsSurveySubmit}
          partsStores={partsStores}
        />
      </FullScreenDialog>
    </Layout>
  );
};

export default InvoiceDetailsPage;
