import * as React from "react";
import { useFormikContext } from "formik";
import { DateInput } from "../Forms/fields/DateInput";
import { TextField } from "../Forms/fields/TextField";
import { CheckboxField } from "../Forms/fields/CheckboxField";
import CurrencyInput from "../Forms/fields/CurrencyInput";
import { CancelButton } from "../Buttons/CancelButton";
import { SubmitButton } from "../Buttons/SubmitButton";
import { AddPaymentFormValues } from "../Forms/AddPaymentForm";

export const CashAndCheckFields: React.FC = () => {
  const { isSubmitting, isValid, resetForm } = useFormikContext<AddPaymentFormValues>();
  return (
    <div className="grid gap-4">
      <DateInput name={"receivedDate"} label={"Received Date"} required={true} />
      <CurrencyInput name={"amount"} label={"Amount"} readOnly={true} required={true} />
      <TextField name={"refNumber"} label={"Ref #/Check #"} />
      <TextField name={"memo"} label={"Memo"} />
      <CheckboxField name={"sendReceipt"} label={"Send Receipt?"} />
      <CancelButton onClick={() => resetForm()} />
      <SubmitButton isSubmitting={isSubmitting} isValid={isValid} />
    </div>
  );
};
