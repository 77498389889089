import { JobDetailsFormValues } from "../Forms/JobDetailsForm";
import { map, prop } from "ramda";
import { Item, ItemInput } from "../../generated/nest-graphql";

export const itemsSpec = (items: readonly Item[]) =>
  map((item: Item): ItemInput => {
    const { product, partsCost, partsStore, amount, description, laborCost, name, isInEstimate, partNumber } = item;
    return {
      product: product,
      partsCost,
      partNumber,
      partsStore: partsStore?.id,
      isInEstimate,
      name,
      laborCost,
      description,
      amount,
    };
  }, items);

export const preJobCheckListItemSpec = (checkListItem: any) => {
  const { partCondition, additionalInfo } = checkListItem;
  return {
    partCondition,
    additionalInfo,
  };
};

export const jobDetailsSpec = (formData: JobDetailsFormValues) => {
  const {
    contact,
    partsLocation,
    partsOrdered,
    estimate,
    partsOrderNumber,
    extraInfo,
    symptoms,
    frontPadLife,
    licensePlate,
    make,
    model,
    odometer,
    rearPadLife,
    vin,
    market,
    partsNotes,
    year,
    appointmentId,
    description,
    items,
    jobName,
    privateNotes,
    serviceLocation,
    serviceLocationNotes,
    type,
    serviceCallReason,
    serviceCallReasonExtraInfo,
    status,
    frontBrakeSymptoms,
    rearBrakeSymptoms,
    additionalNotes,
    rating,
    doneBetter,
    additionalComments,
    partsCorrect,
    partsStores,
    partsIssues,
    additionalDetails,
    frontPads,
    frontRotors,
    rearPads,
    rearRotors,
    brakeFluid,
    brakeShoes,
    brakeDrums,
    calipers,
    shocksStruts,
    controlArms,
    wheelHubs,
    sparkPlugs,
    otherBrakeParts,
    dashboardLights,
  } = formData;
  return {
    contact: prop("id", contact),
    feedback: rating
      ? {
          // @ts-ignore
          rating: parseInt(rating),
          doneBetter,
          additionalComments,
        }
      : null,
    partsOrderingIssuesSurvey: {
      partsCorrect: partsCorrect,
      partsStores: partsStores,
      partsIssues: partsIssues,
      additionalDetails: additionalDetails,
    },
    description,
    items: items && itemsSpec(items),
    jobName,
    estimate,
    market,
    appointment: appointmentId,
    preJobCheckList: {
      frontPads: frontPads && preJobCheckListItemSpec(frontPads),
      frontRotors: frontRotors && preJobCheckListItemSpec(frontRotors),
      rearPads: rearPads && preJobCheckListItemSpec(rearPads),
      rearRotors: rearRotors && preJobCheckListItemSpec(rearRotors),
      brakeFluid: brakeFluid && preJobCheckListItemSpec(brakeFluid),
      brakeShoes: brakeShoes && preJobCheckListItemSpec(brakeShoes),
      brakeDrums: brakeDrums && preJobCheckListItemSpec(brakeDrums),
      calipers: calipers && preJobCheckListItemSpec(calipers),
      shocksStruts: shocksStruts && preJobCheckListItemSpec(shocksStruts),
      controlArms: controlArms && preJobCheckListItemSpec(controlArms),
      wheelHubs: wheelHubs && preJobCheckListItemSpec(wheelHubs),
      sparkPlugs: sparkPlugs && preJobCheckListItemSpec(sparkPlugs),
      otherBrakeParts: otherBrakeParts && preJobCheckListItemSpec(otherBrakeParts),
      dashboardLights: dashboardLights && preJobCheckListItemSpec(dashboardLights),
    },
    partsInfo: {
      partsLocation,
      partsOrdered,
      partsOrderNumber,
      partsNotes,
    },
    privateNotes,
    serviceLocation,
    serviceLocationNotes,
    status,
    type,
    serviceCallReason,
    serviceCallReasonExtraInfo,
    vehicleInfo: {
      frontPadLife,
      licensePlate,
      make,
      model,
      odometer,
      rearPadLife,
      extraInfo,
      symptoms,
      vin,
      year,
      frontBrakeSymptoms,
      rearBrakeSymptoms,
      additionalNotes,
    },
  };
};
