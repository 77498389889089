import * as React from "react";
import { Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

export const ReceiptViewLineItem: React.FC<{
  label: string;
  value: string;
  subItem?: boolean;
}> = ({ label, value, subItem }) => {
  return (
    <Box className={`flex flex-row justify-between ${subItem ? "pl-4" : ""}`}>
      <Typography variant={`${subItem ? "caption" : "h6"}` as "inherit"}>{label}</Typography>
      <Typography variant={`${subItem ? "caption" : "h6"}` as "inherit"}>{value}</Typography>
    </Box>
  );
};
