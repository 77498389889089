import React, { useState } from "react";
import { PreJobCheckListFormValues } from "../Jobs/PreJobCheckListDialog";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import { TextField } from "./fields/TextField";
import { Form, Formik } from "formik";
import { Paper } from "@material-ui/core";
import { SubmitButton } from "../Buttons/SubmitButton";
import * as Yup from "yup";
import { includes } from "ramda";
import { PartConditionOptions } from "./fields/PartConditionOptions";
import { PartConditionListItem } from "../ListItems/PartConditionListItem";
import Box from "@material-ui/core/Box";

const needInfoConditions = [
  "Yellow (Has wear, not in need of immediate repair)",
  "Red (Needs immediate repair)",
  "Warning lights on",
];

const checkListItemsSchema = Yup.object({
  partCondition: Yup.string().required("Required").nullable(),
  additionalInfo: Yup.string().when("partCondition", (value) =>
    includes(value, needInfoConditions) ? Yup.string().required("Additional Notes is a required field") : Yup.string()
  ),
});

const schema = Yup.object().shape({
  odometer: Yup.string().required().nullable(),
  frontPads: checkListItemsSchema,
  frontRotors: checkListItemsSchema,
  rearPads: checkListItemsSchema,
  rearRotors: checkListItemsSchema,
  brakeFluid: checkListItemsSchema,
  brakeShoes: checkListItemsSchema,
  brakeDrums: checkListItemsSchema,
  calipers: checkListItemsSchema,
  shocksStruts: checkListItemsSchema,
  controlArms: checkListItemsSchema,
  wheelHubs: checkListItemsSchema,
  sparkPlugs: checkListItemsSchema,
  otherBrakeParts: checkListItemsSchema,
  dashboardLights: checkListItemsSchema,
});

export const PreJobCheckListForm: React.FC<{
  initialValues: PreJobCheckListFormValues;
  onSubmit: (values: PreJobCheckListFormValues) => void;
}> = ({ initialValues, onSubmit }) => {
  const [optionName, setOptionName] = useState(null);
  return (
    <Formik<PreJobCheckListFormValues> validationSchema={schema} initialValues={initialValues} onSubmit={onSubmit}>
      {({ isValid, isSubmitting }) => {
        return (
          <Paper className="pt-20">
            <PartConditionOptions setOptionName={setOptionName} name={optionName} />
            <Form>
              <Box className={"px-8"}>
                <List component="nav">
                  <PartConditionListItem name={"frontPads"} label={"Front Pads"} setOptionName={setOptionName} />
                  <PartConditionListItem name={"frontRotors"} label={"Front Rotors"} setOptionName={setOptionName} />
                  <PartConditionListItem name={"rearPads"} label={"Rear Pads"} setOptionName={setOptionName} />
                  <PartConditionListItem name={"rearRotors"} label={"Rear Rotors"} setOptionName={setOptionName} />
                  <PartConditionListItem name={"brakeFluid"} label={"Brake Fluid"} setOptionName={setOptionName} />
                  <PartConditionListItem name={"brakeShoes"} label={"Brake Shoes"} setOptionName={setOptionName} />
                  <PartConditionListItem name={"brakeDrums"} label={"Brake Drums"} setOptionName={setOptionName} />
                  <PartConditionListItem name={"calipers"} label={"Calipers"} setOptionName={setOptionName} />
                  <PartConditionListItem
                    name={"shocksStruts"}
                    label={"Shocks & Struts"}
                    setOptionName={setOptionName}
                  />
                  <PartConditionListItem name={"controlArms"} label={"Control Arms"} setOptionName={setOptionName} />
                  <PartConditionListItem name={"wheelHubs"} label={"Wheel Hubs"} setOptionName={setOptionName} />
                  <PartConditionListItem name={"sparkPlugs"} label={"Spark Plugs"} setOptionName={setOptionName} />
                  <PartConditionListItem
                    name={"otherBrakeParts"}
                    label={"Other Brake Parts"}
                    setOptionName={setOptionName}
                  />
                  <PartConditionListItem
                    name={"dashboardLights"}
                    label={"Dashboard Lights"}
                    setOptionName={setOptionName}
                  />
                  <ListItem>
                    <div className="flex flex-col w-full">
                      <Typography variant="caption" display="block" gutterBottom>
                        Odometer Reading
                      </Typography>
                      <TextField label={"Odometer"} name={"odometer"} inputMode={"numeric"} />
                    </div>
                  </ListItem>

                  <SubmitButton isSubmitting={isSubmitting} isValid={isValid} label={"Submit"} />
                </List>
              </Box>
            </Form>
          </Paper>
        );
      }}
    </Formik>
  );
};
