import * as React from "react";
import { Form, Formik } from "formik";
import { Paper } from "@material-ui/core";
import { TextAreaField } from "./fields/TextAreaField";
import Box from "@material-ui/core/Box";
import { SubmitButton } from "../Buttons/SubmitButton";
import { useMutation } from "@apollo/client";
import { Mutation, MutationCreateFeedbackArgs } from "../../generated/nest-graphql";
import { CREATE_FEEDBACK } from "../../graphql/mutations/createFeedback";
import { showSuccessAlert } from "../../actions";
import { useDispatch } from "../../contexts/snackbar-context";
import Typography from "@material-ui/core/Typography";

type FeedbackFormValues = {
  appName: string;
  feedback: string;
};
export const FeedbackForm: React.FC<{}> = () => {
  const [createFeedback] = useMutation<Mutation, MutationCreateFeedbackArgs>(CREATE_FEEDBACK);
  const dispatch = useDispatch();
  const onSubmit = async (values: FeedbackFormValues) => {
    await createFeedback({
      variables: {
        createFeedbackInput: values,
      },
    });
    showSuccessAlert(dispatch, "Successfully Submitted Feedback");
  };
  return (
    <Formik<FeedbackFormValues>
      initialValues={{
        appName: "Technician Web App",
        feedback: "",
      }}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, isValid }) => {
        return (
          <Form>
            <Paper>
              <Box paddingY={4} paddingX={2}>
                <Box paddingBottom={2}>
                  <Typography variant={"h6"}>What feedback would you recommend to our dev/product team?</Typography>
                  <Typography variant={"body1"}>
                    Feel free to request features or report bugs. This will notify the dev team in the #tech-feedback
                    slack channel.
                  </Typography>
                </Box>
                <TextAreaField name={"feedback"} label={"Feedback"} />
              </Box>
              <SubmitButton isSubmitting={isSubmitting} isValid={isValid} />
            </Paper>
          </Form>
        );
      }}
    </Formik>
  );
};
