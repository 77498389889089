import * as React from "react";
import { CalendarViewLayout } from "../components/Appointments/CalendarViewLayout";

const NotificationsPage: React.FC<{}> = () => {
  return (
    <CalendarViewLayout>
      <div>notifications</div>
    </CalendarViewLayout>
  );
};

export default NotificationsPage;
