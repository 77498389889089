import * as React from "react";
import { VehicleInfoFormSection } from "../Forms/VehicleInfoFormSection";
import { FullScreenDialog } from "../FullScreenDialog";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import { BottomSubmitButtonRow } from "../Buttons/BottomSubmitButtonRow";
import { ListItemWithSubList } from "./ListItemWithSubList";
import { useToggle } from "../../hooks/useToggle";

export const VehicleInfoListItem: React.FC<{
  year: string | undefined;
  make: string | undefined;
  model: string | undefined;
  parentSetFieldValue: any;
  parentSubmitForm: any;
  parentSubmitting: boolean;
  isValid: boolean;
}> = ({ year, make, model, parentSetFieldValue, parentSubmitForm, parentSubmitting, isValid }) => {
  const [open, , toggleDialog] = useToggle();
  const vehicleInfo = [`${year} ${make} ${model}`];
  return (
    <>
      <ListItemWithSubList
        arrow={false}
        divider={true}
        title={"Vehicle Info"}
        secondaries={vehicleInfo}
        button={true}
        onClick={toggleDialog}
      />
      <FullScreenDialog title="Vehicle Info" open={open} onClose={toggleDialog}>
        <Paper className={"px-3"}>
          <Box marginBottom={2} paddingTop={4}>
            <VehicleInfoFormSection parentSetFieldValue={parentSetFieldValue} year={year} make={make} />
          </Box>
          <Box marginTop={2} paddingBottom={4}>
            <BottomSubmitButtonRow
              label={"Update Job Vehicle Info"}
              isValid={isValid}
              isSubmitting={parentSubmitting}
              handleSubmit={parentSubmitForm}
              pinToBottom={true}
            />
          </Box>
        </Paper>
      </FullScreenDialog>
    </>
  );
};
